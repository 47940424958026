import { AlarmsService } from 'services/alarms.service';
import { of } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../../services/alarms.service";
var CreateRecipientResolve = /** @class */ (function () {
    function CreateRecipientResolve(alarms) {
        this.alarms = alarms;
    }
    CreateRecipientResolve.prototype.resolve = function (route, state) {
        var limit = this.alarms.createPlainAlarmRecipient();
        return of(limit);
    };
    CreateRecipientResolve.ngInjectableDef = i0.defineInjectable({ factory: function CreateRecipientResolve_Factory() { return new CreateRecipientResolve(i0.inject(i1.AlarmsService)); }, token: CreateRecipientResolve, providedIn: "root" });
    return CreateRecipientResolve;
}());
export { CreateRecipientResolve };
