<div class="content-main">
  <div class="content-heading" i18n>
    Alarms history
  </div>
  <div class="content-body">
    <div class="content-padding">
      <app-table-interactive-top [page]="currentPage" [collectionSize]="numberOfAlarms" (pageChanged)="onPageChanged($event)"
        (searchTextChanged)="onChangeSearchText($event)"></app-table-interactive-top>
    </div>
    <div class="table-responsive">
      <table class="table" *ngIf="shownEntries.length > 0; else noSubscriptionsCreated">
        <thead>
          <tr>
            <th scope="col" class="d-table-cell" i18n>Time</th>
            <th scope="col" class="d-table-cell" i18n>Alarm</th>
            <th scope="col" class="d-table-cell" i18n>Limit</th>
            <th scope="col" class="d-table-cell" i18n>Recipient</th>
            <th scope="col" class="d-table-cell" i18n>Notified by</th>
            <th scope="col" class="d-table-cell"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let entry of shownEntries">
            <td class="d-table-cell align-middle">{{ entry.occuredDate | amLocal | amDateFormat:'YYYY-MM-DD HH:mm:ss'}}</td>
            <td class="d-table-cell align-middle">{{ entry.alarmName }}</td>
            <td class="d-table-cell align-middle">{{ entry.limitName }}</td>
            <td class="d-table-cell align-middle">{{ entry.recipientName }}</td>
            <td class="d-table-cell align-middle">{{ entry.notifiedBy }}</td>
            <td class="d-table-cell align-middle">
              <div class="float-right">
                <button class="btn btn-light btn-sm" (click)="onShowAlertDetails(entry)">
                  <fa-icon [icon]="['fas', 'external-link-alt']"></fa-icon>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="content-padding">
      <app-table-interactive-bottom [page]="currentPage" [collectionSize]="numberOfAlarms" (pageChanged)="onPageChanged($event)"></app-table-interactive-bottom>
    </div>
    <ng-template #noSubscriptionsCreated>
      <div class="content-padding" i18n>No alarms sent.</div>
    </ng-template>
  </div>
</div>