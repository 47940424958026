<div class="content-main">
  <app-add-alarm-heading [lastPathName]="alarm?.name"></app-add-alarm-heading>
  <div class="content-body">
    <div class="container-fluid">
      <div class="row">

      </div>
      <div class="row">
        <div class="col-md-12 body-head">
          <div class="float-md-left content-padding subtitle">
            <h5>{{ alarm?.name }}</h5>
          </div>
          <div class="float-md-right content-padding">
            <app-alarm-btn-group [activeStep]="Step.Recipients" [alarmId]="alarm?.identifier"></app-alarm-btn-group>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h3 class="content-title" i18n>Limits</h3>
          <div class="content-padding">
            <app-table-interactive-top [page]="currentPage" [collectionSize]="numberOfRecipients"
              (pageChanged)="onPageChanged($event)" (searchTextChanged)="onChangeSearchText($event)"></app-table-interactive-top>
          </div>
          <app-recipients-table [alarm]="alarm" [recipients$]="recipients$" [removeRecipientsDisabled]="removeRecipientInProgress"
            (removeRecipient)="onRemoveRecipient($event)" (editRecipient)="onEditRecipient($event)"></app-recipients-table>
          <div class="content-padding">
            <app-table-interactive-bottom [page]="currentPage" [collectionSize]="numberOfRecipients" (pageChanged)="onPageChanged($event)"></app-table-interactive-bottom>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-alarm-bottom-btn-group [alarm]="alarm" [secondBtnTitle]="'Add more recipients'" (addMore)="onAddMoreRecipients()" (duplicatedAlarm)="onDuplicatedAlarm($event)"></app-alarm-bottom-btn-group>