import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { Recipient } from 'services/recipients.model';
import { environment } from 'environments/environment';
import { of } from 'rxjs';

namespace RecipientsMiddleware.Request {

    export interface UpsertRecipient {
        recipient: {
            name: string;
            email?: string;
            mobile?: string;
            mail_confirmed?: boolean;
            sms_confirmed?: boolean;
        };
    }

    export interface Confirmation {
        type: Recipient.Confirmation.Type.mail | Recipient.Confirmation.Type.sms;
    }
}

namespace RecipientsMiddleware.Response {
    export interface BaseRecipient {
        id: number;
        name: string;
        email: string;
        mobile?: string;
        mail_confirmed: boolean;
        sms_confirmed: boolean;
    }

    export interface Recipients {
        recipients: RecipientsMiddleware.Response.BaseRecipient[];
    }

    export interface Subscriptions {
        name: string;
        subscriptions: Recipient.Subscription[];
    }

    export interface DemoNotification {
        mail: boolean;
        sms: boolean;
    }
}

export class RecipientsMiddleware {
    constructor(private http: HttpClient) { }

    refreshRecipients() {
        return this.http.get<RecipientsMiddleware.Response.Recipients>(environment.endpoints.recipients, { withCredentials: true }).pipe(
            map(resp => this.recipientsResponseToRecipients(resp))
        );
    }

    getRecipient(identifier: string) {
        const url = `${environment.endpoints.recipients}/${identifier}`;
        return this.http.get<RecipientsMiddleware.Response.BaseRecipient>(url, { withCredentials: true }).pipe(
            map(resp => this.baseRecipientResponseToRecipient(resp))
        );
    }

    getRecipientSubscriptionsWithRecipientIdentifier(identifier: string) {
        const url = `${environment.endpoints.recipients}/${identifier}/subscriptions`;
        return this.http.get<RecipientsMiddleware.Response.Subscriptions>(url, { withCredentials: true }).pipe(
            map(resp => resp.subscriptions)
        );
    }

    createNewRecipient(recipient: Recipient) {
        const url = `${environment.endpoints.recipients}`;
        const body = this.upsertRecipientsRequestFromRecipient(recipient);
        return this.http.post<RecipientsMiddleware.Response.BaseRecipient>(url, body, { withCredentials: true }).pipe(
            map(resp => this.baseRecipientResponseToRecipient(resp))
        );
    }

    updateRecipient(recipient: Recipient) {
        const url = `${environment.endpoints.recipients}/${recipient.identifier}`;
        const body = this.upsertRecipientsRequestFromRecipient(recipient);
        return this.http.put<RecipientsMiddleware.Response.BaseRecipient>(url, body, { withCredentials: true }).pipe(
            map(resp => this.baseRecipientResponseToRecipient(resp))
        );
    }

    deleteRecipientWithIdentifier(identifier: string) {
        const url = `${environment.endpoints.recipients}/${identifier}`;
        return this.http.delete(url, { withCredentials: true });
    }

    confirmRecipientWithIdentifier(identifier: string, type: Recipient.Confirmation.Type.mail | Recipient.Confirmation.Type.sms) {
        const url = `${environment.endpoints.recipients}/${identifier}/confirm`;
        const body = { type };
        return this.http.post(url, body, { withCredentials: true });
    }

    unconfirmRecipientWithIdentifier(identifier: string, type: Recipient.Confirmation.Type.mail | Recipient.Confirmation.Type.sms) {
        const url = `${environment.endpoints.recipients}/${identifier}/unconfirm`;
        const body = { type };
        return this.http.post(url, body, { withCredentials: true });
    }

    sendTestAlarmToRecipientWithIdentifier(identifier: string) {
        const url = `${environment.endpoints.recipients}/${identifier}/demo_notification`;
        const body = null;
        return this.http.post<RecipientsMiddleware.Response.DemoNotification>(url, body, { withCredentials: true });
    }

    private upsertRecipientsRequestFromRecipient(recipient: Recipient) {
        const mail = recipient.mail != null && recipient.mail.length > 0 ? recipient.mail : null;
        const mobile = recipient.mobile != null && recipient.mobile.length > 0 ? recipient.mobile : null;
        const request: RecipientsMiddleware.Request.UpsertRecipient = {
            recipient: {
                name: recipient.name,
                email: mail,
                mobile: mobile,
                mail_confirmed: mail != null && recipient.mailConfirmed != null ? recipient.mailConfirmed : false,
                sms_confirmed: mobile != null && recipient.smsConfirmed != null ? recipient.smsConfirmed : false
            }
        };
        console.log('upsertRecipientsRequestFromRecipient-request', request);
        return request;
    }

    private recipientsResponseToRecipients(response: RecipientsMiddleware.Response.Recipients) {
        const recp = response.recipients;
        if (recp != null) {
            const recipients = recp.map(r => {
                return this.baseRecipientResponseToRecipient(r);
            });
            return recipients;
        }
        return undefined;
    }

    private baseRecipientResponseToRecipient(response: RecipientsMiddleware.Response.BaseRecipient) {
        const recipient: Recipient = {
            identifier: `${response.id}`,
            name: response.name,
            mail: response.email,
            mobile: response.mobile,
            mailConfirmed: response.mail_confirmed,
            smsConfirmed: response.sms_confirmed
        };
        return recipient;
    }
}
