/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./alarm-btn-group.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "./alarm-btn-group.component";
var styles_AlarmBtnGroupComponent = [i0.styles];
var RenderType_AlarmBtnGroupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AlarmBtnGroupComponent, data: {} });
export { RenderType_AlarmBtnGroupComponent as RenderType_AlarmBtnGroupComponent };
export function View_AlarmBtnGroupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["aria-label", "Alarm limits and recipients"], ["class", "btn-group"], ["role", "group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "button", [["class", "btn"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "btn-light": 0, "btn-secondary": 1 }), i1.ɵdid(4, 16384, null, 0, i3.RouterLink, [i3.Router, i3.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(5, 3), (_l()(), i1.ɵted(-1, null, ["Limits"])), (_l()(), i1.ɵeld(7, 0, null, null, 5, "button", [["class", "btn"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(9, { "btn-light": 0, "btn-secondary": 1 }), i1.ɵdid(10, 16384, null, 0, i3.RouterLink, [i3.Router, i3.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(11, 3), (_l()(), i1.ɵted(-1, null, ["Recipients"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "btn"; var currVal_2 = _ck(_v, 3, 0, !_co.isStepActive(_co.Step.Limits), _co.isStepActive(_co.Step.Limits)); _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_3 = _ck(_v, 5, 0, "/main/alarm", _co.alarmId, "limits"); _ck(_v, 4, 0, currVal_3); var currVal_5 = "btn"; var currVal_6 = _ck(_v, 9, 0, !_co.isStepActive(_co.Step.Recipients), _co.isStepActive(_co.Step.Recipients)); _ck(_v, 8, 0, currVal_5, currVal_6); var currVal_7 = _ck(_v, 11, 0, "/main/alarm/", _co.alarmId, "recipients"); _ck(_v, 10, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.alarmId == null); _ck(_v, 1, 0, currVal_0); var currVal_4 = (_co.alarmId == null); _ck(_v, 7, 0, currVal_4); }); }
export function View_AlarmBtnGroupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-alarm-btn-group", [], null, null, null, View_AlarmBtnGroupComponent_0, RenderType_AlarmBtnGroupComponent)), i1.ɵdid(1, 114688, null, 0, i4.AlarmBtnGroupComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlarmBtnGroupComponentNgFactory = i1.ɵccf("app-alarm-btn-group", i4.AlarmBtnGroupComponent, View_AlarmBtnGroupComponent_Host_0, { activeStep: "activeStep", alarmId: "alarmId" }, {}, []);
export { AlarmBtnGroupComponentNgFactory as AlarmBtnGroupComponentNgFactory };
