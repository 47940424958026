/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./alarms.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "../../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i5 from "@fortawesome/angular-fontawesome";
import * as i6 from "@angular/platform-browser";
import * as i7 from "../../components-general/table-interactive-top/table-interactive-top.component.ngfactory";
import * as i8 from "../../components-general/table-interactive-top/table-interactive-top.component";
import * as i9 from "../../../services/settings.service";
import * as i10 from "../../components-general/table-interactive-bottom/table-interactive-bottom.component.ngfactory";
import * as i11 from "../../components-general/table-interactive-bottom/table-interactive-bottom.component";
import * as i12 from "./alarms.component";
import * as i13 from "@ng-bootstrap/ng-bootstrap";
import * as i14 from "../../../services/alarms.service";
import * as i15 from "../../../services/recipients.service";
var styles_AlarmsComponent = [i0.styles];
var RenderType_AlarmsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AlarmsComponent, data: {} });
export { RenderType_AlarmsComponent as RenderType_AlarmsComponent };
function View_AlarmsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [["class", "d-table-cell align-middle"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "td", [["class", "d-table-cell align-middle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Used metrics"])), (_l()(), i1.ɵted(6, null, [" ", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 11, "td", [["class", "d-table-cell align-middle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 10, "div", [["class", "float-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 5, "a", [["class", "btn btn-light btn-sm"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(11, 3), (_l()(), i1.ɵeld(12, 0, null, null, 2, "fa-icon", [["class", "ng-fa-icon"]], [[8, "innerHTML", 1]], null, null, i4.View_FaIconComponent_0, i4.RenderType_FaIconComponent)), i1.ɵdid(13, 573440, null, 0, i5.FaIconComponent, [i6.DomSanitizer, i5.FaIconService], { iconProp: [0, "iconProp"] }, null), i1.ɵpad(14, 2), (_l()(), i1.ɵeld(15, 0, null, null, 3, "button", [["class", "btn btn-light btn-sm"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onRemoveAlarm(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 2, "fa-icon", [["class", "ng-fa-icon"]], [[8, "innerHTML", 1]], null, null, i4.View_FaIconComponent_0, i4.RenderType_FaIconComponent)), i1.ɵdid(17, 573440, null, 0, i5.FaIconComponent, [i6.DomSanitizer, i5.FaIconService], { iconProp: [0, "iconProp"] }, null), i1.ɵpad(18, 2)], function (_ck, _v) { var currVal_4 = _ck(_v, 11, 0, "/main", "alarm", _v.context.$implicit.identifier); _ck(_v, 10, 0, currVal_4); var currVal_6 = _ck(_v, 14, 0, "fas", "edit"); _ck(_v, 13, 0, currVal_6); var currVal_9 = _ck(_v, 18, 0, "fas", "trash-alt"); _ck(_v, 17, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.name; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.numberOfLimits; _ck(_v, 6, 0, currVal_1); var currVal_2 = i1.ɵnov(_v, 10).target; var currVal_3 = i1.ɵnov(_v, 10).href; _ck(_v, 9, 0, currVal_2, currVal_3); var currVal_5 = i1.ɵnov(_v, 13).renderedIconHTML; _ck(_v, 12, 0, currVal_5); var currVal_7 = _co.removeAlarmInProgress; _ck(_v, 15, 0, currVal_7); var currVal_8 = i1.ɵnov(_v, 17).renderedIconHTML; _ck(_v, 16, 0, currVal_8); }); }
function View_AlarmsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "table", [["class", "table"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "thead", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "th", [["class", "d-table-cell"], ["scope", "col"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Name alarm"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "th", [["class", "d-table-cell"], ["scope", "col"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Number of metrics"])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "th", [["class", "d-table-cell"], ["scope", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlarmsComponent_2)), i1.ɵdid(10, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.shownAlarmEntries; _ck(_v, 10, 0, currVal_0); }, null); }
function View_AlarmsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "content-padding"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["No alarms created."]))], null, null); }
export function View_AlarmsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "content-main"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "content-heading"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Alarms "])), (_l()(), i1.ɵeld(3, 0, null, null, 9, "div", [["class", "content-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "content-padding"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-table-interactive-top", [], null, [[null, "pageChanged"], [null, "searchTextChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("pageChanged" === en)) {
        var pd_0 = (_co.onPageChanged($event) !== false);
        ad = (pd_0 && ad);
    } if (("searchTextChanged" === en)) {
        var pd_1 = (_co.onChangeSearchText($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i7.View_TableInteractiveTopComponent_0, i7.RenderType_TableInteractiveTopComponent)), i1.ɵdid(6, 245760, null, 0, i8.TableInteractiveTopComponent, [i9.SettingsService], { page: [0, "page"], collectionSize: [1, "collectionSize"] }, { pageChanged: "pageChanged", searchTextChanged: "searchTextChanged" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlarmsComponent_1)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "content-padding"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "app-table-interactive-bottom", [], null, [[null, "pageChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("pageChanged" === en)) {
        var pd_0 = (_co.onPageChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_TableInteractiveBottomComponent_0, i10.RenderType_TableInteractiveBottomComponent)), i1.ɵdid(11, 770048, null, 0, i11.TableInteractiveBottomComponent, [i9.SettingsService], { page: [0, "page"], collectionSize: [1, "collectionSize"] }, { pageChanged: "pageChanged" }), (_l()(), i1.ɵand(0, [["noAlarmsCreated", 2]], null, 0, null, View_AlarmsComponent_3)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "button", [["class", "btn btn-primary btn-phoenix float-right"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onAddNewAlarm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Add new alarm"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentPage; var currVal_1 = _co.numberOfAlarms; _ck(_v, 6, 0, currVal_0, currVal_1); var currVal_2 = (_co.allAlarms.length > 0); var currVal_3 = i1.ɵnov(_v, 12); _ck(_v, 8, 0, currVal_2, currVal_3); var currVal_4 = _co.currentPage; var currVal_5 = _co.numberOfAlarms; _ck(_v, 11, 0, currVal_4, currVal_5); }, null); }
export function View_AlarmsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-alarms", [], null, null, null, View_AlarmsComponent_0, RenderType_AlarmsComponent)), i1.ɵdid(1, 245760, null, 0, i12.AlarmsComponent, [i13.NgbModal, i2.Router, i14.AlarmsService, i9.SettingsService, i15.RecipientsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlarmsComponentNgFactory = i1.ɵccf("app-alarms", i12.AlarmsComponent, View_AlarmsComponent_Host_0, {}, {}, []);
export { AlarmsComponentNgFactory as AlarmsComponentNgFactory };
