import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AlarmsService } from 'services/alarms.service';
import { AlarmMetaInfo } from 'services/alarms.model';
import { of } from 'rxjs';

@Injectable({
    providedIn: 'root'
  })
export class AlarmsResolve implements Resolve<AlarmMetaInfo[]> {
    constructor(private alarms: AlarmsService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        console.log('AlarmsResolve');
        const currentInfos = this.alarms.alarmMetaInfo$.value;
        if (currentInfos == null || currentInfos.length === 0) {
            return this.alarms.refreshAlarms();
        }
        return of(currentInfos);
    }
}
