import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-success-upsert-remove',
  templateUrl: './success-upsert-remove.component.html',
  styleUrls: ['./success-upsert-remove.component.scss']
})
export class SuccessUpsertRemoveComponent implements OnInit {

  Type = SuccessUpsertRemoveComponent.Type;
  type: SuccessUpsertRemoveComponent.Type;

  closeResult: string;

  static openDefaultModal(type: SuccessUpsertRemoveComponent.Type, modalService: NgbModal) {
    const modal = modalService.open(SuccessUpsertRemoveComponent,
      { centered: true, size: 'lg', backdrop: 'static', keyboard: false });
    if (modal.componentInstance instanceof SuccessUpsertRemoveComponent) {
      modal.componentInstance.type = type;
    }
    return modal;
  }

  constructor(public modal: NgbActiveModal) { }

  ngOnInit() {
  }

}

export namespace SuccessUpsertRemoveComponent {
  export enum Type {
    AlarmCreated, AlarmDuplicated, AlarmRemoved,
    AlarmLimitCreated, AlarmLimitUpdated, AlarmLimitRemoved,
    AlarmRecipientCreated, AlarmRecipientUpdated, AlarmRecipientRemoved,
    RecipientCreated, RecipientUpdated, RecipientRemoved
  }
}
