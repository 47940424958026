import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-unable-delete',
  templateUrl: './unable-delete.component.html',
  styleUrls: ['./unable-delete.component.scss']
})
export class UnableDeleteComponent implements OnInit {

  Type = UnableDeleteComponent.Type;
  type: UnableDeleteComponent.Type;

  static openDefaultModal(type: UnableDeleteComponent.Type, modalService: NgbModal) {
    const modal = modalService.open(UnableDeleteComponent,
      { centered: true, size: 'sm', backdrop: 'static', keyboard: false});
    if (modal.componentInstance instanceof UnableDeleteComponent) {
      modal.componentInstance.type = type;
    }
    return modal;
  }

  constructor(public modal: NgbActiveModal) { }

  ngOnInit() {
  }

}

export namespace UnableDeleteComponent {
  export enum Type {
    LimitReasonRecpientsAddedd
  }
}
