import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { AlarmsService } from '@services/alarms.service';
import { Alarm } from '@services/alarms.model';
import { AddAlarmStepsBtnGroupComponent } from '@components-general/add-alarm-steps-btn-group/add-alarm-steps-btn-group.component';
import { LimitsTableEntry } from '@components-general/limits-table/limits-table.component';
import { MetricsService } from '@services/metrics.service';
import { AlarmConfigurationVisitedStep, SettingsService } from '@services/settings.service';

@Component({
  selector: 'app-add-alarm-step2',
  templateUrl: './add-alarm-step2.component.html',
  styleUrls: ['./add-alarm-step2.component.scss']
})
export class AddAlarmStep2Component implements OnInit, OnDestroy {

  Step = AddAlarmStepsBtnGroupComponent.Step;

  alarm: Alarm;
  limit: Alarm.Limit;
  addedLimits$ = new BehaviorSubject<LimitsTableEntry[]>([]);

  selectedMetricValue: number;

  metricsReloading = false;

  constructor(
    private router: Router,
    private alarms: AlarmsService,
    private metrics: MetricsService,
    private settings: SettingsService
    ) { }

  ngOnInit() {
    this.alarm = this.alarms.alarmToBeCreated;
    this.limit = this.alarms.createPlainLimit();
    const limitEntries = LimitsTableEntry.convertFromAlarmLimits(this.alarm.limits, this.metrics);
    this.addedLimits$.next(limitEntries);
  }

  ngOnDestroy() {
  }

  onSubmit(form: NgForm) {
    if (form.invalid) {
      return;
    }
    const limit = Alarm.Limit.deepCopy(this.limit);
    const newLimits = this.alarms.upsertLimit(limit, this.alarm);
    const entries = LimitsTableEntry.convertFromAlarmLimits(newLimits, this.metrics);
    this.addedLimits$.next(entries);
    form.reset();
    this.limit = this.alarms.createPlainLimit();
  }

  onRemoveLimit(limit: LimitsTableEntry) {
    const newLimits = this.alarms.removeLimitWithIdentifier(limit.identifier, this.alarm);
    const newEntries = LimitsTableEntry.convertFromAlarmLimits(newLimits, this.metrics);
    this.addedLimits$.next(newEntries);
  }

  onLimitChanged(limit: Alarm.Limit) {
    this.limit = limit;
  }

  onContinueToRecipientsClicked() {
    if (this.settings.helperAlarmConfigurationVisitedStep === AlarmConfigurationVisitedStep.Limits) {
      this.settings.helperAlarmConfigurationVisitedStep = AlarmConfigurationVisitedStep.Recipients;
    }
    this.router.navigate(['main', 'add_alarm', 'recipients']);
  }

  continueDisabled() {
    return this.alarm.limits.length === 0;
  }

  addAlarmStepsBtnGroupRecipientsDisabled() {
    const current = this.settings.helperAlarmConfigurationVisitedStep;
    return current !== AlarmConfigurationVisitedStep.Recipients || this.addedLimits$.getValue().length === 0;
  }

}
