import { Component, OnInit } from '@angular/core';
import { HttpUrlEncodingCodec, HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from 'environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public navbarCollapsed: boolean;

  public appVersion = environment.appVersion;
  public revision: string;

  public ssoRedirectUrl = new HttpUrlEncodingCodec().encodeValue('http://tsdalarmierung.phoenix.local/sso_redirect');

  constructor(private http: HttpClient) { }

  ngOnInit() {
    this.http.get('assets/revision.json').pipe(
      map(resp => {
        console.log('response:', resp);
        let rev = resp['revision'];
        if (rev != null && rev.length > 5) {
          rev = rev.substring(0, 5);
        }
        return rev;
      })
    ).subscribe(revision => {
      this.revision = revision;
    });
    this.navbarCollapsed = true;
  }
}
