<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title" i18n>Success</h4>
</div>
<div class="modal-body">
  <p class="icon">
    <fa-icon [icon]="['fas', 'smile']" size="4x"></fa-icon>
  </p>
  <p>
    <span i18n>A test alarm has been sent to your recipient's email address and/or telephone number.</span><br/>
    <span i18n>Activate the checkbox right next to the email address and/or telephone number of your recipient to let them receive alarms.</span>
  </p>
  <p><strong i18n>Otherwise the alarms you set up will not be delivered to your recipient.</strong></p>
</div>
<div class="modal-footer one">
  <button type="button" class="btn btn-primary btn-phoenix" (click)="modal.close('Ok click')">Okay.</button>
</div>
