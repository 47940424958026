import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { v1 } from 'uuid';
import { map, tap, concatMap } from 'rxjs/operators';

import { AlarmsMiddleware } from 'services/alarms.middleware';
import { Alarm, AlarmMetaInfo } from 'services/alarms.model';


@Injectable({
  providedIn: 'root'
})
export class AlarmsService {

  alarmMetaInfo$ = new BehaviorSubject<AlarmMetaInfo[]>([]);
  currentAlarm$ = new BehaviorSubject<Alarm>(undefined);

  alarmToBeCreated: Alarm = undefined;

  constructor(private http: HttpClient) { }

  refreshAlarms() {
    return new AlarmsMiddleware(this.http).allAlarms().pipe(
      tap(resp => {
        this.alarmMetaInfo$.next(resp);
      })
    );
  }

  resetAlarms() {
    this.alarmMetaInfo$.next([]);
  }

  createPlainAlarm(name?: string) {
    const alarm: Alarm = {
      identifier: v1(),
      createdAt: new Date().toISOString(),
      name: name,
      limits: [],
      recipients: []
    };
    // const alarm = this.defaultAlarm();
    return alarm;
  }

  createPlainLimit() {
    const limit: Alarm.Limit = {
      identifier: v1(),
      rule: {}
    };
    return limit;
  }

  createPlainAlarmRecipient() {
    const aRecipient: Alarm.Recipient = {
      identifier: v1(),
      subscribedToLimits: []
    };
    return aRecipient;
  }

  upsertLimit(limit: Alarm.Limit, toAlarm: Alarm) {
    const indexLimit = Alarm.Limit.indexOfLimitForIdentifier(limit.identifier, toAlarm);
    if (indexLimit > -1) {
      toAlarm.limits[indexLimit] = limit;
    } else {
      toAlarm.limits.push(limit);
    }
    return toAlarm.limits;
  }

  removeLimitWithIdentifier(limitId: string, fromAlarm: Alarm) {
    const index = fromAlarm.limits.findIndex(l => {
      return l.identifier === limitId;
    });
    if (index > -1) {
      fromAlarm.limits.splice(index, 1);

      // remove limit from recipients, where the limit was added
      // fromAlarm.recipients.forEach(r => {
      //   const indexL = r.subscribedToLimits.findIndex(l => {
      //     return l.identifier === limitId;
      //   });
      //   if (indexL > -1) {
      //     r.subscribedToLimits.splice(indexL, 1);
      //   }
      // });
    }
    return fromAlarm.limits;
  }

  upsertRecipient(recipient: Alarm.Recipient, toAlarm: Alarm) {
    const indexRecipient = Alarm.Recipient.indexOfRecipientForIdentifier(recipient.identifier, toAlarm);
    if (indexRecipient > -1) {
      toAlarm.recipients[indexRecipient] = recipient;
    } else {
      toAlarm.recipients.push(recipient);
    }
    return toAlarm.recipients;
  }

  removeRecipientWithIdentifier(recipientId: string, fromAlarm: Alarm) {
    const index = fromAlarm.recipients.findIndex(r => {
      return r.identifier === recipientId;
    });
    if (index > -1) {
      fromAlarm.recipients.splice(index, 1);
    }
    return fromAlarm.recipients;
  }

  upsertAlarm(alarm: Alarm, duplicateName?: string) {
    const exists = this.alarmMetaInfo$.value.findIndex(a => {
      return a.identifier === alarm.identifier;
    });
    const mw = new AlarmsMiddleware(this.http);
    let alarmObs: Observable<Alarm>;
    if (exists > -1 && duplicateName == null) {
      // update
      alarmObs = mw.updateAlarm(alarm);
    } else {
      // add
      alarmObs = mw.createNewAlarm(alarm, duplicateName);
    }
    let upsertedAlarm: Alarm;
    return alarmObs.pipe(
      concatMap(
        msg => {
          upsertedAlarm = msg;
          return this.refreshAlarms();
        }
      )
    ).pipe(
      map(
        msg => {
          return upsertedAlarm;
        }
      )
    );
  }

  deleteAlarmWithIdentifier(identifier: string) {
    return new AlarmsMiddleware(this.http).deleteAlarm(identifier).pipe(
      concatMap(
        msg => this.refreshAlarms()
      )
    );
  }

  alarmWithIdentifier(identifier: string) {
    return new AlarmsMiddleware(this.http).alarm(identifier);
  }
}
