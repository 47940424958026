import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { MetricsMiddleware } from 'services/metrics.middleware';
import { tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export var Metric;
(function (Metric) {
    function currentValueForMetric(identifier, metricsService) {
        var metric = metricsService.metricForIdentifier(identifier);
        if (metric != null) {
            return metric.limit;
        }
        return null;
    }
    Metric.currentValueForMetric = currentValueForMetric;
    function nameForMetric(identifier, metricsService) {
        var metric = metricsService.metricForIdentifier(identifier);
        if (metric != null) {
            return metric.name;
        }
        return null;
    }
    Metric.nameForMetric = nameForMetric;
})(Metric || (Metric = {}));
var MetricsService = /** @class */ (function () {
    function MetricsService(http) {
        this.http = http;
        this.metrics$ = new BehaviorSubject([]);
        this.metricsValues$ = new BehaviorSubject({});
        this.metricNamesToPollForValue = [];
        this.pollForMetricsValues();
    }
    MetricsService.prototype.refreshMetrics = function () {
        var _this = this;
        return new MetricsMiddleware(this.http).refreshMetrics().pipe(tap(function (resp) {
            _this.metrics$.next(resp);
        }));
    };
    MetricsService.prototype.resetMetrics = function () {
        this.metrics$.next([]);
    };
    MetricsService.prototype.metricForIdentifier = function (identifier) {
        return this.metrics$.value.find(function (m) {
            return m.identifier === identifier;
        });
    };
    // poll for metrics valus
    MetricsService.prototype.addMetricNameToPollForValue = function (metricName) {
        var alreadyAdded = this.metricNamesToPollForValue.find(function (n) { return n === metricName; });
        if (alreadyAdded == null && metricName != null) {
            this.metricNamesToPollForValue.push(metricName);
        }
    };
    MetricsService.prototype.removeMetricNameToPollForValue = function (metricName) {
        var index = this.metricNamesToPollForValue.findIndex(function (n) { return n === metricName; });
        if (index > -1) {
            this.metricNamesToPollForValue.splice(index, 1);
        }
    };
    MetricsService.prototype.pollForMetricsValues = function () {
        var _this = this;
        var nextPolling = function () {
            setTimeout(function () {
                _this.pollForMetricsValues();
            }, MetricsService.POLLING_INTERVAL);
        };
        if (this.metricNamesToPollForValue.length > 0) {
            new MetricsMiddleware(this.http).metricsValue(this.metricNamesToPollForValue).subscribe(function (res) {
                console.log('pollForMetricsValues values', res);
                _this.metricsValues$.next(res);
            }, function (err) {
                console.error('pollForMetricsValues failed', err);
                nextPolling();
            }, function () {
                nextPolling();
            });
        }
        else {
            nextPolling();
        }
    };
    MetricsService.POLLING_INTERVAL = 3000;
    MetricsService.ngInjectableDef = i0.defineInjectable({ factory: function MetricsService_Factory() { return new MetricsService(i0.inject(i1.HttpClient)); }, token: MetricsService, providedIn: "root" });
    return MetricsService;
}());
export { MetricsService };
