import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AlarmsService } from 'services/alarms.service';
import { Alarm } from 'services/alarms.model';
import { AddAlarmHeading } from '@components-general/add-alarm-heading/add-alarm-heading.component';
import { SuccessUpsertRemoveComponent } from '@components-modal/success-upsert-remove/success-upsert-remove.component';
import { FailedUpsertRemoveComponent } from '@components-modal/failed-upsert-remove/failed-upsert-remove.component';


@Component({
  selector: 'app-alarm-upsert-limit',
  templateUrl: './alarm-upsert-limit.component.html',
  styleUrls: ['./alarm-upsert-limit.component.scss']
})
export class AlarmUpsertLimitComponent implements OnInit, OnDestroy {

  @ViewChild('addLimitText') addLimitText: ElementRef;
  @ViewChild('editLimitText') editLimitText: ElementRef;

  alarm: Alarm;
  limit: Alarm.Limit;

  alarmHeadingMiddleEntries: AddAlarmHeading.MiddleEntry[];
  alarmHeadingLastPath: string;

  editLimit: boolean;
  inViewerMode: boolean;
  saveInProgress = false;

  private alarmSubscription: Subscription;

  constructor(private modalService: NgbModal, private router: Router, private route: ActivatedRoute, private alarms: AlarmsService) { }

  ngOnInit() {
    this.alarmSubscription = this.alarms.currentAlarm$.subscribe(alarm => {
      this.alarm = alarm;
      this.updateAlarmHeadingMiddleEntries();
    });

    this.route.data.subscribe(data => {
      this.limit = Alarm.Limit.deepCopy(data.limit);
      this.editLimit = this.limit.metricIdentifier != null ? true : false;
      this.alarmHeadingLastPath = this.editLimit ? this.editLimitText.nativeElement.innerHTML : this.addLimitText.nativeElement.innerHTML;
      // this.inViewerMode = this.editLimit ? true : false;
      this.inViewerMode = false;
    });
  }

  ngOnDestroy() {
    this.alarmSubscription.unsubscribe();
  }

  onLimitChanged(limit: Alarm.Limit) {
    this.limit = limit;
  }

  onCancel() {
    this.router.navigate(['/main/alarm', this.alarm.identifier, 'limits']);
  }

  onSubmit(form: NgForm) {
    if (form.invalid) {
      return;
    }
    this.saveInProgress = true;
    const limit = Alarm.Limit.deepCopy(this.limit);
    this.alarms.upsertLimit(limit, this.alarm);
    this.alarms.upsertAlarm(this.alarm).subscribe(
      resp => {
        this.alarms.currentAlarm$.next(resp);
        this.router.navigate(['/main/alarm', this.alarm.identifier, 'limits']);
        // tslint:disable-next-line:max-line-length
        const type = this.editLimit ? SuccessUpsertRemoveComponent.Type.AlarmLimitUpdated : SuccessUpsertRemoveComponent.Type.AlarmLimitCreated;
        SuccessUpsertRemoveComponent.openDefaultModal(type, this.modalService);
      },
      err => {
        FailedUpsertRemoveComponent.openDefaultModal(err, this.modalService);
        console.error(err);
        this.saveInProgress = false;
      },
      () => {
        this.saveInProgress = false;
      }
    );
  }

  private updateAlarmHeadingMiddleEntries() {
    const entry: AddAlarmHeading.MiddleEntry = {
      name: this.alarm.name,
      routerLinkValue: `/main/alarm/${this.alarm.identifier}/limits`
    };
    this.alarmHeadingMiddleEntries = [entry];
  }

}
