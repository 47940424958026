import { AlarmsService } from 'services/alarms.service';
import { Alarm } from 'services/alarms.model';
import { of } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../../services/alarms.service";
var AlarmRecipientResolve = /** @class */ (function () {
    function AlarmRecipientResolve(alarms) {
        this.alarms = alarms;
    }
    AlarmRecipientResolve.prototype.resolve = function (route, state) {
        console.log('AlarmRecipientResolve');
        var alarm = this.alarms.currentAlarm$.value;
        var recipient = Alarm.Recipient.recipientByIdentifier(route.params.id, alarm);
        return of(recipient);
    };
    AlarmRecipientResolve.ngInjectableDef = i0.defineInjectable({ factory: function AlarmRecipientResolve_Factory() { return new AlarmRecipientResolve(i0.inject(i1.AlarmsService)); }, token: AlarmRecipientResolve, providedIn: "root" });
    return AlarmRecipientResolve;
}());
export { AlarmRecipientResolve };
