import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { AlarmsService } from 'services/alarms.service';
import { SettingsService, AlarmingMenuEntry } from 'services/settings.service';

@Component({
  selector: 'app-alarm',
  templateUrl: './alarm.component.html',
  styleUrls: ['./alarm.component.scss']
})
export class AlarmComponent implements OnInit, OnDestroy {

  private dataSubscription: Subscription;

  constructor(private route: ActivatedRoute, private alarms: AlarmsService, private settings: SettingsService) { }

  ngOnInit() {
    this.dataSubscription = this.route.data.subscribe(
      data => {
        this.alarms.currentAlarm$.next(data.alarm);
      },
      err => {
        console.error(err);
      });
  }

  ngOnDestroy() {
    this.dataSubscription.unsubscribe();
  }

}
