import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

import { AlarmsService } from 'services/alarms.service';
import { RecipientsService } from 'services/recipients.service';
import { AlarmMetaInfo } from 'services/alarms.model';
import { SettingsService, AlarmingMenuEntry } from 'services/settings.service';
import { WarningRemoveComponent } from '@components-modal/warning-remove/warning-remove.component';
import { SuccessUpsertRemoveComponent } from '@components-modal/success-upsert-remove/success-upsert-remove.component';
import { FailedUpsertRemoveComponent } from '@components-modal/failed-upsert-remove/failed-upsert-remove.component';
import { NoRecipientsCreatedComponent } from '@components-modal/no-recipients-created/no-recipients-created.component';

@Component({
  selector: 'app-alarms',
  templateUrl: './alarms.component.html',
  styleUrls: ['./alarms.component.scss']
})
export class AlarmsComponent implements OnInit, OnDestroy {

  currentPage = 1;
  numberOfAlarms = 0;

  shownAlarmEntries: AlarmMetaInfo[];
  searchText: string;

  allAlarms: AlarmMetaInfo[] = [];
  removeAlarmInProgress = false;

  private metaInfoSubscription: Subscription;
  private settingsSubscription: Subscription;

  constructor(private modalService: NgbModal, private router: Router,
    public alarms: AlarmsService, private settings: SettingsService, private recipients: RecipientsService) {
    this.settings.currentAlarmEntry$.next(AlarmingMenuEntry.Alarms);
  }

  ngOnInit() {
    this.metaInfoSubscription = this.alarms.alarmMetaInfo$.subscribe(metaInfo => {
      this.allAlarms = metaInfo.sort((a1, a2) => {
        if (a1.createdAt > a2.createdAt) {
          return 1;
        }
        if (a1.createdAt < a2.createdAt) {
          return -1;
        }
        return 0;
      });
      this.updateShownEntries();
    });
    this.settingsSubscription = this.settings.showEntriesPerPage$.subscribe(entriesPerPage => {
      this.updateShownEntries(true);
    });
  }

  ngOnDestroy() {
    this.metaInfoSubscription.unsubscribe();
    this.settingsSubscription.unsubscribe();
  }

  onPageChanged(newPage: number) {
    this.currentPage = newPage;
    this.updateShownEntries();
  }

  onRemoveAlarm(alarm: AlarmMetaInfo) {
    const modalRef = this.modalService
      .open(WarningRemoveComponent, { centered: true, size: 'lg', backdrop: 'static', keyboard: false });
    if (modalRef.componentInstance instanceof WarningRemoveComponent) {
      modalRef.componentInstance.name = alarm.name;
      modalRef.componentInstance.type = WarningRemoveComponent.Type.Alarm;
    }
    modalRef.result.then(
      result => {
        this.removeAlarmInProgress = true;
        this.alarms.deleteAlarmWithIdentifier(alarm.identifier).subscribe(
          msg => {
            SuccessUpsertRemoveComponent.openDefaultModal(SuccessUpsertRemoveComponent.Type.AlarmRemoved, this.modalService);
          },
          err => {
            FailedUpsertRemoveComponent.openDefaultModal(err, this.modalService);
            console.error(err);
            this.removeAlarmInProgress = false;
          },
          () => {
            this.removeAlarmInProgress = false;
          }
        );
      },
      reason => {}
    );
  }


  onChangeSearchText(searchText: string) {
    this.searchText = searchText;
    this.updateShownEntries(true);
  }

  onAddNewAlarm() {
    if (this.recipients.recipients$.value.length === 0) {
      const modalRef = NoRecipientsCreatedComponent.openDefaultModal(this.modalService);
      modalRef.result.then(
        result => {
          console.log('result', result);
          this.router.navigate(['/main/add_recipient']);
        },
        reason => {
          this.router.navigate(['/main/add_alarm']);
        }
      );
    } else {
      this.router.navigate(['/main/add_alarm']);
    }
  }

  private updateShownEntries(resetPage: boolean = false) {
    const totalEntries = this.allAlarms.filter(entry => {
      return this.filterByText(entry, this.searchText);
    });
    this.numberOfAlarms = totalEntries.length;
    const entriesPerPage = this.settings.showEntriesPerPage$.value;
    let start: number;
    if (resetPage) {
      this.currentPage = 1;
    }
    start = this.currentPage * entriesPerPage - entriesPerPage;
    start = start < totalEntries.length ? start : 0;
    const end = start + entriesPerPage;
    const clippedEntries = totalEntries.slice(start, end);
    this.shownAlarmEntries = clippedEntries;
  }

  private filterByText(alarm: AlarmMetaInfo, text: String) {
    if (text == null || text.length === 0) {
      return true;
    }
    const t = text.toLocaleLowerCase();
    const name = alarm.name.toLocaleLowerCase();
    if (name.includes(t)) {
      return true;
    }
    return false;
  }

}
