import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { AuthService } from 'services/auth.service';

// see https://stackoverflow.com/questions/47210919/wait-for-http-inside-guard-on-angular-5/47211470

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private auth: AuthService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      if (this.auth.isAuthenticated) {
        console.log('canActivate isAuthenticated true');
        return true;
      }
      // window.location.href = environment.ssoLoginUrl;
      // return of(false);

      return this.auth.verifyLoginState()
        .pipe(
          map(resp => {
            console.log('AuthGuard verifyLoginState success', resp);
            return true;
          }),
          catchError(error => {
            console.error('AuthGuard verifyLoginState failed', error);
            return this.auth.initiateSSO();
          })
        ).pipe(
          map(resp => {
            console.log('AuthGuard canActivate initiateSSO success', resp);
            if (typeof resp === typeof true) {
              return true;
            }
            window.location.href = resp;
            return false;
          })
        );
  }
}
