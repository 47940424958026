import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { MomentModule } from 'ngx-moment';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUser, faSignOutAlt, faBars, faAngleDown, faTrashAlt, faSms, faEnvelope, faInfoCircle, faExternalLinkAlt,
  faSearch, faEdit, faSync, faExclamationTriangle, faSmile, faTimesCircle,
  faTimes, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';


  import { environment } from 'environments/environment';

import { WarningRemoveComponent } from './components-modal/warning-remove/warning-remove.component';
import { SuccessUpsertRemoveComponent } from './components-modal/success-upsert-remove/success-upsert-remove.component';
import { FailedUpsertRemoveComponent } from './components-modal/failed-upsert-remove/failed-upsert-remove.component';
import { UnableDeleteComponent } from './components-modal/unable-delete/unable-delete.component';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './components-screen/main/main.component';
import { AlarmsComponent } from './components-screen/alarms/alarms.component';
import { SsoRedirectComponent } from './components-screen/sso-redirect/sso-redirect.component';
import { AddAlarmComponent } from './components-screen/add-alarm/add-alarm.component';
import { AddAlarmStep1Component } from './components-screen/add-alarm-step1/add-alarm-step1.component';
import { AddAlarmStep2Component } from './components-screen/add-alarm-step2/add-alarm-step2.component';
import { AddAlarmStep3Component } from './components-screen/add-alarm-step3/add-alarm-step3.component';
import { AddAlarmStepsBtnGroupComponent } from './components-general/add-alarm-steps-btn-group/add-alarm-steps-btn-group.component';
import { AddAlarmHeadingComponent } from './components-general/add-alarm-heading/add-alarm-heading.component';
import { SelectOptionsComponent } from './components-general/select-options/select-options.component';
// tslint:disable-next-line:max-line-length
import { AddAlarmAddedEntriesHeadingComponent } from './components-general/add-alarm-added-entries-heading/add-alarm-added-entries-heading.component';
import { LimitNameValidatorDirective } from './directives/limit-name-validator.directive';
import { OnlyAllowNumberDirective } from './directives/only-allow-number.directive';
// tslint:disable-next-line:max-line-length
import { UpsertAlarmRecipientFormContainerComponent } from './components-general/upsert-alarm-recipient-form-container/upsert-alarm-recipient-form-container.component';
import { LoggedOutComponent } from './components-screen/logged-out/logged-out.component';
import { TableInteractiveTopComponent } from './components-general/table-interactive-top/table-interactive-top.component';
import { TableInteractiveBottomComponent } from './components-general/table-interactive-bottom/table-interactive-bottom.component';
import { AlarmLimitsComponent } from './components-screen/alarm-limits/alarm-limits.component';
import { AlarmComponent } from './components-screen/alarm/alarm.component';
import { AlarmRecipientsComponent } from './components-screen/alarm-recipients/alarm-recipients.component';
import { AlarmBtnGroupComponent } from './components-general/alarm-btn-group/alarm-btn-group.component';
import { LimitsTableComponent } from './components-general/limits-table/limits-table.component';
import { RecipientsTableComponent } from './components-general/recipients-table/recipients-table.component';
import { AlarmBottomBtnGroupComponent } from './components-general/alarm-bottom-btn-group/alarm-bottom-btn-group.component';
import { AlarmUpsertRecipientComponent } from './components-screen/alarm-upsert-recipient/alarm-upsert-recipient.component';
// tslint:disable-next-line:max-line-length
import { UpsertAlarmLimitFormContainerComponent } from './components-general/upsert-alarm-limit-form-container/upsert-alarm-limit-form-container.component';
import { AlarmUpsertLimitComponent } from './components-screen/alarm-upsert-limit/alarm-upsert-limit.component';
import { RecipientsAlreadyCreatedComponent } from './components-screen/recipients-already-created/recipients-already-created.component';
import { AlarmsAlreadySentComponent } from './components-screen/alarms-already-sent/alarms-already-sent.component';
import { RecipientAlreadyCreatedComponent } from './components-screen/recipient-already-created/recipient-already-created.component';
import { AlarmAlreadySentComponent } from './components-screen/alarm-already-sent/alarm-already-sent.component';
import { RecipientUpsertComponent } from './components-screen/recipient-upsert/recipient-upsert.component';
// tslint:disable-next-line:max-line-length
import { UpsertRecipientFormContainerComponent } from './components-general/upsert-recipient-form-container/upsert-recipient-form-container.component';
import { TermsAndConditionsComponent } from './components-modal/terms-and-conditions/terms-and-conditions.component';
import { SuccessSentTestAlarmComponent } from './components-modal/success-sent-test-alarm/success-sent-test-alarm.component';
import { FailedSentTestAlarmComponent } from './components-modal/failed-sent-test-alarm/failed-sent-test-alarm.component';
import { FailedUpdateConfirmationComponent } from './components-modal/failed-update-confirmation/failed-update-confirmation.component';
// tslint:disable-next-line:max-line-length
import { NotConfirmedInformationBoxComponent } from './components-general/not-confirmed-information-box/not-confirmed-information-box.component';
import { DropdownSelectOptionsComponent } from './components-general/dropdown-select-options/dropdown-select-options.component';
import { AlarmRecipientLimitsValidatorDirective } from './directives/alarm-recipient-limits-validator.directive';
import { NoRecipientsAddedComponent } from './components-modal/no-recipients-added/no-recipients-added.component';
import { NoRecipientsCreatedComponent } from './components-modal/no-recipients-created/no-recipients-created.component';
import { RecipientWaysToNotifyValidatorDirective } from './directives/recipient-ways-to-notify-validator.directive';

// Add an icon to the library for convenient access in other components
library.add(faUser, faSignOutAlt, faBars, faAngleDown, faTrashAlt, faSearch, faEnvelope, faInfoCircle, faQuestionCircle,
  faEdit, faSync, faExclamationTriangle, faSmile, faTimesCircle, faTimes, faSms, faExternalLinkAlt);

@NgModule({
  entryComponents: [
    WarningRemoveComponent,
    SuccessUpsertRemoveComponent,
    FailedUpsertRemoveComponent,
    UnableDeleteComponent,
    TermsAndConditionsComponent,
    SuccessSentTestAlarmComponent,
    FailedSentTestAlarmComponent,
    FailedUpdateConfirmationComponent,
    NoRecipientsAddedComponent,
    NoRecipientsCreatedComponent
  ],
  declarations: [
    AppComponent,
    MainComponent,
    AlarmsComponent,
    SsoRedirectComponent,
    AddAlarmComponent,
    AddAlarmStep1Component,
    AddAlarmStep2Component,
    AddAlarmStep3Component,
    AddAlarmStepsBtnGroupComponent,
    AddAlarmHeadingComponent,
    SelectOptionsComponent,
    AddAlarmAddedEntriesHeadingComponent,
    LimitNameValidatorDirective,
    OnlyAllowNumberDirective,
    UpsertAlarmRecipientFormContainerComponent,
    LoggedOutComponent,
    TableInteractiveTopComponent,
    TableInteractiveBottomComponent,
    AlarmLimitsComponent,
    AlarmComponent,
    AlarmRecipientsComponent,
    AlarmBtnGroupComponent,
    LimitsTableComponent,
    RecipientsTableComponent,
    AlarmBottomBtnGroupComponent,
    AlarmUpsertRecipientComponent,
    UpsertAlarmLimitFormContainerComponent,
    AlarmUpsertLimitComponent,
    RecipientsAlreadyCreatedComponent,
    AlarmsAlreadySentComponent,
    RecipientAlreadyCreatedComponent,
    AlarmAlreadySentComponent,
    WarningRemoveComponent,
    SuccessUpsertRemoveComponent,
    FailedUpsertRemoveComponent,
    UnableDeleteComponent,
    RecipientUpsertComponent,
    UpsertRecipientFormContainerComponent,
    TermsAndConditionsComponent,
    SuccessSentTestAlarmComponent,
    FailedSentTestAlarmComponent,
    FailedUpdateConfirmationComponent,
    NotConfirmedInformationBoxComponent,
    DropdownSelectOptionsComponent,
    AlarmRecipientLimitsValidatorDirective,
    NoRecipientsAddedComponent,
    NoRecipientsCreatedComponent,
    RecipientWaysToNotifyValidatorDirective,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    NgxIntlTelInputModule,
    HttpClientModule,
    FontAwesomeModule,
    MomentModule,
    CommonModule
  ],
  providers: [
    // AuthGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

if (environment.showLogs === false) {
  console.log = function() {};
}
