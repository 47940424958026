<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title" i18n>Error</h4>
  </div>
  <div class="modal-body">
    <p class="icon">
      <fa-icon [icon]="['fas', 'times-circle']" size="4x"></fa-icon>
    </p>
    <p i18n>
      An error occurred while executing the action.
    </p>
    <div class="alert alert-danger" role="alert">
      {{ message }}
    </div>
  </div>
  <div class="modal-footer one">
    <button type="button" class="btn btn-primary btn-phoenix" (click)="modal.close('Ok click')">Okay.</button>
  </div>
