import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';

import { SettingsService, EntriesPerPage } from 'services/settings.service';
import { SelectOptionsComponent } from '@components-general/select-options/select-options.component';

@Component({
  selector: 'app-table-interactive-top',
  templateUrl: './table-interactive-top.component.html',
  styleUrls: ['./table-interactive-top.component.scss']
})
export class TableInteractiveTopComponent implements OnInit, OnDestroy {

  @ViewChild('pagination') pagination: ElementRef;

  // select number of entries per page
  entriesPerPage = EntriesPerPage.five;
  availableEntriesPerPageOptions: SelectOptionsComponent.Option[] = [];
  selectedEntriesPerPageOption: SelectOptionsComponent.Option[];

  // pagination
  @Input() page = 1;
  @Input() collectionSize = 10;
  @Input() searchText: string;

  @Output() pageChanged = new EventEmitter<number>();
  @Output() searchTextChanged = new EventEmitter<string>();

  maxSize = 3;
  private showEntriesPerPageSubscription: Subscription;

  constructor(public settings: SettingsService) { }

  ngOnInit() {
    this.showEntriesPerPageSubscription = this.settings.showEntriesPerPage$.subscribe(count => {
      this.entriesPerPage = count;
      this.selectedEntriesPerPageOption = [{
        identifier: `${count}`,
        name: `${count}`
      }];
    });
    this.availableEntriesPerPageOptions = this.settings.availableEntriesPerPage.map(e => {
      return {
        identifier: `${e}`,
        name: `${e}`
      };
    });
  }

  ngOnDestroy() {
    this.showEntriesPerPageSubscription.unsubscribe();
  }

  onPageChanged(newPage: number) {
    this.pageChanged.emit(newPage);
  }

  onEntriesPerPageModelChanged(newOption: SelectOptionsComponent.Option) {
    const entriesPerPage = EntriesPerPage.entryFromText(newOption[0].identifier);
    this.settings.showEntriesPerPage$.next(entriesPerPage);
    this.entriesPerPage = entriesPerPage;
  }

  onChangeSearchText() {
    this.searchTextChanged.emit(this.searchText);
  }

}
