import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';

import { RecipientsService } from 'services/recipients.service';
import { AlarmsService } from 'services/alarms.service';
import { Alarm } from 'services/alarms.model';
import { Recipient } from 'services/recipients.model';
import { DropdownSelectOptionsComponent } from '@components-general/dropdown-select-options/dropdown-select-options.component';

@Component({
  selector: 'app-upsert-alarm-recipient-form-container',
  templateUrl: './upsert-alarm-recipient-form-container.component.html',
  styleUrls: ['./upsert-alarm-recipient-form-container.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer, useExisting: NgForm
    }
  ]
})
export class UpsertAlarmRecipientFormContainerComponent implements OnInit, OnDestroy {

  @Input() alarm: Alarm;
  @Input() recipient: Alarm.Recipient;
  @Input() inViewerMode: boolean;
  @Input() selectRecipientDisabled = false;

  @Output() recipientChanged = new EventEmitter<Alarm.Recipient>();

  recipientsContentMode = DropdownSelectOptionsComponent.ContentMode.recipients;

  availableLimits: UpsertAlarmRecipientFormContainerComponent.SelectableLimit[] = [];
  selectedLimits: UpsertAlarmRecipientFormContainerComponent.SelectableLimit[] = [];

  availableRecipientOptions: DropdownSelectOptionsComponent.Option[] = [];
  selectedRecipientOptions: DropdownSelectOptionsComponent.Option[] = [];

  isRecipientSelected = false;
  hasRecipientMail = false;
  hasRecipientSMS = false;

  mailSelected = false;
  smsSelected = false;
  smsRequired = true;
  mailRequired = true;

  recipientsReloading = false;

  recipientsSubscription: Subscription;

  constructor(private recipients: RecipientsService, private alarms: AlarmsService) { }

  ngOnInit() {
    this.hasRecipientMail = false;
    this.hasRecipientSMS = false;
    this.recipientsSubscription = this.recipients.recipients$.subscribe(recipients => {
      this.updateAvailableRecipients(recipients);
    });
    this.availableLimits = this.alarm.limits.map(l => {
      return {
        name: l.name,
        identifier: l.identifier,
        selected: false
      };
    });
    this.updatePrefilledRecipient(this.recipient.identifier);
  }

  ngOnDestroy() {
    this.recipientsSubscription.unsubscribe();
  }

  triggerUpdateAvailableRecipients() {
    const recipients = this.recipients.recipients$.value;
    this.updateAvailableRecipients(recipients);
  }

  onSelectedRecipientsModelChanged(newOptions: DropdownSelectOptionsComponent.Option[]) {
    let alarmRecipient: Alarm.Recipient;
    this.isRecipientSelected = false;
    this.hasRecipientMail = false;
    this.hasRecipientSMS = false;
    if (newOptions != null && newOptions.length > 0) {
      const option = newOptions[0];
      const recipient = this.recipients.recipientByIdentifier(option.identifier);
      if (recipient != null) {
        alarmRecipient = this.alarms.createPlainAlarmRecipient();
        alarmRecipient.identifier = recipient.identifier;
        alarmRecipient.recipientName = recipient.name;
        alarmRecipient.noticeByMail = recipient.mail ? Alarm.Recipient.Notice.byMail : null;
        alarmRecipient.noticeBySMS = recipient.mobile ? Alarm.Recipient.Notice.bySMS : null;
        alarmRecipient.subscribedToLimits = this.selectedLimitsFromAvailableLimits();
        this.hasRecipientMail = recipient.mail != null;
        this.hasRecipientSMS = recipient.mobile != null;
        this.smsSelected = this.hasRecipientSMS;
        this.mailSelected = this.hasRecipientMail;
        // this.selectedLimitsOptions = [];
        this.isRecipientSelected = true;
      }
    } else {
      // form resettet
      const recipients = this.recipients.recipients$.value;
      this.updateAvailableRecipients(recipients);
    }
    this.recipientChanged.emit(alarmRecipient);
  }

  onSelectedSMSModelChanged(selected: boolean) {
    if (selected) {
      this.recipient.noticeBySMS = Alarm.Recipient.Notice.bySMS;
    } else {
      this.recipient.noticeBySMS = undefined;
    }
    this.recipientChanged.emit(this.recipient);
    this.updateSMSRequired();
    this.updateMailRequired();
  }

  onSelectedMailModelChanged(selected: boolean) {
    if (selected) {
      this.recipient.noticeByMail = Alarm.Recipient.Notice.byMail;
    } else {
      this.recipient.noticeByMail = undefined;
    }
    this.recipientChanged.emit(this.recipient);
    this.updateSMSRequired();
    this.updateMailRequired();
  }

  onSelectLimitChanged(limit: UpsertAlarmRecipientFormContainerComponent.SelectableLimit) {
    console.log('onSelectLimitChanged', limit);
    this.recipient.subscribedToLimits = this.selectedLimitsFromAvailableLimits();
    this.recipientChanged.emit(this.recipient);
  }

  mailDisabled() {
    if (this.isRecipientSelected && this.hasRecipientMail && this.smsSelected) {
      return false;
    }
    return true;
  }

  smsDisabled() {
    if (this.isRecipientSelected && this.hasRecipientSMS && this.mailSelected) {
      return false;
    }
    return true;
  }

  private selectedLimitsFromAvailableLimits() {
    const selectedLimits: Alarm.Recipient.SubscribedLimit[] = this.availableLimits.filter(l => {
      return l.selected;
    }).map(l => {
      return {
        identifier: l.identifier
      };
    });
    return selectedLimits;
  }

  private updateAvailableRecipients(recipients: Recipient[]) {
    // Don't add already defined reciepents to the list.
    const filteredRecipients = recipients.filter(r => {
      return Alarm.Recipient.recipientByIdentifier(r.identifier, this.alarm) == null;
    });
    this.availableRecipientOptions = filteredRecipients.map<DropdownSelectOptionsComponent.Option>(r => {
      return {
        name: r.name,
        identifier: r.identifier,
        recipient: {
          mail: r.mail,
          mailConfirmed: r.mailConfirmed,
          mobile: r.mobile,
          smsConfirmed: r.smsConfirmed
        }
      };
    });
  }

  private updateSMSRequired() {
    let req = false;
    if (this.hasRecipientSMS) {
      req = !this.mailSelected;
    }
    this.smsRequired = req;
  }

  private updateMailRequired() {
    let req = false;
    if (this.hasRecipientMail) {
      req = !this.smsSelected;
    }
    this.mailRequired = req;
  }

  private updatePrefilledRecipient(recipientId: string) {
    const origRecipient = this.recipients.recipientByIdentifier(recipientId);
    const recipient = Alarm.Recipient.recipientByIdentifier(recipientId, this.alarm);
    if (recipient != null && origRecipient != null) {
      this.selectedRecipientOptions = [{
        name: origRecipient.name,
        identifier: origRecipient.identifier,
        recipient: {
          mail: origRecipient.mail,
          mailConfirmed: origRecipient.mailConfirmed,
          mobile: origRecipient.mobile,
          smsConfirmed: origRecipient.smsConfirmed
        }
      }];
      recipient.subscribedToLimits.forEach(l => {
        const selectedLimit = this.availableLimits.find(aL => {
          return aL.identifier === l.identifier;
        });
        if (selectedLimit != null) {
          selectedLimit.selected = true;
        }
      });
      this.hasRecipientMail = origRecipient.mail != null;
      this.hasRecipientSMS = origRecipient.mobile != null;
      this.mailSelected = recipient.noticeByMail != null;
      this.smsSelected = recipient.noticeBySMS != null;
      this.isRecipientSelected = true;
    } else {
      this.isRecipientSelected = false;
    }
  }

}

export namespace UpsertAlarmRecipientFormContainerComponent {
  export interface SelectableLimit {
    name: string;
    identifier: string;
    selected: boolean;
  }
}
