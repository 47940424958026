import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-no-recipients-created',
  templateUrl: './no-recipients-created.component.html',
  styleUrls: ['./no-recipients-created.component.scss']
})
export class NoRecipientsCreatedComponent implements OnInit {

  constructor(public modal: NgbActiveModal) { }

  static openDefaultModal(modalService: NgbModal) {
    const modal = modalService.open(NoRecipientsCreatedComponent,
      { centered: true, size: 'lg', backdrop: 'static', keyboard: false});
    return modal;
  }

  ngOnInit() {
  }

}
