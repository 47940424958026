/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./limits-table.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i3 from "@fortawesome/angular-fontawesome";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@angular/common";
import * as i6 from "./limits-table.component";
import * as i7 from "../../../services/metrics.service";
var styles_LimitsTableComponent = [i0.styles];
var RenderType_LimitsTableComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LimitsTableComponent, data: {} });
export { RenderType_LimitsTableComponent as RenderType_LimitsTableComponent };
function View_LimitsTableComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "btn btn-light btn-sm"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onEditLimit(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "fa-icon", [["class", "ng-fa-icon"]], [[8, "innerHTML", 1]], null, null, i2.View_FaIconComponent_0, i2.RenderType_FaIconComponent)), i1.ɵdid(2, 573440, null, 0, i3.FaIconComponent, [i4.DomSanitizer, i3.FaIconService], { iconProp: [0, "iconProp"] }, null), i1.ɵpad(3, 2)], function (_ck, _v) { var currVal_1 = _ck(_v, 3, 0, "fas", "edit"); _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).renderedIconHTML; _ck(_v, 1, 0, currVal_0); }); }
function View_LimitsTableComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [["class", "d-table-cell align-middle"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "td", [["class", "d-none d-md-table-cell align-middle"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "td", [["class", "d-table-cell align-middle"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "td", [["class", "d-none d-md-table-cell align-middle"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "td", [["class", "d-none d-md-table-cell align-middle"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵeld(11, 0, null, null, 7, "td", [["class", "d-table-cell align-middle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 6, "div", [["class", "float-right"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LimitsTableComponent_2)), i1.ɵdid(14, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 3, "button", [["class", "btn btn-light btn-sm"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onRemoveLimit(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 2, "fa-icon", [["class", "ng-fa-icon"]], [[8, "innerHTML", 1]], null, null, i2.View_FaIconComponent_0, i2.RenderType_FaIconComponent)), i1.ɵdid(17, 573440, null, 0, i3.FaIconComponent, [i4.DomSanitizer, i3.FaIconService], { iconProp: [0, "iconProp"] }, null), i1.ɵpad(18, 2)], function (_ck, _v) { var _co = _v.component; var currVal_5 = (_co.showEdit === true); _ck(_v, 14, 0, currVal_5); var currVal_8 = _ck(_v, 18, 0, "fas", "trash-alt"); _ck(_v, 17, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.limitName; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.metricName; _ck(_v, 4, 0, currVal_1); var currVal_2 = _v.context.$implicit.limitsDescription; _ck(_v, 6, 0, currVal_2); var currVal_3 = (_co.currentValueForMetric(_v.context.$implicit.metricName) || "---"); _ck(_v, 8, 0, currVal_3); var currVal_4 = _v.context.$implicit.notificationText; _ck(_v, 10, 0, currVal_4); var currVal_6 = _co.removeLimitsDisabled; _ck(_v, 15, 0, currVal_6); var currVal_7 = i1.ɵnov(_v, 17).renderedIconHTML; _ck(_v, 16, 0, currVal_7); }); }
export function View_LimitsTableComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "div", [["class", "table-responsive"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 17, "table", [["class", "table"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 12, "thead", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 11, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "th", [["class", "d-table-cell"], ["scope", "col"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Name limit"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "th", [["class", "d-none d-md-table-cell"], ["scope", "col"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Metric name"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "th", [["class", "d-table-cell"], ["scope", "col"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Limits"])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "th", [["class", "d-none d-md-table-cell"], ["scope", "col"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Current value"])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "th", [["class", "d-none d-md-table-cell"], ["scope", "col"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Notice"])), (_l()(), i1.ɵeld(14, 0, null, null, 0, "th", [["class", "d-table-cell"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 3, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_LimitsTableComponent_1)), i1.ɵdid(17, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 17, 0, i1.ɵnov(_v, 18).transform(_co.limits$)); _ck(_v, 17, 0, currVal_0); }, null); }
export function View_LimitsTableComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-limits-table", [], null, null, null, View_LimitsTableComponent_0, RenderType_LimitsTableComponent)), i1.ɵdid(1, 245760, null, 0, i6.LimitsTableComponent, [i7.MetricsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LimitsTableComponentNgFactory = i1.ɵccf("app-limits-table", i6.LimitsTableComponent, View_LimitsTableComponent_Host_0, { limits$: "limits$", showEdit: "showEdit", removeLimitsDisabled: "removeLimitsDisabled" }, { removeLimit: "removeLimit", editLimit: "editLimit" }, []);
export { LimitsTableComponentNgFactory as LimitsTableComponentNgFactory };
