<div class="table-responsive">
  <table class="table">
    <thead>
      <tr>
        <th scope="col" class="d-table-cell" i18n>Recipients name</th>
        <th scope="col" class="d-none d-md-table-cell" i18n>Way to notice</th>
        <th scope="col" class="d-table-cell" i18n>Subscriptions</th>
        <th class="d-table-cell"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let recipient of recipients$ | async">
        <td class="d-table-cell align-middle">{{ recipient.recipientName }}</td>
        <td class="d-none d-md-table-cell align-middle">{{ recipient.wayToNotice }}</td>
        <td class="d-table-cell align-middle">{{ recipient.subscribedTo }}</td>
        <td class="d-table-cell align-middle">
          <div class="float-right">
            <button class="btn btn-light btn-sm" *ngIf="showEdit === true" (click)="onEditRecipient(recipient)">
              <fa-icon [icon]="['fas', 'edit']"></fa-icon>
            </button>
            <button type="button" class="btn btn-light btn-sm" (click)="onRemoveRecipient(recipient)" [disabled]="removeRecipientsDisabled">
              <fa-icon [icon]="['fas', 'trash-alt']"></fa-icon>
            </button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>