export interface Recipient {
    identifier: string|undefined;
    name: string;
    mail?: string;
    mobile?: string;
    mailConfirmed: boolean;
    smsConfirmed: boolean;
}

export namespace Recipient {
    export function deepCopy(recipient: Recipient) {
        const cRecipient = Object.assign({}, recipient);
        return cRecipient;
    }

    export interface Subscription {
        alarm: string;
        limit: string;
        mail: boolean;
        sms: boolean;
    }
}

export namespace Recipient.Confirmation {
    export enum Type {
        mail = 'mail', sms = 'sms'
    }

    // tslint:disable-next-line:max-line-length
    export function isFullConfirmedRecipient(mail: string|undefined, mailConfirmed: boolean, mobile: string|undefined, smsConfirmed: boolean) {
        const hasMail = mail != null && mail.length > 0;
        const hasMobile = mobile != null && mobile.length > 0;
        if (hasMail && mailConfirmed === false) {
            return false;
        }
        if (hasMobile && smsConfirmed === false) {
            return false;
        }
        return true;
    }
}
