<div class="btn-group" role="group" aria-label="Alarm limits and recipients">
    <button [routerLink]="['/main/alarm', alarmId, 'limits']" type="button" class="btn"
        [disabled]="alarmId == null"
        [ngClass]="{'btn-light': !isStepActive(Step.Limits), 'btn-secondary': isStepActive(Step.Limits)}"
        i18n>Limits</button>
    <button [routerLink]="['/main/alarm/', alarmId, 'recipients']" type="button" class="btn"
        [disabled]="alarmId == null"
        [ngClass]="{'btn-light': !isStepActive(Step.Recipients), 'btn-secondary': isStepActive(Step.Recipients)}"
        i18n>Recipients</button>
  </div>
