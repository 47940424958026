import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { SubscriptionsMiddleware } from 'services/subscriptions.middleware';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionsService {

  constructor(private http: HttpClient) { }

  allSubscriptions() {
    return new SubscriptionsMiddleware(this.http).allSubscriptions();
  }
}
