import { Component, OnInit, Input } from '@angular/core';

export namespace AddAlarmHeading {
  export interface MiddleEntry {
    name: string;
    routerLinkValue: string;
  }

  export enum Mode {
    Alarms, RecipientsAlreadyCreated, AlarmsSent
  }
}

@Component({
  selector: 'app-add-alarm-heading',
  templateUrl: './add-alarm-heading.component.html',
  styleUrls: ['./add-alarm-heading.component.scss']
})
export class AddAlarmHeadingComponent implements OnInit {

  @Input() lastPathName: string;
  @Input() middleEntries: AddAlarmHeading.MiddleEntry[];
  @Input() mode = AddAlarmHeading.Mode.Alarms;

  Mode = AddAlarmHeading.Mode;

  constructor() { }

  ngOnInit() {
  }



}
