<div class="table-responsive">
  <table class="table">
    <thead>
      <tr>
        <th scope="col" class="d-table-cell" i18n>Name limit</th>
        <th scope="col" class="d-none d-md-table-cell" i18n>Metric name</th>
        <th scope="col" class="d-table-cell" i18n>Limits</th>
        <th scope="col" class="d-none d-md-table-cell" i18n>Current value</th>
        <th scope="col" class="d-none d-md-table-cell" i18n>Notice</th>
        <th class="d-table-cell"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let limit of limits$ | async">
        <td class="d-table-cell align-middle">{{ limit.limitName }}</td>
        <td class="d-none d-md-table-cell align-middle">{{ limit.metricName }}</td>
        <td class="d-table-cell align-middle">{{ limit.limitsDescription }}</td>
        <td class="d-none d-md-table-cell align-middle">{{ currentValueForMetric(limit.metricName) || '---' }}</td>
        <td class="d-none d-md-table-cell align-middle">{{ limit.notificationText }}</td>
        <td class="d-table-cell align-middle">
          <div class="float-right">
            <button class="btn btn-light btn-sm" *ngIf="showEdit === true" (click)="onEditLimit(limit)">
              <fa-icon [icon]="['fas', 'edit']"></fa-icon>
            </button>
            <button type="button" class="btn btn-light btn-sm" (click)="onRemoveLimit(limit)" [disabled]="removeLimitsDisabled">
              <fa-icon [icon]="['fas', 'trash-alt']"></fa-icon>
            </button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>