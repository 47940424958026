/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./alarm.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./alarm.component";
import * as i4 from "../../../services/alarms.service";
import * as i5 from "../../../services/settings.service";
var styles_AlarmComponent = [i0.styles];
var RenderType_AlarmComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AlarmComponent, data: {} });
export { RenderType_AlarmComponent as RenderType_AlarmComponent };
export function View_AlarmComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_AlarmComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-alarm", [], null, null, null, View_AlarmComponent_0, RenderType_AlarmComponent)), i1.ɵdid(1, 245760, null, 0, i3.AlarmComponent, [i2.ActivatedRoute, i4.AlarmsService, i5.SettingsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlarmComponentNgFactory = i1.ɵccf("app-alarm", i3.AlarmComponent, View_AlarmComponent_Host_0, {}, {}, []);
export { AlarmComponentNgFactory as AlarmComponentNgFactory };
