import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { map } from 'rxjs/operators';

import { Recipient } from '@services/recipients.model';
import { SubscriptionsService } from 'services/subscriptions.service';
import { SubscriptionMetaInfo } from 'services/subscriptions.model';

@Injectable({
    providedIn: 'root'
  })
export class SubscriptionsRecipientsUnconfirmedResolve implements Resolve<SubscriptionMetaInfo[]> {
    constructor(private subscriptions: SubscriptionsService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        console.log('SubscriptionsRecipientsUnconfirmedResolve');
        return this.subscriptions.allSubscriptions().pipe(
            map(resp => {
                const metaInfos = resp.filter(info => {
                    // tslint:disable-next-line:max-line-length
                    const isConfirmed = Recipient.Confirmation.isFullConfirmedRecipient(info.mail, info.mailConfirmed, info.mobile, info.smsConfirmed);
                    return isConfirmed ? false : true;
                });
                return metaInfos;
            })
        );
    }
}
