/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./table-interactive-bottom.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@ng-bootstrap/ng-bootstrap/ng-bootstrap.ngfactory";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
import * as i4 from "./table-interactive-bottom.component";
import * as i5 from "../../../services/settings.service";
var styles_TableInteractiveBottomComponent = [i0.styles];
var RenderType_TableInteractiveBottomComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TableInteractiveBottomComponent, data: {} });
export { RenderType_TableInteractiveBottomComponent as RenderType_TableInteractiveBottomComponent };
export function View_TableInteractiveBottomComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "left-side"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["Showing ", " to ", " from ", "."])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "ngb-pagination", [["class", "float-right phoenix-pagination"], ["role", "navigation"]], null, [[null, "pageChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("pageChange" === en)) {
        var pd_0 = ((_co.page = $event) !== false);
        ad = (pd_0 && ad);
    } if (("pageChange" === en)) {
        var pd_1 = (_co.onPageChanged($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_NgbPagination_0, i2.RenderType_NgbPagination)), i1.ɵdid(7, 573440, null, 0, i3.NgbPagination, [i3.NgbPaginationConfig], { boundaryLinks: [0, "boundaryLinks"], ellipses: [1, "ellipses"], collectionSize: [2, "collectionSize"], maxSize: [3, "maxSize"], page: [4, "page"], pageSize: [5, "pageSize"] }, { pageChange: "pageChange" })], function (_ck, _v) { var _co = _v.component; var currVal_3 = true; var currVal_4 = false; var currVal_5 = _co.collectionSize; var currVal_6 = _co.maxSize; var currVal_7 = _co.page; var currVal_8 = _co.entriesPerPage; _ck(_v, 7, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showing1; var currVal_1 = _co.showing2; var currVal_2 = _co.showing3; _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_TableInteractiveBottomComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-table-interactive-bottom", [], null, null, null, View_TableInteractiveBottomComponent_0, RenderType_TableInteractiveBottomComponent)), i1.ɵdid(1, 770048, null, 0, i4.TableInteractiveBottomComponent, [i5.SettingsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TableInteractiveBottomComponentNgFactory = i1.ɵccf("app-table-interactive-bottom", i4.TableInteractiveBottomComponent, View_TableInteractiveBottomComponent_Host_0, { page: "page", collectionSize: "collectionSize", entriesPerPage: "entriesPerPage" }, { pageChanged: "pageChanged" }, []);
export { TableInteractiveBottomComponentNgFactory as TableInteractiveBottomComponentNgFactory };
