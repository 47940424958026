import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { map, tap, concatMap } from 'rxjs/operators';
import { Recipient } from 'services/recipients.model';
import { RecipientsMiddleware } from './recipients.middleware';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var RecipientsService = /** @class */ (function () {
    function RecipientsService(http) {
        this.http = http;
        this.recipients$ = new BehaviorSubject([]);
        this.recipientsUnconfirmed$ = new BehaviorSubject([]);
        this.countRecipientsUnconfirmed = 0;
    }
    RecipientsService.prototype.recipientByIdentifier = function (identifier) {
        var allRecepients = this.recipients$.value;
        var recipient = allRecepients.find(function (r) {
            return r.identifier === identifier;
        });
        return recipient;
    };
    RecipientsService.prototype.resetRecipients = function () {
        this.recipients$.next([]);
    };
    RecipientsService.prototype.refreshRecipients = function () {
        var _this = this;
        return new RecipientsMiddleware(this.http).refreshRecipients().pipe(tap(function (resp) {
            _this.recipients$.next(resp);
            _this.updateUnconfirmedRecipients(resp);
        }));
    };
    RecipientsService.prototype.getRecipientByIdentifier = function (identifier) {
        return new RecipientsMiddleware(this.http).getRecipient(identifier);
    };
    RecipientsService.prototype.subscriptionsWithRecipientIdentifier = function (identifier) {
        return new RecipientsMiddleware(this.http).getRecipientSubscriptionsWithRecipientIdentifier(identifier);
    };
    RecipientsService.prototype.upsertRecipient = function (recipient) {
        var _this = this;
        var exists = this.recipients$.value.findIndex(function (r) {
            return r.identifier === recipient.identifier;
        });
        var mw = new RecipientsMiddleware(this.http);
        var recipientObs;
        if (exists > -1) {
            // update
            recipientObs = mw.updateRecipient(recipient);
        }
        else {
            // add
            recipientObs = mw.createNewRecipient(recipient);
        }
        var upsertedRecipient;
        return recipientObs.pipe(concatMap(function (msg) {
            upsertedRecipient = msg;
            return _this.refreshRecipients();
        })).pipe(map(function (msg) {
            return upsertedRecipient;
        }));
    };
    RecipientsService.prototype.deleteRecipientWithIdentifier = function (identifier) {
        var _this = this;
        return new RecipientsMiddleware(this.http).deleteRecipientWithIdentifier(identifier).pipe(concatMap(function (msg) {
            return _this.refreshRecipients();
        }));
    };
    RecipientsService.prototype.confirmWayToNotifiyRecipientWithIdentifier = function (identifier, type) {
        var _this = this;
        return new RecipientsMiddleware(this.http).confirmRecipientWithIdentifier(identifier, type).pipe(tap(function (resp) {
            _this.updateRecipientConfirmation(identifier, true, type);
            _this.updateUnconfirmedRecipients(_this.recipients$.value);
        }));
    };
    RecipientsService.prototype.unconfirmWayToNotifiyRecipientWithIdentifier = function (identifier, type) {
        var _this = this;
        return new RecipientsMiddleware(this.http).unconfirmRecipientWithIdentifier(identifier, type).pipe(tap(function (resp) {
            _this.updateRecipientConfirmation(identifier, false, type);
            _this.updateUnconfirmedRecipients(_this.recipients$.value);
        }));
    };
    RecipientsService.prototype.sendTestAlarmToRecipientWithIdentifier = function (identifier) {
        return new RecipientsMiddleware(this.http).sendTestAlarmToRecipientWithIdentifier(identifier);
    };
    // tslint:disable-next-line:max-line-length
    RecipientsService.prototype.updateRecipientConfirmation = function (recipientIdentifier, confirmed, type) {
        var recipients = this.recipients$.value;
        var recipient = recipients.find(function (r) {
            return r.identifier === recipientIdentifier;
        });
        if (recipient != null) {
            switch (type) {
                case Recipient.Confirmation.Type.mail:
                    recipient.mailConfirmed = confirmed;
                    break;
                case Recipient.Confirmation.Type.sms:
                    recipient.smsConfirmed = confirmed;
                    break;
            }
        }
    };
    RecipientsService.prototype.updateUnconfirmedRecipients = function (recipients) {
        var unconfirmedRecipients = recipients.filter(function (r) {
            var isConfirmed = Recipient.Confirmation.isFullConfirmedRecipient(r.mail, r.mailConfirmed, r.mobile, r.smsConfirmed);
            return isConfirmed ? false : true;
        });
        this.recipientsUnconfirmed$.next(unconfirmedRecipients);
        this.countRecipientsUnconfirmed = unconfirmedRecipients.length;
    };
    RecipientsService.ngInjectableDef = i0.defineInjectable({ factory: function RecipientsService_Factory() { return new RecipientsService(i0.inject(i1.HttpClient)); }, token: RecipientsService, providedIn: "root" });
    return RecipientsService;
}());
export { RecipientsService };
