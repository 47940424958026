import { AlarmsService } from 'services/alarms.service';
import { of } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../../services/alarms.service";
var CreateAlarmResolve = /** @class */ (function () {
    function CreateAlarmResolve(alarms) {
        this.alarms = alarms;
    }
    CreateAlarmResolve.prototype.resolve = function (route, state) {
        var alarm = this.alarms.createPlainAlarm();
        this.alarms.alarmToBeCreated = alarm;
        return of(alarm);
    };
    CreateAlarmResolve.ngInjectableDef = i0.defineInjectable({ factory: function CreateAlarmResolve_Factory() { return new CreateAlarmResolve(i0.inject(i1.AlarmsService)); }, token: CreateAlarmResolve, providedIn: "root" });
    return CreateAlarmResolve;
}());
export { CreateAlarmResolve };
