<div class="content-main">
  <ng-container [ngSwitch]="mode">
    <div *ngSwitchCase="Mode.default" class="content-heading" i18n>
      Recipients
    </div>
    <div *ngSwitchCase="Mode.recipientsUnconfirmed" class="content-heading" i18n>
      Unconfirmed Recipients
    </div>
  </ng-container>
  <div class="content-body">
    <div class="content-padding">
      <app-table-interactive-top [page]="currentPage" [collectionSize]="numberOfRecipients"
        (pageChanged)="onPageChanged($event)" (searchTextChanged)="onChangeSearchText($event)">
      </app-table-interactive-top>
    </div>
    <table class="table" *ngIf="allEntries.length > 0; else noSubscriptionsCreated">
      <thead>
        <tr>
          <th scope="col" class="d-table-cell" i18n>Name</th>
          <th scope="col" class="d-none d-md-table-cell" i18n>Mail</th>
          <th scope="col" class="d-none d-md-table-cell" i18n>SMS</th>
          <th scope="col" class="d-table-cell" i18n>Subscriptions</th>
          <th scope="col" class="d-table-cell"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let entry of shownEntries">
          <td class="d-table-cell align-middle">{{ entry.name }}</td>
          <td class="d-none d-md-table-cell align-middle" [ngClass]="{'unconfirmed': !entry.mailConfirmed}">
            {{ entry.mail }}</td>
          <td class="d-none d-md-table-cell align-middle" [ngClass]="{'unconfirmed': !entry.smsConfirmed}">
            {{ entry.mobile }}</td>
          <td class="d-table-cell align-middle">{{ entry.alarms }} Alarms / {{entry.limits }} Limits</td>
          <td class="d-table-cell align-middle">
            <div class="float-right">
              <button class="btn btn-light btn-sm" (click)="onShowRecipientDetails(entry)">
                <fa-icon [icon]="['fas', 'external-link-alt']"></fa-icon>
              </button>
              <button type="button" class="btn btn-light btn-sm" (click)="onRemoveRecipient(entry)" [disabled]="removeRecipientInProgress">
                <fa-icon [icon]="['fas', 'trash-alt']"></fa-icon>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="content-padding">
      <app-table-interactive-bottom [page]="currentPage" [collectionSize]="numberOfRecipients"
        (pageChanged)="onPageChanged($event)"></app-table-interactive-bottom>
    </div>
    <ng-template #noSubscriptionsCreated>
      <ng-container [ngSwitch]="mode">
          <div *ngSwitchCase="Mode.default" class="content-padding" i18n>
            No recipients created.
          </div>
          <div *ngSwitchCase="Mode.recipientsUnconfirmed" class="content-padding" i18n>
            No unconfirmed recipients.
          </div>
        </ng-container>
    </ng-template>
  </div>
</div>
<button *ngIf="mode===Mode.default" class="btn btn-primary btn-phoenix float-right" (click)="onNewRecipientsClicked()" i18n>Add new recipient</button>
