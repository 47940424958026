/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./no-recipients-added.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i3 from "@fortawesome/angular-fontawesome";
import * as i4 from "@angular/platform-browser";
import * as i5 from "./no-recipients-added.component";
import * as i6 from "@ng-bootstrap/ng-bootstrap";
var styles_NoRecipientsAddedComponent = [i0.styles];
var RenderType_NoRecipientsAddedComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NoRecipientsAddedComponent, data: {} });
export { RenderType_NoRecipientsAddedComponent as RenderType_NoRecipientsAddedComponent };
export function View_NoRecipientsAddedComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title"], ["id", "modal-basic-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["You did not create any recipients"])), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "p", [["class", "icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "fa-icon", [["class", "ng-fa-icon"], ["size", "4x"]], [[8, "innerHTML", 1]], null, null, i2.View_FaIconComponent_0, i2.RenderType_FaIconComponent)), i1.ɵdid(6, 573440, null, 0, i3.FaIconComponent, [i4.DomSanitizer, i3.FaIconService], { iconProp: [0, "iconProp"], size: [1, "size"] }, null), i1.ɵpad(7, 2), (_l()(), i1.ɵeld(8, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" The alarm you are creating does not have a target recipient."])), (_l()(), i1.ɵeld(10, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" You can create recipients and add them to this alarm. "])), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["class", "modal-footer one"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "button", [["class", "btn btn-primary btn-phoenix"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.modal.close("Ok click") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Okay"]))], function (_ck, _v) { var currVal_1 = _ck(_v, 7, 0, "fas", "info-circle"); var currVal_2 = "4x"; _ck(_v, 6, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 6).renderedIconHTML; _ck(_v, 5, 0, currVal_0); }); }
export function View_NoRecipientsAddedComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-no-recipients-added", [], null, null, null, View_NoRecipientsAddedComponent_0, RenderType_NoRecipientsAddedComponent)), i1.ɵdid(1, 114688, null, 0, i5.NoRecipientsAddedComponent, [i6.NgbActiveModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NoRecipientsAddedComponentNgFactory = i1.ɵccf("app-no-recipients-added", i5.NoRecipientsAddedComponent, View_NoRecipientsAddedComponent_Host_0, {}, {}, []);
export { NoRecipientsAddedComponentNgFactory as NoRecipientsAddedComponentNgFactory };
