/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./alarms-already-sent.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i3 from "@fortawesome/angular-fontawesome";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@angular/common";
import * as i6 from "ngx-moment";
import * as i7 from "../../components-general/table-interactive-top/table-interactive-top.component.ngfactory";
import * as i8 from "../../components-general/table-interactive-top/table-interactive-top.component";
import * as i9 from "../../../services/settings.service";
import * as i10 from "../../components-general/table-interactive-bottom/table-interactive-bottom.component.ngfactory";
import * as i11 from "../../components-general/table-interactive-bottom/table-interactive-bottom.component";
import * as i12 from "./alarms-already-sent.component";
import * as i13 from "@angular/router";
var styles_AlarmsAlreadySentComponent = [i0.styles];
var RenderType_AlarmsAlreadySentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AlarmsAlreadySentComponent, data: {} });
export { RenderType_AlarmsAlreadySentComponent as RenderType_AlarmsAlreadySentComponent };
function View_AlarmsAlreadySentComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "td", [["class", "d-table-cell align-middle"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵppd(3, 1), i1.ɵppd(4, 2), (_l()(), i1.ɵeld(5, 0, null, null, 1, "td", [["class", "d-table-cell align-middle"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "td", [["class", "d-table-cell align-middle"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "td", [["class", "d-table-cell align-middle"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "td", [["class", "d-table-cell align-middle"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", ""])), (_l()(), i1.ɵeld(13, 0, null, null, 5, "td", [["class", "d-table-cell align-middle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 4, "div", [["class", "float-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 3, "button", [["class", "btn btn-light btn-sm"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onShowAlertDetails(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 2, "fa-icon", [["class", "ng-fa-icon"]], [[8, "innerHTML", 1]], null, null, i2.View_FaIconComponent_0, i2.RenderType_FaIconComponent)), i1.ɵdid(17, 573440, null, 0, i3.FaIconComponent, [i4.DomSanitizer, i3.FaIconService], { iconProp: [0, "iconProp"] }, null), i1.ɵpad(18, 2)], function (_ck, _v) { var currVal_6 = _ck(_v, 18, 0, "fas", "external-link-alt"); _ck(_v, 17, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent.parent, 0), i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent, 1), _v.context.$implicit.occuredDate)), "YYYY-MM-DD HH:mm:ss")); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.alarmName; _ck(_v, 6, 0, currVal_1); var currVal_2 = _v.context.$implicit.limitName; _ck(_v, 8, 0, currVal_2); var currVal_3 = _v.context.$implicit.recipientName; _ck(_v, 10, 0, currVal_3); var currVal_4 = _v.context.$implicit.notifiedBy; _ck(_v, 12, 0, currVal_4); var currVal_5 = i1.ɵnov(_v, 17).renderedIconHTML; _ck(_v, 16, 0, currVal_5); }); }
function View_AlarmsAlreadySentComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "table", [["class", "table"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "thead", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 11, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "th", [["class", "d-table-cell"], ["scope", "col"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Time"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "th", [["class", "d-table-cell"], ["scope", "col"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Alarm"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "th", [["class", "d-table-cell"], ["scope", "col"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Limit"])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "th", [["class", "d-table-cell"], ["scope", "col"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Recipient"])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "th", [["class", "d-table-cell"], ["scope", "col"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Notified by"])), (_l()(), i1.ɵeld(13, 0, null, null, 0, "th", [["class", "d-table-cell"], ["scope", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlarmsAlreadySentComponent_2)), i1.ɵdid(16, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.shownEntries; _ck(_v, 16, 0, currVal_0); }, null); }
function View_AlarmsAlreadySentComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "content-padding"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["No alarms sent."]))], null, null); }
export function View_AlarmsAlreadySentComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i6.DateFormatPipe, []), i1.ɵpid(0, i6.LocalTimePipe, []), (_l()(), i1.ɵeld(2, 0, null, null, 13, "div", [["class", "content-main"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "content-heading"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Alarms history "])), (_l()(), i1.ɵeld(5, 0, null, null, 10, "div", [["class", "content-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "content-padding"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-table-interactive-top", [], null, [[null, "pageChanged"], [null, "searchTextChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("pageChanged" === en)) {
        var pd_0 = (_co.onPageChanged($event) !== false);
        ad = (pd_0 && ad);
    } if (("searchTextChanged" === en)) {
        var pd_1 = (_co.onChangeSearchText($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i7.View_TableInteractiveTopComponent_0, i7.RenderType_TableInteractiveTopComponent)), i1.ɵdid(8, 245760, null, 0, i8.TableInteractiveTopComponent, [i9.SettingsService], { page: [0, "page"], collectionSize: [1, "collectionSize"] }, { pageChanged: "pageChanged", searchTextChanged: "searchTextChanged" }), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "table-responsive"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlarmsAlreadySentComponent_1)), i1.ɵdid(11, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["class", "content-padding"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "app-table-interactive-bottom", [], null, [[null, "pageChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("pageChanged" === en)) {
        var pd_0 = (_co.onPageChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_TableInteractiveBottomComponent_0, i10.RenderType_TableInteractiveBottomComponent)), i1.ɵdid(14, 770048, null, 0, i11.TableInteractiveBottomComponent, [i9.SettingsService], { page: [0, "page"], collectionSize: [1, "collectionSize"] }, { pageChanged: "pageChanged" }), (_l()(), i1.ɵand(0, [["noSubscriptionsCreated", 2]], null, 0, null, View_AlarmsAlreadySentComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentPage; var currVal_1 = _co.numberOfAlarms; _ck(_v, 8, 0, currVal_0, currVal_1); var currVal_2 = (_co.shownEntries.length > 0); var currVal_3 = i1.ɵnov(_v, 15); _ck(_v, 11, 0, currVal_2, currVal_3); var currVal_4 = _co.currentPage; var currVal_5 = _co.numberOfAlarms; _ck(_v, 14, 0, currVal_4, currVal_5); }, null); }
export function View_AlarmsAlreadySentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-alarms-already-sent", [], null, null, null, View_AlarmsAlreadySentComponent_0, RenderType_AlarmsAlreadySentComponent)), i1.ɵdid(1, 245760, null, 0, i12.AlarmsAlreadySentComponent, [i13.ActivatedRoute, i13.Router, i9.SettingsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlarmsAlreadySentComponentNgFactory = i1.ɵccf("app-alarms-already-sent", i12.AlarmsAlreadySentComponent, View_AlarmsAlreadySentComponent_Host_0, {}, {}, []);
export { AlarmsAlreadySentComponentNgFactory as AlarmsAlreadySentComponentNgFactory };
