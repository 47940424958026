<div class="btn-group" role="group" aria-label="Steps to add new alarm.">
  <button [routerLink]="['/main/add_alarm']" type="button" class="btn"
      [ngClass]="{'btn-light': !isStepActive(Step.NewAlarm), 'btn-secondary': isStepActive(Step.NewAlarm)}"
      [disabled]="newAlarmDisabled" i18n>1. Alarm</button>
  <button [routerLink]="['/main/add_alarm/limits']" type="button" class="btn"
      [ngClass]="{'btn-light': !isStepActive(Step.Limits), 'btn-secondary': isStepActive(Step.Limits)}"
      [disabled]="limitsDisabled" i18n>2. Limits</button>
  <button [routerLink]="['/main/add_alarm/recipients']" type="button" class="btn"
      [ngClass]="{'btn-light': !isStepActive(Step.Recipients), 'btn-secondary': isStepActive(Step.Recipients)}"
      [disabled]="recipientsDisabled" i18n>3. Recipients</button>
</div>