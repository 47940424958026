import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-add-alarm-added-entries-heading',
  templateUrl: './add-alarm-added-entries-heading.component.html',
  styleUrls: ['./add-alarm-added-entries-heading.component.scss']
})
export class AddAlarmAddedEntriesHeadingComponent implements OnInit {

  @Input() headlineText = '';

  constructor() { }

  ngOnInit() {
  }

}
