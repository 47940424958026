import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-success-sent-test-alarm',
  templateUrl: './success-sent-test-alarm.component.html',
  styleUrls: ['./success-sent-test-alarm.component.scss']
})
export class SuccessSentTestAlarmComponent implements OnInit {

  constructor(public modal: NgbActiveModal) { }

  static openDefaultModal(modalService: NgbModal) {
    const modal = modalService.open(SuccessSentTestAlarmComponent,
      { centered: true, size: 'lg', backdrop: 'static', keyboard: false});
    return modal;
  }

  ngOnInit() {
  }

}
