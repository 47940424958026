/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./logged-out.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./logged-out.component";
import * as i3 from "@angular/router";
var styles_LoggedOutComponent = [i0.styles];
var RenderType_LoggedOutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoggedOutComponent, data: {} });
export { RenderType_LoggedOutComponent as RenderType_LoggedOutComponent };
export function View_LoggedOutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" logged-out works!\n"]))], null, null); }
export function View_LoggedOutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-logged-out", [], null, null, null, View_LoggedOutComponent_0, RenderType_LoggedOutComponent)), i1.ɵdid(1, 114688, null, 0, i2.LoggedOutComponent, [i3.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoggedOutComponentNgFactory = i1.ɵccf("app-logged-out", i2.LoggedOutComponent, View_LoggedOutComponent_Host_0, {}, {}, []);
export { LoggedOutComponentNgFactory as LoggedOutComponentNgFactory };
