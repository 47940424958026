import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-add-alarm-steps-btn-group',
  templateUrl: './add-alarm-steps-btn-group.component.html',
  styleUrls: ['./add-alarm-steps-btn-group.component.scss']
})
export class AddAlarmStepsBtnGroupComponent implements OnInit {

  @Input() activeStep: AddAlarmStepsBtnGroupComponent.Step;
  @Input() newAlarmDisabled = false;
  @Input() limitsDisabled = false;
  @Input() recipientsDisabled = false;

  Step = AddAlarmStepsBtnGroupComponent.Step;

  constructor() { }

  ngOnInit() {}

  isStepActive(step: AddAlarmStepsBtnGroupComponent.Step) {
    const isActive = step === this.activeStep;
    return isActive;
  }

}

export namespace AddAlarmStepsBtnGroupComponent {
  export enum Step {
    NewAlarm, Limits, Recipients
  }
}
