import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { SubscriptionsService } from 'services/subscriptions.service';
import { SubscriptionMetaInfo } from 'services/subscriptions.model';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
  })
export class SubscriptionsResolve implements Resolve<SubscriptionMetaInfo[]> {
    constructor(private subscriptions: SubscriptionsService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        console.log('SubscriptionsResolve');
        return this.subscriptions.allSubscriptions().pipe(
            tap(msg => {
                // console.log('SubscriptionsResolve msg', msg);
            })
        );
    }
}
