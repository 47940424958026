<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title" i18n>No recipients created</h4>
</div>
<div class="modal-body">
  <p class="icon">
    <fa-icon [icon]="['fas', 'info-circle']" size="4x"></fa-icon>
  </p>
  <p i18n>
    You have not created recipients that can be notified in case of an alarm.<br>
    Create recipients first or add them later to an alarm.
  </p>
</div>
<div class="modal-footer confirm">
  <button type="button" class="btn btn-primary btn-phoenix" (click)="modal.close('Ok click')" i18n>Create recipient</button>
  <button type="button" class="btn btn-primary btn-phoenix" (click)="modal.dismiss('Cancel click')">Add new alarm</button>
</div>