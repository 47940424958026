<ng-container [ngSwitch]="type">
  <ng-container *ngSwitchCase="Type.LimitReasonRecpientsAddedd">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title" i18n>Unable to delete limit.</h4>
    </div>
    <div class="modal-body">
      <p class="icon">
        <fa-icon [icon]="['fas', 'exclamation-triangle']" size="4x"></fa-icon>
      </p>
      <p i18n>
        Recipents are already subscribed to limit.
      </p>
      <p>
        <strong i18n>First, you have to unsubscribe all recipients from that limit.</strong>
      </p>
    </div>
    <div class="modal-footer one">
      <button type="button" class="btn btn-primary btn-phoenix" (click)="modal.close('Ok click')" i18n>Okay</button>
    </div>
  </ng-container>
</ng-container>