<div class="content-main">
  <div class="content-heading" i18n="Content headline for alarms">
    Alarms
  </div>
  <div class="content-body">
    <div class="content-padding">
      <app-table-interactive-top [page]="currentPage" [collectionSize]="numberOfAlarms" (pageChanged)="onPageChanged($event)" (searchTextChanged)="onChangeSearchText($event)"></app-table-interactive-top>
    </div>
    <table class="table" *ngIf="allAlarms.length > 0; else noAlarmsCreated">
      <thead>
        <tr>
          <th scope="col" class="d-table-cell" i18n>Name alarm</th>
          <th scope="col" class="d-table-cell" i18n>Number of metrics</th>
          <th scope="col" class="d-table-cell"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let alarm of shownAlarmEntries">
          <td class="d-table-cell align-middle">{{ alarm.name }}</td>
          <td class="d-table-cell align-middle"><span i18n>Used metrics</span> {{ alarm.numberOfLimits }}</td>
          <td class="d-table-cell align-middle">
            <div class="float-right">
              <a [routerLink]="['/main','alarm', alarm.identifier]" class="btn btn-light btn-sm">
                <fa-icon [icon]="['fas', 'edit']"></fa-icon>
              </a>
              <button type="button" class="btn btn-light btn-sm" (click)="onRemoveAlarm(alarm)" [disabled]="removeAlarmInProgress">
                <fa-icon [icon]="['fas', 'trash-alt']"></fa-icon>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="content-padding">
      <app-table-interactive-bottom [page]="currentPage" [collectionSize]="numberOfAlarms" (pageChanged)="onPageChanged($event)"></app-table-interactive-bottom>
    </div>
    <ng-template #noAlarmsCreated>
      <div class="content-padding" i18n>No alarms created.</div>
    </ng-template>
  </div>
</div>
<!-- <a [routerLink]="['/main/add_alarm']" class="btn btn-primary float-right btn-phoenix" type="button" i18n>Add new alarm</a> -->
<button class="btn btn-primary btn-phoenix float-right" (click)="onAddNewAlarm()" i18n>Add new alarm</button>