import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { RecipientsService } from 'services/recipients.service';
import { SubscriptionMetaInfo } from 'services/subscriptions.model';
import { SettingsService, AlarmingMenuEntry } from 'services/settings.service';
import { WarningRemoveComponent } from '@components-modal/warning-remove/warning-remove.component';
import { SuccessUpsertRemoveComponent } from '@components-modal/success-upsert-remove/success-upsert-remove.component';
import { FailedUpsertRemoveComponent } from '@components-modal/failed-upsert-remove/failed-upsert-remove.component';

export interface RecipentsTableEntry {
  identifier: string;
  name: string;
  mail: string;
  mobile: string | undefined;
  mailConfirmed: boolean;
  smsConfirmed: boolean;
  alarms: string;
  limits: string;
}

@Component({
  selector: 'app-recipients-already-created',
  templateUrl: './recipients-already-created.component.html',
  styleUrls: ['./recipients-already-created.component.scss']
})
export class RecipientsAlreadyCreatedComponent implements OnInit, OnDestroy {

  shownEntries: RecipentsTableEntry[] = [];
  numberOfRecipients = 0;
  currentPage = 0;

  searchText: string;

  allEntries: RecipentsTableEntry[] = [];

  Mode = RecipientsAlreadyCreatedComponent.Mode;
  mode = RecipientsAlreadyCreatedComponent.Mode.default;

  removeRecipientInProgress = false;

  private dataSubscription: Subscription;
  private routeSubscription: Subscription;
  private settingsSubscription: Subscription;

  constructor(private route: ActivatedRoute, private router: Router, private modalService: NgbModal,
    private recipients: RecipientsService, private settings: SettingsService) {
    this.settings.currentAlarmEntry$.next(AlarmingMenuEntry.RecipientsCreated);
  }

  ngOnInit() {
    this.dataSubscription = this.route.data.subscribe(data => {

      this.allEntries = data.subscriptions.map((subscription: SubscriptionMetaInfo) => {
        const entry: RecipentsTableEntry = {
          identifier: subscription.recipientIdentifier,
          name: subscription.recipientName,
          mail: subscription.mail,
          mailConfirmed: subscription.mailConfirmed,
          mobile: subscription.mobile,
          smsConfirmed: subscription.smsConfirmed,
          alarms: `${subscription.numberAlarms}`,
          limits: `${subscription.numberLimits}`
        };
        return entry;
      })
      .sort(this.sort_recipients);

      this.routeSubscription = this.route.url.subscribe(url => {
        const unconfirmedRoute = url.find(u => {
          return u.path === 'recipients_unconfirmed';
        });
        if (unconfirmedRoute != null) {
          this.mode = RecipientsAlreadyCreatedComponent.Mode.recipientsUnconfirmed;
        } else {
          this.mode = RecipientsAlreadyCreatedComponent.Mode.default;
        }
      });

      this.updateShownEntries();
    });

    this.settingsSubscription = this.settings.showEntriesPerPage$.subscribe(entriesPerPage => {
      this.updateShownEntries(true);
    });
  }

  ngOnDestroy() {
    if (this.dataSubscription != null) {
      this.dataSubscription.unsubscribe();
    }
    if (this.settingsSubscription != null) {
      this.settingsSubscription.unsubscribe();
    }
    if (this.routeSubscription != null) {
      this.routeSubscription.unsubscribe();
    }
  }

  onShowRecipientDetails(event: RecipentsTableEntry) {
    this.router.navigate(['/main/recipients_created', event.identifier]);
    // this.router.navigate([event.identifier], { relativeTo: this.route });
  }

  onRemoveRecipient(event: RecipentsTableEntry) {
    const modalRef = this.modalService
      .open(WarningRemoveComponent, { centered: true, size: 'lg', backdrop: 'static', keyboard: false });
    if (modalRef.componentInstance instanceof WarningRemoveComponent) {
      modalRef.componentInstance.name = event.name;
      modalRef.componentInstance.type = WarningRemoveComponent.Type.Recipient;
    }
    modalRef.result.then(
      result => {
        this.removeRecipientInProgress = true;
        this.recipients.deleteRecipientWithIdentifier(event.identifier).subscribe(
          msg => {
            SuccessUpsertRemoveComponent.openDefaultModal(SuccessUpsertRemoveComponent.Type.RecipientRemoved, this.modalService);
            this.removeEntryAfterRecipientIsDeleted(event.identifier);
          },
          err => {
            FailedUpsertRemoveComponent.openDefaultModal(err, this.modalService);
            console.error(err);
            this.removeRecipientInProgress = false;
          },
          () => {
            this.removeRecipientInProgress = false;
          }
        );
      },
      reason => { }
    );
  }

  onPageChanged(page: number) {
    console.log('onPageChanged', page);
    this.currentPage = page;
    this.updateShownEntries();
  }

  onChangeSearchText(searchText: string) {
    this.searchText = searchText;
    this.updateShownEntries(true);
  }

  onNewRecipientsClicked() {
    this.router.navigate(['/main/add_recipient']);
  }

  private updateShownEntries(resetPage: boolean = false) {
    const totalEntries = this.allEntries.filter(entry => {
      return this.filterByText(entry, this.searchText);
    });
    this.numberOfRecipients = totalEntries.length;
    const entriesPerPage = this.settings.showEntriesPerPage$.value;
    let start: number;
    if (resetPage) {
      this.currentPage = 1;
    }
    start = this.currentPage * entriesPerPage - entriesPerPage;
    start = start < totalEntries.length ? start : 0;
    const end = start + entriesPerPage;
    const clippedEntries = totalEntries.slice(start, end);
    this.shownEntries = clippedEntries;
  }

  private filterByText(recipient: RecipentsTableEntry, text: String) {
    if (text == null || text.length === 0) {
      return true;
    }
    const t = text.toLocaleLowerCase();
    const name = recipient.name.toLocaleLowerCase();
    if (name.includes(t)) {
      return true;
    }
    const mail = recipient.mail != null ? recipient.mail.toLocaleLowerCase() : null;
    if (mail != null && mail.includes(t)) {
      return true;
    }
    const mobile = recipient.mobile != null ? recipient.mobile.toLocaleLowerCase() : null;
    if (mobile != null && mobile.includes(t)) {
      return true;
    }
    return false;
  }

  private removeEntryAfterRecipientIsDeleted(recipientIdentifier: string) {
    const index = this.allEntries.findIndex(r => {
      return r.identifier === recipientIdentifier;
    });
    if (index > -1) {
      this.allEntries.splice(index, 1);
      this.updateShownEntries();
    }
  }

  private sort_recipients(e1: RecipentsTableEntry, e2: RecipentsTableEntry) {
    const e1MailConfirmed = e1.mail == null || e1.mail.length === 0 || e1.mailConfirmed ? true : false;
    const e1SmsConfirmed = e1.mobile == null || e1.mobile.length === 0 || e1.smsConfirmed ? true : false;

    const e2MailConfirmed = e2.mail == null || e2.mail.length === 0 || e2.mailConfirmed ? true : false;
    const e2SmsConfirmed = e2.mobile == null || e2.mobile.length === 0 || e2.smsConfirmed ? true : false;

    // neither mail nor sms confirmed
    const e1NeitherMailNorSMSConfirmed = e1MailConfirmed === false && e1SmsConfirmed === false;
    const e2NeitherMailNorSMSConfirmed = e2MailConfirmed === false && e2SmsConfirmed === false;
    if (e1NeitherMailNorSMSConfirmed === true && e2NeitherMailNorSMSConfirmed === false) {
      return -1;
    } else if (e1NeitherMailNorSMSConfirmed === false && e2NeitherMailNorSMSConfirmed === true) {
      return 1;
    }

    // mail not confirmed, sms confirmed
    if (e1MailConfirmed === false && e2MailConfirmed === true) {
      return -1;
    } else if (e1MailConfirmed === true && e2MailConfirmed === false) {
      return 1;
    }

    // sms not confirmed, mail confirmed
    if (e1SmsConfirmed === false && e2SmsConfirmed === true) {
      return -1;
    } else if (e1SmsConfirmed === true && e2SmsConfirmed === false) {
      return 1;
    }

    // sort by name
    const name1 = e1.name.toLocaleLowerCase();
    const name2 = e2.name.toLocaleLowerCase();
    if (name1 > name2) {
      return 1;
    }
    if (name1 < name2) {
      return -1;
    }
    return 0;
  }

}

export namespace RecipientsAlreadyCreatedComponent {
  // Mode.recipientsUnconfirmed currently not in use because of CR Phoenix
  export enum Mode {
    default, recipientsUnconfirmed
  }
}
