import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Alarm } from 'services/alarms.model';
import { RecipientsService } from '@services/recipients.service';

export interface RecipientsTableEntry {
  recipientName: string;
  wayToNotice: string;
  subscribedTo: string;
  recipientId: string;
}

export namespace RecipientsTableEntry {

  export function convertFromAlarmRecipients(
      alarmRecipients: Alarm.Recipient[],
      alarm: Alarm,
      recipientsService: RecipientsService
  ) {
    const entries = alarmRecipients.map(r => {
      return convertFromAlarmRecipient(r, alarm, recipientsService);
    });
    return entries;
  }

  export function convertFromAlarmRecipient(
      alarmRecipient: Alarm.Recipient,
      alarm: Alarm,
      recipientsService: RecipientsService) {
    const origRecipient = recipientsService.recipientByIdentifier(alarmRecipient.identifier);
        const recipientName = origRecipient != null && origRecipient.name != null ? origRecipient.name : alarmRecipient.identifier;
        const wayToNotice = Alarm.Recipient.descriptionWayToNotice(alarmRecipient);
        const subscribedTo = Alarm.Recipient.descriptionSubscribedToLimits(alarmRecipient, alarm);
        const recipientId = alarmRecipient.identifier;
        return {
          recipientName,
          wayToNotice,
          subscribedTo,
          recipientId
        };
  }
}

@Component({
  selector: 'app-recipients-table',
  templateUrl: './recipients-table.component.html',
  styleUrls: ['./recipients-table.component.scss']
})
export class RecipientsTableComponent implements OnInit {

  @Input() alarm: Alarm;
  @Input() showEdit = true;
  @Input() recipients$: BehaviorSubject<RecipientsTableEntry[]>;
  @Input() removeRecipientsDisabled = false;

  @Output() editRecipient = new EventEmitter<RecipientsTableEntry>();
  @Output() removeRecipient = new EventEmitter<RecipientsTableEntry>();

  entriesPerPage: number;
  recipientEntries: RecipientsTableEntry[] = [];

  constructor() { }

  ngOnInit() {
  }

  onRemoveRecipient(recipient: RecipientsTableEntry) {
    this.removeRecipient.emit(recipient);
  }

  onEditRecipient(recipient: RecipientsTableEntry) {
    this.editRecipient.emit(recipient);
  }

}
