<form #newRecipientsForm="ngForm" (ngSubmit)="onSubmit(newRecipientsForm)" class="clearfix">
    <div class="content-main">
        <app-add-alarm-heading [lastPathName]="'New alarm'"></app-add-alarm-heading>
        <div class="content-body">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <div class="float-md-left content-padding subtitle">
                            <h5>{{ alarm.name }}</h5>
                        </div>
                        <div class="float-md-right content-padding">
                            <app-add-alarm-steps-btn-group [activeStep]="Step.Recipients"></app-add-alarm-steps-btn-group>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <h3 class="content-title">Recipients</h3>
                        <div class="content-padding">
                                <app-upsert-alarm-recipient-form-container (recipientChanged)="onRecipientChanged($event)"
                                [alarm]="alarm" [recipient]="recipient" #upsertAlarmRecipientFormContainer>
                              </app-upsert-alarm-recipient-form-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="btn-toolbar float-right buttons-outside-content" role="toolbar" aria-label="Toolbar with button groups">
        <div class="btn-group mr-2" role="group" aria-label="First group">
            <button class="btn btn-primary btn-phoenix float-right" type="submit" [disabled]="!newRecipientsForm.form.valid"
                i18n>Add recipient</button>
        </div>
        <div class="btn-group" role="group" aria-label="Second group">
            <button class="btn btn-primary btn-phoenix float-right" type="button" i18n (click)="onSave()" [disabled]="saveInProgress">Save alarm</button>
        </div>
    </div>
</form>

<div class="content-main" *ngIf="addedRecipients$.value.length > 0">
    <app-add-alarm-added-entries-heading [headlineText]="'Recipients'"></app-add-alarm-added-entries-heading>
    <div class="content-body">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <app-recipients-table [alarm]="alarm" [recipients$]="addedRecipients$" [showEdit]="false" (removeRecipient)="onRemoveRecipient($event)"></app-recipients-table>
                </div>
            </div>
        </div>
    </div>
</div>