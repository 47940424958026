import { Component, OnInit, OnChanges, OnDestroy, Input, Output, EventEmitter, SimpleChanges, HostListener } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';

import { Alarm } from 'services/alarms.model';
import { MetricsService, Metric } from 'services/metrics.service';
import { SelectOptionsComponent } from '@components-general/select-options/select-options.component';


@Component({
  selector: 'app-upsert-alarm-limit-form-container',
  templateUrl: './upsert-alarm-limit-form-container.component.html',
  styleUrls: ['./upsert-alarm-limit-form-container.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer, useExisting: NgForm
    }
  ]
})
export class UpsertAlarmLimitFormContainerComponent implements OnInit, OnChanges, OnDestroy {

  @Input() alarm: Alarm;
  @Input() limit: Alarm.Limit; // limit that is currently edited
  @Input() inViewerMode: boolean;

  @Output() limitChanged = new EventEmitter<Alarm.Limit>();

  selectedMetricValue: number;
  metricsReloading = false;

  selectedMetricOptions: SelectOptionsComponent.Option[] = [];
  availableMetricOptions: SelectOptionsComponent.Option[] = [];
  selectedLimitRuleOperatorOptions: SelectOptionsComponent.Option[] = [];
  availableLimitRuleOperatorOptions: SelectOptionsComponent.Option[] = [];

  private metricsSubscription: Subscription;
  private metricsValuesSubscription: Subscription;

  private metricsValues: {[metricName: string]: number|null};

  @HostListener('paste', ['$event']) onPaste(e) {
    const value = e.clipboardData.getData('text/plain');
    this.limit.rule.value = value;
    e.preventDefault();
  }

  constructor(private metrics: MetricsService) { }

  ngOnInit() {
    this.metricsValuesSubscription = this.metrics.metricsValues$.subscribe(metricsValues => {
      this.metricsValues = metricsValues;
    });
    this.metricsSubscription = this.metrics.metrics$.subscribe(metrics => {
      this.availableMetricOptions = metrics.map(m => {
        return {
          name: m.name,
          identifier: m.identifier
        };
      });
    });
    this.availableLimitRuleOperatorOptions = Alarm.Limit.Rule.availableOperators.map(o => {
      const name = o.toString();
      return {
        name: name,
        identifier: name
      };
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.limit != null) {
      const limit = changes.limit.currentValue;

      const metricName = Metric.nameForMetric(limit.metricIdentifier, this.metrics);
      if (metricName == null) {
        this.selectedMetricOptions = [];
      } else {
        this.selectedMetricOptions = [{
          identifier: this.limit.metricIdentifier,
          name: Metric.nameForMetric(limit.metricIdentifier, this.metrics)
        }];
      }
      if (limit.rule != null && limit.rule.operator != null) {
        const name = limit.rule.operator.toString();
        this.selectedLimitRuleOperatorOptions = [{
          identifier: name,
          name: name
        }];
      }
      if (limit.metricIdentifier != null) {
        this.removeMetricFromPolling();
        this.metrics.addMetricNameToPollForValue(limit.metricIdentifier);
      }
    }
  }

  ngOnDestroy() {
    this.removeMetricFromPolling();
    this.metricsSubscription.unsubscribe();
    this.metricsValuesSubscription.unsubscribe();
  }

  onSelectedMetricsModelChanged(newOptions: SelectOptionsComponent.Option[]) {
    this.removeMetricFromPolling();
    if (newOptions != null && newOptions.length > 0) {
      const option = newOptions[0];
      this.limit.metricIdentifier = option.identifier;
      this.metrics.addMetricNameToPollForValue(this.limit.metricIdentifier);
    } else {
      this.limit.metricIdentifier = null;
      this.selectedMetricValue = null;
    }
    this.limitChanged.emit(this.limit);
  }

  onSelectedLimitRuleOperationChanged(newOptions: SelectOptionsComponent.Option[]) {
    if (newOptions != null && newOptions.length > 0) {
      const option = newOptions[0];
      this.limit.rule.operator = Alarm.Limit.Rule.operatorFromName(option.name);
    } else {
      this.limit.rule.operator = null;
    }
    this.limitChanged.emit(this.limit);
  }

  onReloadMetrics() {
    this.metricsReloading = true;
    this.metrics.refreshMetrics().subscribe(metrics => { },
      err => {
        console.error(err);
        this.metricsReloading = false;
      },
      () => {
        this.metricsReloading = false;
      }
    );
  }

  currentValueForMetric() {
    const value = this.metricsValues[this.limit.metricIdentifier];
    return value;
  }

  private removeMetricFromPolling() {
    if (this.limit.metricIdentifier != null) {
      this.metrics.removeMetricNameToPollForValue(this.limit.metricIdentifier);
    }
  }

}
