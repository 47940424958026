import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { AddAlarmHeading } from '@components-general/add-alarm-heading/add-alarm-heading.component';
import { Alert } from '@services/alerts.model';

@Component({
  selector: 'app-alarm-already-sent',
  templateUrl: './alarm-already-sent.component.html',
  styleUrls: ['./alarm-already-sent.component.scss']
})
export class AlarmAlreadySentComponent implements OnInit, OnDestroy {

  alert: Alert;
  HeadingMode = AddAlarmHeading.Mode;

  private dataSubscription: Subscription;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.dataSubscription = this.route.data.subscribe(data => {
      this.alert = data.alert;
    });
  }

  ngOnDestroy() {
    this.dataSubscription.unsubscribe();
  }

}
