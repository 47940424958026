<ng-container [ngSwitch]="type">
  <ng-container *ngSwitchCase="Type.Alarm">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title" i18n>Delete alarm</h4>
      <!-- <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button> -->
    </div>
    <div class="modal-body">
      <p class="icon">
        <fa-icon [icon]="['fas', 'exclamation-triangle']" size="4x"></fa-icon>
      </p>
      <p>
        <span i18n>If you delete this alarm, you can't undo this action.</span><br>
        <span i18n>The alarm '{{ name }}' will no longer be part of your alarming.</span>
      </p>
      <p i18n>
        Are you sure you want to delete the alarm?
      </p>
    </div>
    <div class="modal-footer confirm">
      <button type="button" class="btn btn-primary btn-phoenix" (click)="modal.dismiss('Cancel click')">Cancel</button>
      <button type="button" class="btn btn-danger" (click)="modal.close('Delete click')">Delete alarm</button>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="Type.LimitFromAlarm">
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title" i18n>Delete limit</h4>
      </div>
      <div class="modal-body">
        <p class="icon">
          <fa-icon [icon]="['fas', 'exclamation-triangle']" size="4x"></fa-icon>
        </p>
        <p>
          <span i18n>If you delete this limit, you can't undo this action.</span><br>
          <span i18n>The limit '{{ name }}' will no longer be part of your alarming.</span>
        </p>
        <p i18n>
          Are you sure you want to delete the limit?
        </p>
      </div>
      <div class="modal-footer confirm">
        <button type="button" class="btn btn-primary btn-phoenix" (click)="modal.dismiss('Cancel click')">Cancel</button>
        <button type="button" class="btn btn-danger" (click)="modal.close('Delete click')">Delete limit</button>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="Type.RecipientFromAlarm">
        <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title" i18n>Delete recipient</h4>
        </div>
        <div class="modal-body">
          <p class="icon">
            <fa-icon [icon]="['fas', 'exclamation-triangle']" size="4x"></fa-icon>
          </p>
          <p>
            <span i18n>If you delete this recipient, you can't undo this action.</span><br>
            <span i18n>The recipient '{{ name }}' will no longer be part of your alarming, the recipient will stay in your recipient listing.</span>
          </p>
          <p i18n>
            Are you sure you want to delete the recipient out of this alarm?
          </p>
        </div>
        <div class="modal-footer confirm">
          <button type="button" class="btn btn-primary btn-phoenix" (click)="modal.dismiss('Cancel click')">Cancel</button>
          <button type="button" class="btn btn-danger" (click)="modal.close('Delete click')">Delete recipient</button>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="Type.Recipient">
        <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title" i18n>Delete recipient</h4>
        </div>
        <div class="modal-body">
          <p class="icon">
            <fa-icon [icon]="['fas', 'exclamation-triangle']" size="4x"></fa-icon>
          </p>
          <p>
            <span i18n>If you delete this recipient, you can't undo this action.</span><br>
            <span i18n>The recipient '{{ name }}' will be removed from all alarms and will no longer receive any notifications.</span>
          </p>
          <p i18n>
            Are you sure you want to delete the recipient?
          </p>
        </div>
        <div class="modal-footer confirm">
          <button type="button" class="btn btn-primary btn-phoenix" (click)="modal.dismiss('Cancel click')">Cancel</button>
          <button type="button" class="btn btn-danger" (click)="modal.close('Delete click')">Delete recipient</button>
        </div>
      </ng-container>
</ng-container>