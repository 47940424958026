import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AddAlarmHeading } from '@components-general/add-alarm-heading/add-alarm-heading.component';
import { Recipient } from '@services/recipients.model';
import { RecipientsService } from '@services/recipients.service';
import { SettingsService, AlarmingMenuEntry } from '@services/settings.service';
import { SuccessSentTestAlarmComponent } from '@components-modal/success-sent-test-alarm/success-sent-test-alarm.component';
import { FailedSentTestAlarmComponent } from '@components-modal/failed-sent-test-alarm/failed-sent-test-alarm.component';
import { FailedUpdateConfirmationComponent } from '@components-modal/failed-update-confirmation/failed-update-confirmation.component';

@Component({
  selector: 'app-recipient-already-created',
  templateUrl: './recipient-already-created.component.html',
  styleUrls: ['./recipient-already-created.component.scss']
})
export class RecipientAlreadyCreatedComponent implements OnInit, OnDestroy {

  recipient: Recipient;

  subscriptions: Recipient.Subscription[] = [];

  HeadingMode = AddAlarmHeading.Mode;

  private dataSubscription: Subscription;

  constructor(
    private route: ActivatedRoute, private router: Router, private recipients: RecipientsService,
    private settings: SettingsService, private modalService: NgbModal) {
    this.settings.currentAlarmEntry$.next(AlarmingMenuEntry.RecipientsCreated);
  }

  ngOnInit() {
    this.dataSubscription = this.route.data.subscribe(
      data => {
        this.subscriptions = data.subscriptions;
        this.recipient = data.recipient;
      },
      err => {
        console.error(err);
      });
  }

  ngOnDestroy() {
    if (this.dataSubscription != null) {
      this.dataSubscription.unsubscribe();
    }
  }

  onConfirmationMail(checked: boolean) {
    this.sendConfirmationRequest(checked, Recipient.Confirmation.Type.mail);
  }

  onConfirmationSMS(checked: boolean) {
    this.sendConfirmationRequest(checked, Recipient.Confirmation.Type.sms);
  }

  onSendTestAlarm() {
    this.recipients.sendTestAlarmToRecipientWithIdentifier(this.recipient.identifier).subscribe(resp => {
      SuccessSentTestAlarmComponent.openDefaultModal(this.modalService);
    }, err => {
      console.error('sendTestAlarmToRecipientWithIdentifier failed', err);
      FailedSentTestAlarmComponent.openDefaultModal(err, this.modalService);
    });
  }

  onEditRecipient() {
    this.router.navigate(['/main/edit_recipient', this.recipient.identifier]);
  }

  isSendTestAlarmDisabled() {
    if (this.recipient == null && this.recipient.mobile == null) {
      return true;
    } else if (this.recipient.mail != null && this.recipient.mobile == null && this.recipient.mailConfirmed === true) {
      return true;
    } else if (this.recipient.mail == null && this.recipient.mobile != null && this.recipient.smsConfirmed === true) {
      return true;
    } else if (this.recipient.mailConfirmed === true && this.recipient.smsConfirmed === true) {
      return true;
    }
    return false;
  }

  isConfirmMailDisabled() {
    return this.recipient.mail == null || this.recipient.mail.length < 1;
  }

  isConfirmSMSDisabled() {
    return this.recipient.mobile == null || this.recipient.mobile.length < 1;
  }

  private sendConfirmationRequest(checked: boolean, type: Recipient.Confirmation.Type) {
    let requestErr: any| undefined;
    if (checked) {
      this.recipients.confirmWayToNotifiyRecipientWithIdentifier(
        this.recipient.identifier, type).subscribe(resp => {}, err => {
          requestErr = err;
          console.error('confirmWayToNotifiyRecipientWithIdentifier failed', err);
          switch (type) {
            case Recipient.Confirmation.Type.mail:
              this.recipient.mailConfirmed = false;
              break;
            case Recipient.Confirmation.Type.sms:
              this.recipient.smsConfirmed = false;
              break;
          }
          FailedUpdateConfirmationComponent.openDefaultModal(err, this.modalService);
      }
      );
    } else {
      this.recipients.unconfirmWayToNotifiyRecipientWithIdentifier(
        this.recipient.identifier, type
      ).subscribe(resp => {}, err => {
        console.error('unconfirmWayToNotifiyRecipientWithIdentifier failed', err);
        switch (type) {
          case Recipient.Confirmation.Type.mail:
            this.recipient.mailConfirmed = true;
            break;
          case Recipient.Confirmation.Type.sms:
            this.recipient.smsConfirmed = true;
            break;
        }
        FailedUpdateConfirmationComponent.openDefaultModal(err, this.modalService);
      });
    }
  }

}
