import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { UserService } from 'services/user.service';
import { User } from 'services/user.model';

@Injectable({
    providedIn: 'root'
  })
export class UserResolve implements Resolve<User> {
    constructor(private userService: UserService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        console.log('UserResolve');
        return this.userService.user();
    }
}
