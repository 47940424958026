import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AlarmsService } from 'services/alarms.service';
import { Alarm } from 'services/alarms.model';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
  })
export class AlarmResolve implements Resolve<Alarm> {
    constructor(private alarms: AlarmsService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        console.log('AlarmResolve');
        const currentAlarm = this.alarms.currentAlarm$ != null ? this.alarms.currentAlarm$.value : undefined;
        if (currentAlarm == null || currentAlarm.identifier !== route.params.id) {
            return this.alarms.alarmWithIdentifier(route.params.id).pipe(
                map(a => {
                    this.alarms.currentAlarm$.next(a);
                    return a;
                })
            );
        }
        return of(currentAlarm);
    }
}
