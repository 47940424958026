import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

import { AlarmConfigurationVisitedStep, SettingsService } from '@services/settings.service';


export enum Step {
  NewAlarm, Limits, Recipients
}

@Component({
  selector: 'app-add-alarm',
  templateUrl: './add-alarm.component.html',
  styleUrls: ['./add-alarm.component.scss']
})
export class AddAlarmComponent implements OnInit, OnDestroy {

  Step = Step;

  currentStep = Step.NewAlarm;

  private urlSubscription: Subscription;

  constructor(private route: ActivatedRoute, private router: Router, private settings: SettingsService) { }

  ngOnInit() {

    this.settings.helperAlarmConfigurationVisitedStep = AlarmConfigurationVisitedStep.Alarm;

    this.urlSubscription = this.route.url.subscribe(url => {
      const urlComps = this.router.url.split('/');
      // tslint:disable-next-line:max-line-length
      this.currentStep = urlComps != null && urlComps.length > 0 ? this.stepFromLastPathComponent(urlComps[urlComps.length - 1]) : Step.NewAlarm;
    });
  }

  ngOnDestroy() {
    this.urlSubscription.unsubscribe();
  }

  private stepFromLastPathComponent(lastPathComponent: string) {
    switch (lastPathComponent) {
      case 'limits':
        return Step.Limits;
      case 'recipients':
        return Step.Recipients;
      default:
        return Step.NewAlarm;
    }
  }

  isStepActive(step: Step) {
    const isActive = step === this.currentStep;
    return isActive;
  }

}
