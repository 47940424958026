import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';

import { AlertsMiddleware } from './alerts.middleware';
import { Alert } from './alerts.model';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {

  private alerts: Alert[] = [];

  constructor(private http: HttpClient) { }

  allAlerts() {
    return new AlertsMiddleware(this.http).allAlerts().pipe(
      tap(alerts => {
        this.alerts = alerts;
      })
    );
  }

  alert(identifier: string) {
    const alert = this.alerts.find(a => a.identifier === identifier);
    return alert;
  }
}
