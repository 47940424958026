<form #addAlarmForm="ngForm" (ngSubmit)="onSubmit(addAlarmForm)">
    <div class="content-main">
        <app-add-alarm-heading [lastPathName]="'New alarm'"></app-add-alarm-heading>
        <div class="content-body">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <div class="float-md-right content-padding">
                            <app-add-alarm-steps-btn-group [activeStep]="Step.NewAlarm"
                                [limitsDisabled]="addAlarmStepsBtnGroupLimitsDisable()"
                                [recipientsDisabled]="addAlarmStepsBtnGroupRecipientsDisable()">
                            </app-add-alarm-steps-btn-group>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <h3 class="content-title">New alarm</h3>
                        <div class="content-padding">
                            <div class="form-group row">
                                <div class="col-sm-12">
                                    <small class="form-text text-muted" i18n>Please note that fields marked with a star
                                        (<span class="star">*</span>) are required fields.</small>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="inputAlarmName" class="col-sm-2 col-form-label">Name alarm<span class="star">*</span></label>
                                <div class="col-sm-10">
                                    <input type="text" class="form-control" id="inputAlarmName" name="inputAlarmName"
                                        #inputAlarmName="ngModel" placeholder="Enter alarm name" required
                                        [(ngModel)]="alarm.name" [ngModelOptions]="{updateOn: 'change'}">
                                    <div *ngIf="(inputAlarmName.invalid && !inputAlarmName.pristine) && (inputAlarmName.dirty || inputAlarmName.touched)"
                                        class="alert alert-danger">
                                        <div *ngIf="inputAlarmName.errors.required" i18n>Alarm name is required.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <button class="btn btn-primary btn-phoenix float-right" type="submit" [disabled]="!addAlarmForm.form.valid"
        i18n>Continue to limits</button>
</form>