<div class="content-main">
  <app-add-alarm-heading [mode]="HeadingMode.AlarmsSent" [lastPathName]="alert.alarmName"></app-add-alarm-heading>
  <div class="content-body">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12 body-head">
          <div class="float-md-left content-padding subtitle">
            <h5>{{ alert.alarmName }}</h5>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h3 class="content-title" i18n>Alarm sent</h3>
          <div class="content-padding">
            <dl class="row">
              <dt class="col-sm-2" i18n>Time</dt>
              <dd class="col-sm-10">{{ alert.occurredAt | amLocal | amDateFormat:'YYYY-MM-DD HH:mm:ss'}}</dd>
              <dt class="col-sm-2" i18n>Limit</dt>
              <dd class="col-sm-10">{{ alert.limitName }}</dd>
              <dt class="col-sm-2" i18n>Metric</dt>
              <dd class="col-sm-10">{{ alert.metricName }}</dd>
              <dt class="col-sm-2" i18n>Violation</dt>
              <dd class="col-sm-10">{{ alert.limitValueViolation }} {{ alert.comparator}} {{ alert.limitValue }}</dd>
              <dt class="col-sm-2" i18n>Recipient</dt>
              <dd class="col-sm-10">{{ alert.recipientName }}</dd>
              <dt class="col-sm-2" i18n>Notified by</dt>
              <dd class="col-sm-10">{{ alert.recipientNotifiedBy }}</dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>