import { RecipientsService } from '@services/recipients.service';
import * as i0 from "@angular/core";
import * as i1 from "../../services/recipients.service";
var RecipientSubscriptionsResolve = /** @class */ (function () {
    function RecipientSubscriptionsResolve(recipients) {
        this.recipients = recipients;
    }
    RecipientSubscriptionsResolve.prototype.resolve = function (route, state) {
        console.log('RecipientSubscriptionsResolve');
        return this.recipients.subscriptionsWithRecipientIdentifier(route.params.id);
    };
    RecipientSubscriptionsResolve.ngInjectableDef = i0.defineInjectable({ factory: function RecipientSubscriptionsResolve_Factory() { return new RecipientSubscriptionsResolve(i0.inject(i1.RecipientsService)); }, token: RecipientSubscriptionsResolve, providedIn: "root" });
    return RecipientSubscriptionsResolve;
}());
export { RecipientSubscriptionsResolve };
