import { RecipientsService } from '@services/recipients.service';
import * as i0 from "@angular/core";
import * as i1 from "../../services/recipients.service";
var RecipientResolve = /** @class */ (function () {
    function RecipientResolve(recipients) {
        this.recipients = recipients;
    }
    RecipientResolve.prototype.resolve = function (route, state) {
        console.log('RecipientResolve');
        return this.recipients.getRecipientByIdentifier(route.params.id);
    };
    RecipientResolve.ngInjectableDef = i0.defineInjectable({ factory: function RecipientResolve_Factory() { return new RecipientResolve(i0.inject(i1.RecipientsService)); }, token: RecipientResolve, providedIn: "root" });
    return RecipientResolve;
}());
export { RecipientResolve };
