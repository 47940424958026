import { HttpClient } from '@angular/common/http';
import { SubscriptionsMiddleware } from 'services/subscriptions.middleware';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var SubscriptionsService = /** @class */ (function () {
    function SubscriptionsService(http) {
        this.http = http;
    }
    SubscriptionsService.prototype.allSubscriptions = function () {
        return new SubscriptionsMiddleware(this.http).allSubscriptions();
    };
    SubscriptionsService.ngInjectableDef = i0.defineInjectable({ factory: function SubscriptionsService_Factory() { return new SubscriptionsService(i0.inject(i1.HttpClient)); }, token: SubscriptionsService, providedIn: "root" });
    return SubscriptionsService;
}());
export { SubscriptionsService };
