import { map, catchError } from 'rxjs/operators';
import { AuthService } from 'services/auth.service';
import * as i0 from "@angular/core";
import * as i1 from "../../services/auth.service";
// see https://stackoverflow.com/questions/47210919/wait-for-http-inside-guard-on-angular-5/47211470
var AuthGuard = /** @class */ (function () {
    function AuthGuard(auth) {
        this.auth = auth;
    }
    AuthGuard.prototype.canActivate = function (next, state) {
        var _this = this;
        if (this.auth.isAuthenticated) {
            console.log('canActivate isAuthenticated true');
            return true;
        }
        // window.location.href = environment.ssoLoginUrl;
        // return of(false);
        return this.auth.verifyLoginState()
            .pipe(map(function (resp) {
            console.log('AuthGuard verifyLoginState success', resp);
            return true;
        }), catchError(function (error) {
            console.error('AuthGuard verifyLoginState failed', error);
            return _this.auth.initiateSSO();
        })).pipe(map(function (resp) {
            console.log('AuthGuard canActivate initiateSSO success', resp);
            if (typeof resp === typeof true) {
                return true;
            }
            window.location.href = resp;
            return false;
        }));
    };
    AuthGuard.ngInjectableDef = i0.defineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.inject(i1.AuthService)); }, token: AuthGuard, providedIn: "root" });
    return AuthGuard;
}());
export { AuthGuard };
