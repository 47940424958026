import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

import { AuthService } from 'services/auth.service';
import { SettingsService, AlarmingMenuEntry } from 'services/settings.service';
import { User } from 'services/user.model';
import { RecipientsService } from '@services/recipients.service';
import { environment } from 'environments/environment';

export enum MainComponentMenu {
  User, Application
}

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, OnDestroy {
  navbarCollapsed: boolean;
  userMenuCollapsed: boolean;

  MainComponentMenu = MainComponentMenu;
  AlarmingMenuEntry = AlarmingMenuEntry;

  activeAlarmingMenuEntry = AlarmingMenuEntry.Alarms;

  user: User;

  ExternalLink = MainComponent.ExternalLink;
  externalProficloudUrl = MainComponent.urlFromLink(MainComponent.ExternalLink.proficloud);
  externalTSDAnalyticsUrl = MainComponent.urlFromLink(MainComponent.ExternalLink.tsdAnalytics);

  private routeUrlSubscription: Subscription;
  private userSubscription: Subscription;

  constructor(private route: ActivatedRoute, private router: Router, private auth: AuthService,
    private settings: SettingsService, public recipients: RecipientsService) { }

  ngOnInit() {
    this.navbarCollapsed = true;
    this.userMenuCollapsed = true;

    this.settings.currentAlarmEntry$.subscribe(entry => {
      this.activeAlarmingMenuEntry = entry;
    });

    this.userSubscription = this.route.data.subscribe(data => {
      this.user = data.user;
    });
  }

  ngOnDestroy() {
    this.routeUrlSubscription.unsubscribe();
  }

  toggleCollapsedMenu(menu: MainComponentMenu) {

    switch (menu) {
      case MainComponentMenu.User:
        this.userMenuCollapsed = !this.userMenuCollapsed;
        this.navbarCollapsed = true;
        break;
      case MainComponentMenu.Application:
        this.navbarCollapsed = !this.navbarCollapsed;
        this.userMenuCollapsed = true;
        break;
    }

  }

  onLogout() {
    console.log('onLogout clicked');
    this.auth.logout().subscribe(resp => {
      console.log('onLogout subscribe', resp);
    }, err => {
      console.error('onLogout error', err);
    }, () => {
      console.log('onLogout completed');
      this.router.navigate(['loggedout']);
    });
  }

  openExternalLink(link: MainComponent.ExternalLink) {
    const url = MainComponent.urlFromLink(link);
    window.open(url, '_blank');
  }

}

export namespace MainComponent {
  export enum ExternalLink {
    proficloud, tsdAnalytics
  }

  export function urlFromLink(link: ExternalLink) {
    switch (link) {
      case ExternalLink.proficloud: return environment.externalLinks.proficloud;
      case ExternalLink.tsdAnalytics: return environment.externalLinks.tsdAnalytics;
    }
  }
}
