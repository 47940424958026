import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export namespace EntriesPerPage {
  export function entryFromText(text: string) {
    switch (text) {
      case `${EntriesPerPage.five}`: return EntriesPerPage.five;
      case `${EntriesPerPage.ten}`: return EntriesPerPage.ten;
      case `${EntriesPerPage.twentyfive}`: return EntriesPerPage.twentyfive;
      case `${EntriesPerPage.fifty}`: return EntriesPerPage.fifty;
    }
  }
}

export enum EntriesPerPage {
  five = 5, ten = 10, twentyfive = 25, fifty = 50
}

export enum AlarmingMenuEntry {
  Alarms = 'alarms', AlarmsSent = 'alarms_sent', RecipientsCreated = 'recipients_created'
}

export enum AlarmConfigurationVisitedStep {
  Alarm, Limits, Recipients
}

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  helperAlarmConfigurationVisitedStep = AlarmConfigurationVisitedStep.Alarm;

  showEntriesPerPage$ = new BehaviorSubject<number>(EntriesPerPage.ten);

  currentAlarmEntry$ = new BehaviorSubject<AlarmingMenuEntry>(AlarmingMenuEntry.Alarms);

  availableEntriesPerPage = [
    EntriesPerPage.five, EntriesPerPage.ten, EntriesPerPage.twentyfive, EntriesPerPage.fifty
  ];

  constructor() { }
}
