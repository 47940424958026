import { Component, OnInit, Input, OnChanges, SimpleChanges, forwardRef, ViewChild, ElementRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-dropdown-select-options',
  templateUrl: './dropdown-select-options.component.html',
  styleUrls: ['./dropdown-select-options.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownSelectOptionsComponent),
      multi: true
    }
  ]
})
export class DropdownSelectOptionsComponent implements OnInit, OnChanges, ControlValueAccessor {

  @ViewChild('dropdownOptionsList') dropdownOptionsList: NgbDropdown;
  @ViewChild('inputTextElem') inputTextElem: ElementRef;

  selectedOptions: DropdownSelectOptionsComponent.Option[] = [];
  @Input() options: DropdownSelectOptionsComponent.Option[] = [];
  @Input() allowMultiSelection = false;
  @Input() widthClass: 'none'|'small-width'|'full-width' = 'none';
  @Input() contentMode = DropdownSelectOptionsComponent.ContentMode.normal;

  filteredSelectableOptions: DropdownSelectOptionsComponent.Option[] = [];
  filteredUnableToNotifyRecipientsOptions: DropdownSelectOptionsComponent.Option[] = [];

  mode = DropdownSelectOptionsComponent.Mode.closed;

  ContentMode = DropdownSelectOptionsComponent.ContentMode;

  inputText: string;
  isDisabled = false;

  private selectedOptionsDescription: string;

  constructor() { }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    const optionsChanged = changes['options'];
    if (optionsChanged != null) {
      this.updateFilteredSelectableOptions();
    }
  }

  widthCSS() {
    switch (this.widthClass) {
      case 'none': return undefined;
      default: return this.widthClass;
    }
  }

  private updateFilteredSelectableOptions() {
    const filteredOptions = this.options.filter(o => {
      return this.filter_option_by_text(o, this.inputText);
    }).filter(o => {
      return this.filter_option_by_selected(o, this.selectedOptions);
    }).filter(o => {
      if (this.contentMode === DropdownSelectOptionsComponent.ContentMode.recipients) {
        return this.filter_recipient_option_unable_to_notify(o);
      } else {
        return true;
      }
    });
    this.filteredSelectableOptions = filteredOptions;

    if (this.contentMode === DropdownSelectOptionsComponent.ContentMode.recipients) {
      const unableToNotifyFilteredOptions = this.options.filter(o => {
        return this.filter_option_by_text(o, this.inputText);
      }).filter(o => {
        return this.filter_option_by_selected(o, this.selectedOptions);
      }).filter(o => {
        return this.filter_recipient_option_unable_to_notify(o, 'in');
      });
      this.filteredUnableToNotifyRecipientsOptions = unableToNotifyFilteredOptions;
    }
  }

  private updateSelectedOptionsDescription() {
    this.selectedOptionsDescription = this.selectedOptions.map(o => {
      return o.name;
    }).join(', ');
  }

  private updateShownInputText() {
    switch (this.mode) {
      case DropdownSelectOptionsComponent.Mode.opened:
        this.inputText = null;
        break;
      case DropdownSelectOptionsComponent.Mode.closed:
        this.inputText = this.selectedOptionsDescription;
        break;
    }
  }

  private filter_option_by_text(option: DropdownSelectOptionsComponent.Option, filterText: string) {
    if (filterText != null && filterText.length > 0) {
      const text = filterText.toLocaleLowerCase();
      const name = option.name.toLocaleLowerCase();
      return name.includes(text);
    }
    return true;
  }

  private filter_option_by_selected(option: DropdownSelectOptionsComponent.Option,
    selectedOptions: DropdownSelectOptionsComponent.Option[]) {
    const selectedOption = selectedOptions.find(o => {
      return o.identifier === option.identifier;
    });
    return selectedOption == null ? true : false;
  }

  private filter_recipient_option_unable_to_notify(option: DropdownSelectOptionsComponent.Option, filter: 'in'|'out' = 'out') {
    const ret = filter === 'out' ? false : true;
    if (option.recipient != null) {
      if (option.recipient.mobile != null && option.recipient.mobile.length > 0 && option.recipient.smsConfirmed) {
        return !ret;
      }
      if (option.recipient.mail != null && option.recipient.mail.length > 0 && option.recipient.mailConfirmed) {
        return !ret;
      }
    }
    return ret;
  }

  onOpenChanged(opened: boolean) {
    this.mode = opened ? DropdownSelectOptionsComponent.Mode.opened : DropdownSelectOptionsComponent.Mode.closed;
    this.updateShownInputText();
    this.updateFilteredSelectableOptions();
  }

  onInputTextChanged() {
    this.updateFilteredSelectableOptions();
  }

  onOptionClicked($event: any, option: DropdownSelectOptionsComponent.Option) {
    $event.preventDefault();

    // why setTimeout()
    // this.selectedOptions.splice(index, 1); <-- dropdown is automatically closed
    // solution: setTimeout() <-- prevents automatic close
    setTimeout(() => {
      if (this.allowMultiSelection === false) {
        this.selectedOptions = [option];
      } else {
        const index = this.selectedOptions.findIndex(o => {
          return o.identifier === option.identifier;
        });
        if (index > -1) {
          // option selected => remove
          this.selectedOptions.splice(index, 1);
        } else {
          // select option => add
          this.selectedOptions.push(option);
        }
      }
      this.propagateChange(this.selectedOptions);
      this.updateSelectedOptionsDescription();
      this.updateFilteredSelectableOptions();
      if (this.allowMultiSelection === false) {
        this.dropdownOptionsList.close();
      }
    }, 0);
  }

  onOptionRemoved($event: any, option: DropdownSelectOptionsComponent.Option) {
    if (this.allowMultiSelection) {
      this.onOptionClicked($event, option);
    } else {
      $event.preventDefault();
      this.selectedOptions = [];
      this.propagateChange(this.selectedOptions);
      this.updateSelectedOptionsDescription();
      this.updateFilteredSelectableOptions();
      this.dropdownOptionsList.close();
    }
  }

  onClickAngleDown(event: Event) {
    if (this.isDisabled) {
      event.stopImmediatePropagation();
      return;
    }
    this.inputTextElem.nativeElement.focus();
    this.inputTextElem.nativeElement.select();
  }

  onClickDropdown(event: Event) {
    if (this.isDisabled) {
      this.dropdownOptionsList.close();
      return;
    }
  }

  // ContentMode Recipients
  hasMail(option: DropdownSelectOptionsComponent.Option) {
    if (option.recipient != null && option.recipient.mail != null && option.recipient.mail.length > 0) {
      return true;
    }
    return false;
  }

  hasMobile(option: DropdownSelectOptionsComponent.Option) {
    if (option.recipient != null && option.recipient.mobile != null && option.recipient.mobile.length > 0) {
      return true;
    }
    return false;
  }

  recipientMailCSSClass(option: DropdownSelectOptionsComponent.Option) {
    return this.recipientCSSClass(option.recipient.mailConfirmed, option.recipient.mail);
  }

  recipientMobileCSSClass(option: DropdownSelectOptionsComponent.Option) {
    return this.recipientCSSClass(option.recipient.smsConfirmed, option.recipient.mobile);
  }

  private recipientCSSClass(confirmed: boolean, wayToContact?: string) {
    if (wayToContact != null && wayToContact.length > 0) {
      if (confirmed === false) {
        return 'unconfirmed';
      }
      return '';
    }
    return 'transparent';
  }

  // ControlValueAccessor Implementation

  propagateChange = (_: any) => {};

  writeValue(obj: DropdownSelectOptionsComponent.Option[] | null) {
    this.selectedOptions = obj != null ? obj : [];
    this.updateSelectedOptionsDescription();
    this.updateShownInputText();
    this.updateFilteredSelectableOptions();
  }

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any) {}

  setDisabledState?(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }
}

export namespace DropdownSelectOptionsComponent {
  export interface Option {
    identifier: string;
    name: string;
    recipient?: {
      mail?: string;
      mailConfirmed: boolean;
      mobile?: string;
      smsConfirmed: boolean;
    };
  }

  export enum Mode {
    opened, closed
  }

  export enum ContentMode {
    normal, recipients
  }
}
