import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '@guards/auth.guard';
import { ConfigureAlarmGuard } from '@guards/configure-alarm.guard';

import { AlarmsResolve } from '@resolvers/alarms.resolver';
import { AlarmResolve } from '@resolvers/alarm.resolver';
import { MetricsResolve } from '@resolvers/metrics.resolver';
import { RecipientsResolve } from '@resolvers/recipients.resolver';
import { CreateAlarmResolve } from '@resolvers/create-alarm.resolver';
import { CreateLimitResolve } from '@resolvers/create-limit.resolver';
import { LimitResolve } from '@resolvers/limit.resolver';
import { CreateRecipientResolve } from '@resolvers/create-recipient.resolver';
import { AlarmRecipientResolve } from '@resolvers/alarm-recipient.resolver';
import { SubscriptionsResolve } from '@resolvers/subscriptions.resolver';
import { AlertsResolve } from '@resolvers/alerts.resolver';
import { AlertResolve } from '@resolvers/alert.resolver';
import { UserResolve } from '@resolvers/user.resolver';
import { RecipientSubscriptionsResolve } from '@resolvers/recipient-subscriptions.resolver';
import { SubscriptionsRecipientsUnconfirmedResolve } from '@resolvers/subscriptions-recipients-unconfirmed.resolver';

import { MainComponent } from '@components-screen/main/main.component';
import { LoggedOutComponent } from '@components-screen/logged-out/logged-out.component';
import { AlarmsComponent } from '@components-screen/alarms/alarms.component';
import { SsoRedirectComponent } from '@components-screen/sso-redirect/sso-redirect.component';
import { AddAlarmComponent } from '@components-screen/add-alarm/add-alarm.component';
import { AddAlarmStep1Component } from '@components-screen/add-alarm-step1/add-alarm-step1.component';
import { AddAlarmStep2Component } from '@components-screen/add-alarm-step2/add-alarm-step2.component';
import { AddAlarmStep3Component } from '@components-screen/add-alarm-step3/add-alarm-step3.component';
import { AlarmComponent } from '@components-screen/alarm/alarm.component';
import { AlarmLimitsComponent } from '@components-screen/alarm-limits/alarm-limits.component';
import { AlarmRecipientsComponent } from '@components-screen/alarm-recipients/alarm-recipients.component';
import { AlarmUpsertRecipientComponent } from '@components-screen/alarm-upsert-recipient/alarm-upsert-recipient.component';
import { AlarmUpsertLimitComponent } from '@components-screen/alarm-upsert-limit/alarm-upsert-limit.component';
import { RecipientsAlreadyCreatedComponent } from '@components-screen/recipients-already-created/recipients-already-created.component';
import { AlarmsAlreadySentComponent } from '@components-screen/alarms-already-sent/alarms-already-sent.component';
import { RecipientAlreadyCreatedComponent } from '@components-screen/recipient-already-created/recipient-already-created.component';
import { AlarmAlreadySentComponent } from '@components-screen/alarm-already-sent/alarm-already-sent.component';
import { RecipientUpsertComponent } from '@components-screen/recipient-upsert/recipient-upsert.component';
import { RecipientResolve } from '@resolvers/recipient.resolver';

const routes: Routes = [
  {
    path: 'main',
    component: MainComponent,
    canActivate: [AuthGuard],
    resolve: {
      metrics: MetricsResolve,
      recipients: RecipientsResolve,
      alarms: AlarmsResolve,
      user: UserResolve
    },
    children: [
      {
        path: 'alarms',
        component: AlarmsComponent
      },
      {
        path: 'add_alarm',
        component: AddAlarmComponent,
        resolve: {
          alarm: CreateAlarmResolve
        },
        children: [
          {
            path: '',
            component: AddAlarmStep1Component
          },
          {
            path: 'limits',
            canActivate: [ConfigureAlarmGuard],
            component: AddAlarmStep2Component,
            pathMatch: 'full'
          },
          {
            path: 'recipients',
            canActivate: [ConfigureAlarmGuard],
            component: AddAlarmStep3Component,
            pathMatch: 'full'
          }
        ]
      },
      {
        path: 'alarm/:id',
        component: AlarmComponent,
        resolve: {
          alarm: AlarmResolve
        },
        children: [
          {
            path: 'limits',
            component: AlarmLimitsComponent,
            pathMatch: 'full'
          },
          {
            path: 'recipients',
            component: AlarmRecipientsComponent,
            pathMatch: 'full'
          },
          {
            path: 'add_recipient',
            component: AlarmUpsertRecipientComponent,
            pathMatch: 'full',
            resolve: {
              recipient: CreateRecipientResolve,
              recipients: RecipientsResolve
            }
          },
          {
            path: 'edit_recipient/:id',
            component: AlarmUpsertRecipientComponent,
            pathMatch: 'full',
            resolve: {
              recipient: AlarmRecipientResolve,
              recipients: RecipientsResolve
            }
          },
          {
            path: 'add_limit',
            component: AlarmUpsertLimitComponent,
            pathMatch: 'full',
            resolve: {
              limit: CreateLimitResolve
            }
          },
          {
            path: 'edit_limit/:id',
            component: AlarmUpsertLimitComponent,
            pathMatch: 'full',
            resolve: {
              limit: LimitResolve
            }
          },
          {
            path: '',
            redirectTo: 'limits',
            pathMatch: 'full'
          }
        ]
      },
      {
        path: '',
        redirectTo: '/alarms',
        pathMatch: 'full'
      },
      {
        path: 'alarms_sent',
        component: AlarmsAlreadySentComponent,
        resolve: {
          alerts: AlertsResolve
        }
      },
      {
        path: 'alarms_sent/:id',
        component: AlarmAlreadySentComponent,
        resolve: {
          alert: AlertResolve
        }
      },
      {
        path: 'recipients_created',
        component: RecipientsAlreadyCreatedComponent,
        resolve: {
          subscriptions: SubscriptionsResolve
        }
      },
      {
        path: 'recipients_created/:id',
        component: RecipientAlreadyCreatedComponent,
        resolve: {
          subscriptions: RecipientSubscriptionsResolve,
          recipient: RecipientResolve
        }
      },
      {
        path: 'add_recipient',
        component: RecipientUpsertComponent,
        pathMatch: 'full'
      },
      {
        path: 'edit_recipient/:id',
        component: RecipientUpsertComponent,
        pathMatch: 'full',
        resolve: {
          recipient: RecipientResolve
        }
      },
      {
        path: 'recipients_unconfirmed',
        component: RecipientsAlreadyCreatedComponent,
        resolve: {
          subscriptions: SubscriptionsRecipientsUnconfirmedResolve
        }
      },
      {
        path: '**',
        redirectTo: '/alarms'
      }
    ]
  },
  {
    path: 'loggedout',
    component: LoggedOutComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'sso_redirect',
    component: SsoRedirectComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '',
    redirectTo: '/main/alarms',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '/main/alarms',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
