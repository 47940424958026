/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./recipients-already-created.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i4 from "@fortawesome/angular-fontawesome";
import * as i5 from "@angular/platform-browser";
import * as i6 from "../../components-general/table-interactive-top/table-interactive-top.component.ngfactory";
import * as i7 from "../../components-general/table-interactive-top/table-interactive-top.component";
import * as i8 from "../../../services/settings.service";
import * as i9 from "../../components-general/table-interactive-bottom/table-interactive-bottom.component.ngfactory";
import * as i10 from "../../components-general/table-interactive-bottom/table-interactive-bottom.component";
import * as i11 from "./recipients-already-created.component";
import * as i12 from "@angular/router";
import * as i13 from "@ng-bootstrap/ng-bootstrap";
import * as i14 from "../../../services/recipients.service";
var styles_RecipientsAlreadyCreatedComponent = [i0.styles];
var RenderType_RecipientsAlreadyCreatedComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RecipientsAlreadyCreatedComponent, data: {} });
export { RenderType_RecipientsAlreadyCreatedComponent as RenderType_RecipientsAlreadyCreatedComponent };
function View_RecipientsAlreadyCreatedComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "content-heading"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Recipients "]))], null, null); }
function View_RecipientsAlreadyCreatedComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "content-heading"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Unconfirmed Recipients "]))], null, null); }
function View_RecipientsAlreadyCreatedComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 22, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [["class", "d-table-cell align-middle"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "td", [["class", "d-none d-md-table-cell align-middle"]], null, null, null, null, null)), i1.ɵdid(4, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(5, { "unconfirmed": 0 }), (_l()(), i1.ɵted(6, null, [" ", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 3, "td", [["class", "d-none d-md-table-cell align-middle"]], null, null, null, null, null)), i1.ɵdid(8, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(9, { "unconfirmed": 0 }), (_l()(), i1.ɵted(10, null, [" ", ""])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "td", [["class", "d-table-cell align-middle"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", " Alarms / ", " Limits"])), (_l()(), i1.ɵeld(13, 0, null, null, 9, "td", [["class", "d-table-cell align-middle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 8, "div", [["class", "float-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 3, "button", [["class", "btn btn-light btn-sm"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onShowRecipientDetails(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 2, "fa-icon", [["class", "ng-fa-icon"]], [[8, "innerHTML", 1]], null, null, i3.View_FaIconComponent_0, i3.RenderType_FaIconComponent)), i1.ɵdid(17, 573440, null, 0, i4.FaIconComponent, [i5.DomSanitizer, i4.FaIconService], { iconProp: [0, "iconProp"] }, null), i1.ɵpad(18, 2), (_l()(), i1.ɵeld(19, 0, null, null, 3, "button", [["class", "btn btn-light btn-sm"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onRemoveRecipient(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 2, "fa-icon", [["class", "ng-fa-icon"]], [[8, "innerHTML", 1]], null, null, i3.View_FaIconComponent_0, i3.RenderType_FaIconComponent)), i1.ɵdid(21, 573440, null, 0, i4.FaIconComponent, [i5.DomSanitizer, i4.FaIconService], { iconProp: [0, "iconProp"] }, null), i1.ɵpad(22, 2)], function (_ck, _v) { var currVal_1 = "d-none d-md-table-cell align-middle"; var currVal_2 = _ck(_v, 5, 0, !_v.context.$implicit.mailConfirmed); _ck(_v, 4, 0, currVal_1, currVal_2); var currVal_4 = "d-none d-md-table-cell align-middle"; var currVal_5 = _ck(_v, 9, 0, !_v.context.$implicit.smsConfirmed); _ck(_v, 8, 0, currVal_4, currVal_5); var currVal_10 = _ck(_v, 18, 0, "fas", "external-link-alt"); _ck(_v, 17, 0, currVal_10); var currVal_13 = _ck(_v, 22, 0, "fas", "trash-alt"); _ck(_v, 21, 0, currVal_13); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.name; _ck(_v, 2, 0, currVal_0); var currVal_3 = _v.context.$implicit.mail; _ck(_v, 6, 0, currVal_3); var currVal_6 = _v.context.$implicit.mobile; _ck(_v, 10, 0, currVal_6); var currVal_7 = _v.context.$implicit.alarms; var currVal_8 = _v.context.$implicit.limits; _ck(_v, 12, 0, currVal_7, currVal_8); var currVal_9 = i1.ɵnov(_v, 17).renderedIconHTML; _ck(_v, 16, 0, currVal_9); var currVal_11 = _co.removeRecipientInProgress; _ck(_v, 19, 0, currVal_11); var currVal_12 = i1.ɵnov(_v, 21).renderedIconHTML; _ck(_v, 20, 0, currVal_12); }); }
function View_RecipientsAlreadyCreatedComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "table", [["class", "table"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "thead", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 9, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "th", [["class", "d-table-cell"], ["scope", "col"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Name"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "th", [["class", "d-none d-md-table-cell"], ["scope", "col"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Mail"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "th", [["class", "d-none d-md-table-cell"], ["scope", "col"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["SMS"])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "th", [["class", "d-table-cell"], ["scope", "col"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Subscriptions"])), (_l()(), i1.ɵeld(11, 0, null, null, 0, "th", [["class", "d-table-cell"], ["scope", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RecipientsAlreadyCreatedComponent_4)), i1.ɵdid(14, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.shownEntries; _ck(_v, 14, 0, currVal_0); }, null); }
function View_RecipientsAlreadyCreatedComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "content-padding"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" No recipients created. "]))], null, null); }
function View_RecipientsAlreadyCreatedComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "content-padding"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" No unconfirmed recipients. "]))], null, null); }
function View_RecipientsAlreadyCreatedComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RecipientsAlreadyCreatedComponent_6)), i1.ɵdid(3, 278528, null, 0, i2.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RecipientsAlreadyCreatedComponent_7)), i1.ɵdid(5, 278528, null, 0, i2.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.mode; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.Mode.default; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.Mode.recipientsUnconfirmed; _ck(_v, 5, 0, currVal_2); }, null); }
function View_RecipientsAlreadyCreatedComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-primary btn-phoenix float-right"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onNewRecipientsClicked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Add new recipient"]))], null, null); }
export function View_RecipientsAlreadyCreatedComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "content-main"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, null, null, null, null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i2.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RecipientsAlreadyCreatedComponent_1)), i1.ɵdid(4, 278528, null, 0, i2.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RecipientsAlreadyCreatedComponent_2)), i1.ɵdid(6, 278528, null, 0, i2.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 9, "div", [["class", "content-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "content-padding"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "app-table-interactive-top", [], null, [[null, "pageChanged"], [null, "searchTextChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("pageChanged" === en)) {
        var pd_0 = (_co.onPageChanged($event) !== false);
        ad = (pd_0 && ad);
    } if (("searchTextChanged" === en)) {
        var pd_1 = (_co.onChangeSearchText($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_TableInteractiveTopComponent_0, i6.RenderType_TableInteractiveTopComponent)), i1.ɵdid(10, 245760, null, 0, i7.TableInteractiveTopComponent, [i8.SettingsService], { page: [0, "page"], collectionSize: [1, "collectionSize"] }, { pageChanged: "pageChanged", searchTextChanged: "searchTextChanged" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RecipientsAlreadyCreatedComponent_3)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 2, "div", [["class", "content-padding"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "app-table-interactive-bottom", [], null, [[null, "pageChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("pageChanged" === en)) {
        var pd_0 = (_co.onPageChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_TableInteractiveBottomComponent_0, i9.RenderType_TableInteractiveBottomComponent)), i1.ɵdid(15, 770048, null, 0, i10.TableInteractiveBottomComponent, [i8.SettingsService], { page: [0, "page"], collectionSize: [1, "collectionSize"] }, { pageChanged: "pageChanged" }), (_l()(), i1.ɵand(0, [["noSubscriptionsCreated", 2]], null, 0, null, View_RecipientsAlreadyCreatedComponent_5)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RecipientsAlreadyCreatedComponent_8)), i1.ɵdid(18, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.mode; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.Mode.default; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.Mode.recipientsUnconfirmed; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.currentPage; var currVal_4 = _co.numberOfRecipients; _ck(_v, 10, 0, currVal_3, currVal_4); var currVal_5 = (_co.allEntries.length > 0); var currVal_6 = i1.ɵnov(_v, 16); _ck(_v, 12, 0, currVal_5, currVal_6); var currVal_7 = _co.currentPage; var currVal_8 = _co.numberOfRecipients; _ck(_v, 15, 0, currVal_7, currVal_8); var currVal_9 = (_co.mode === _co.Mode.default); _ck(_v, 18, 0, currVal_9); }, null); }
export function View_RecipientsAlreadyCreatedComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-recipients-already-created", [], null, null, null, View_RecipientsAlreadyCreatedComponent_0, RenderType_RecipientsAlreadyCreatedComponent)), i1.ɵdid(1, 245760, null, 0, i11.RecipientsAlreadyCreatedComponent, [i12.ActivatedRoute, i12.Router, i13.NgbModal, i14.RecipientsService, i8.SettingsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RecipientsAlreadyCreatedComponentNgFactory = i1.ɵccf("app-recipients-already-created", i11.RecipientsAlreadyCreatedComponent, View_RecipientsAlreadyCreatedComponent_Host_0, {}, {}, []);
export { RecipientsAlreadyCreatedComponentNgFactory as RecipientsAlreadyCreatedComponentNgFactory };
