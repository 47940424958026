import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

import { Alarm } from 'services/alarms.model';
import { MetricsService } from 'services/metrics.service';

export interface LimitsTableEntry {
  identifier: string;
  limitName: string;
  metricName: string;
  limitsDescription: string;
  notificationText: string;
}

export namespace LimitsTableEntry {
  export function convertFromAlarmLimits(
    alarmLimits: Alarm.Limit[],
    metricsService: MetricsService
  ) {
    const entries = alarmLimits.map(aL => {
      return LimitsTableEntry.convertFromAlarmLimit(aL, metricsService);
    });
    return entries;
  }

  export function convertFromAlarmLimit(
      alarmLimit: Alarm.Limit,
      metricsService: MetricsService) {
    const metric = metricsService.metricForIdentifier(alarmLimit.metricIdentifier);
    const entry: LimitsTableEntry = {
      identifier: alarmLimit.identifier,
      limitName: alarmLimit.name,
      metricName: metric != null && metric.name != null ? metric.name : alarmLimit.metricIdentifier,
      limitsDescription: Alarm.Limit.Rule.descriptionRule(alarmLimit),
      notificationText: alarmLimit.notificationText
    };
    return entry;
  }
}

@Component({
  selector: 'app-limits-table',
  templateUrl: './limits-table.component.html',
  styleUrls: ['./limits-table.component.scss']
})
export class LimitsTableComponent implements OnInit, OnDestroy {

  @Input() limits$: BehaviorSubject<LimitsTableEntry[]>;
  @Input() showEdit = true;
  @Input() removeLimitsDisabled = false;
  @Output() removeLimit = new EventEmitter<LimitsTableEntry>();
  @Output() editLimit = new EventEmitter<LimitsTableEntry>();

  private limitsSubscription: Subscription;
  private metricsValueSubscription: Subscription;
  private limits: LimitsTableEntry[] = [];
  private metricsValues: {[metricName: string]: number|null};

  constructor(private metrics: MetricsService) { }

  ngOnInit() {
    this.limitsSubscription = this.limits$.subscribe(entries => {
      this.limits.forEach(l => {
        this.metrics.removeMetricNameToPollForValue(l.metricName);
      });
      this.limits = entries;
      this.limits.forEach(l => {
        this.metrics.addMetricNameToPollForValue(l.metricName);
      });
    });
    this.metricsValueSubscription = this.metrics.metricsValues$.subscribe(values => {
      this.metricsValues = values;
    });
  }

  ngOnDestroy() {
    this.metricsValueSubscription.unsubscribe();
    this.limitsSubscription.unsubscribe();
    this.limits.forEach(l => {
      this.metrics.removeMetricNameToPollForValue(l.metricName);
    });
  }

  onEditLimit(limit: LimitsTableEntry) {
    this.editLimit.emit(limit);
  }

  onRemoveLimit(limit: LimitsTableEntry) {
    this.removeLimit.emit(limit);
  }

  currentValueForMetric(identifier: string) {
    const value = this.metricsValues[identifier];
    return value;
  }

}
