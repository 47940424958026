import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';

import { User } from './user.model';

namespace UserMiddleware {
    export interface UserResponse {
        username: string;
    }
}

export class UserMiddleware {
    constructor(private http: HttpClient) {
    }

    user() {
        const url = environment.endpoints.user;
        return this.http.get<UserMiddleware.UserResponse>(url, { withCredentials: true }).pipe(
            map(res => {
                const user: User = {
                    name: res.username
                };
                return user;
            })
        );
    }
}
