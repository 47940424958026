import { map } from 'rxjs/operators';
import { Recipient } from '@services/recipients.model';
import { SubscriptionsService } from 'services/subscriptions.service';
import * as i0 from "@angular/core";
import * as i1 from "../../services/subscriptions.service";
var SubscriptionsRecipientsUnconfirmedResolve = /** @class */ (function () {
    function SubscriptionsRecipientsUnconfirmedResolve(subscriptions) {
        this.subscriptions = subscriptions;
    }
    SubscriptionsRecipientsUnconfirmedResolve.prototype.resolve = function (route, state) {
        console.log('SubscriptionsRecipientsUnconfirmedResolve');
        return this.subscriptions.allSubscriptions().pipe(map(function (resp) {
            var metaInfos = resp.filter(function (info) {
                // tslint:disable-next-line:max-line-length
                var isConfirmed = Recipient.Confirmation.isFullConfirmedRecipient(info.mail, info.mailConfirmed, info.mobile, info.smsConfirmed);
                return isConfirmed ? false : true;
            });
            return metaInfos;
        }));
    };
    SubscriptionsRecipientsUnconfirmedResolve.ngInjectableDef = i0.defineInjectable({ factory: function SubscriptionsRecipientsUnconfirmedResolve_Factory() { return new SubscriptionsRecipientsUnconfirmedResolve(i0.inject(i1.SubscriptionsService)); }, token: SubscriptionsRecipientsUnconfirmedResolve, providedIn: "root" });
    return SubscriptionsRecipientsUnconfirmedResolve;
}());
export { SubscriptionsRecipientsUnconfirmedResolve };
