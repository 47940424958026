import { AlarmsService } from 'services/alarms.service';
import { of } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../../services/alarms.service";
var AlarmsResolve = /** @class */ (function () {
    function AlarmsResolve(alarms) {
        this.alarms = alarms;
    }
    AlarmsResolve.prototype.resolve = function (route, state) {
        console.log('AlarmsResolve');
        var currentInfos = this.alarms.alarmMetaInfo$.value;
        if (currentInfos == null || currentInfos.length === 0) {
            return this.alarms.refreshAlarms();
        }
        return of(currentInfos);
    };
    AlarmsResolve.ngInjectableDef = i0.defineInjectable({ factory: function AlarmsResolve_Factory() { return new AlarmsResolve(i0.inject(i1.AlarmsService)); }, token: AlarmsResolve, providedIn: "root" });
    return AlarmsResolve;
}());
export { AlarmsResolve };
