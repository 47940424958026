<form #newRecipientForm="ngForm" (ngSubmit)="onSubmit(newRecipientForm)" class="clearfix">
  <div class="content-main">
    <ng-container [ngSwitch]="mode">
      <app-add-alarm-heading *ngSwitchCase="Mode.add" [mode]="HeadingMode.RecipientsAlreadyCreated" [lastPathName]="'Add recipient'"></app-add-alarm-heading>
      <app-add-alarm-heading *ngSwitchCase="Mode.edit" [mode]="HeadingMode.RecipientsAlreadyCreated" [lastPathName]="'Edit recipient'"></app-add-alarm-heading>
    </ng-container>
    <div class="content-body">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 body-head">
            <div class="float-md-left content-padding subtitle">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <h3 class="content-title">
              <ng-container [ngSwitch]="mode">
                <span *ngSwitchCase="Mode.add" i18n>New Recipient</span>
                <span *ngSwitchCase="Mode.edit" i18n>Edit Recipient</span>
              </ng-container>
            </h3>
            <div class="content-padding">
              <app-upsert-recipient-form-container [recipient]="recipient" [termsAndConditionsAccepted]="termsAndConditionsAccepted"></app-upsert-recipient-form-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="btn-toolbar float-right buttons-outside-content" role="toolbar" aria-label="Toolbar with button groups">
    <div class="btn-group" role="group" aria-label="Second group" *ngIf="inAddRecipientMode; else editMode">
      <button class="btn btn-primary btn-phoenix float-right" type="submit" [disabled]="!newRecipientForm.form.valid"
        i18n>Edit recipient</button>
    </div>
    <ng-template #editMode>
      <div class="btn-group" role="group" aria-label="Second group">
        <button class="btn btn-primary btn-phoenix float-right simplebox" type="submit"
          [disabled]="!newRecipientForm.form.valid || saveInProgress" i18n>Save recipient</button>
      </div>
    </ng-template>
  </div>
</form>