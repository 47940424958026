import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AlarmsService } from 'services/alarms.service';
import { Alarm } from 'services/alarms.model';
import { of } from 'rxjs';

@Injectable({
    providedIn: 'root'
  })
export class CreateAlarmResolve implements Resolve<Alarm> {
    constructor(private alarms: AlarmsService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const alarm = this.alarms.createPlainAlarm();
        this.alarms.alarmToBeCreated = alarm;
        return of(alarm);
    }
}
