/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./alarm-bottom-btn-group.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./alarm-bottom-btn-group.component";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
import * as i4 from "../../../services/alarms.service";
var styles_AlarmBottomBtnGroupComponent = [i0.styles];
var RenderType_AlarmBottomBtnGroupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AlarmBottomBtnGroupComponent, data: {} });
export { RenderType_AlarmBottomBtnGroupComponent as RenderType_AlarmBottomBtnGroupComponent };
export function View_AlarmBottomBtnGroupComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { duplicateText: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["aria-label", "Toolbar with button groups"], ["class", "btn-toolbar float-right buttons-outside-content"], ["role", "toolbar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["aria-label", "First group"], ["class", "btn-group mr-2"], ["role", "group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "button", [["class", "btn btn-primary btn-phoenix float-right"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onDuplicateAlarm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Duplicate alarm"])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["aria-label", "Second group"], ["class", "btn-group"], ["role", "group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "button", [["class", "btn btn-primary btn-phoenix float-right"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onAddMore() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, [[1, 0], ["duplicateText", 1]], null, 1, "span", [], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["(Duplicate)"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.duplicateAlarmInProgress; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.secondBtnTitle; _ck(_v, 7, 0, currVal_1); var currVal_2 = true; _ck(_v, 8, 0, currVal_2); }); }
export function View_AlarmBottomBtnGroupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-alarm-bottom-btn-group", [], null, null, null, View_AlarmBottomBtnGroupComponent_0, RenderType_AlarmBottomBtnGroupComponent)), i1.ɵdid(1, 114688, null, 0, i2.AlarmBottomBtnGroupComponent, [i3.NgbModal, i4.AlarmsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlarmBottomBtnGroupComponentNgFactory = i1.ɵccf("app-alarm-bottom-btn-group", i2.AlarmBottomBtnGroupComponent, View_AlarmBottomBtnGroupComponent_Host_0, { alarm: "alarm", secondBtnTitle: "secondBtnTitle" }, { addMore: "addMore", duplicatedAlarm: "duplicatedAlarm" }, []);
export { AlarmBottomBtnGroupComponentNgFactory as AlarmBottomBtnGroupComponentNgFactory };
