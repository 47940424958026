/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./add-alarm-added-entries-heading.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./add-alarm-added-entries-heading.component";
var styles_AddAlarmAddedEntriesHeadingComponent = [i0.styles];
var RenderType_AddAlarmAddedEntriesHeadingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AddAlarmAddedEntriesHeadingComponent, data: {} });
export { RenderType_AddAlarmAddedEntriesHeadingComponent as RenderType_AddAlarmAddedEntriesHeadingComponent };
export function View_AddAlarmAddedEntriesHeadingComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "content-heading"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.headlineText; _ck(_v, 1, 0, currVal_0); }); }
export function View_AddAlarmAddedEntriesHeadingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-add-alarm-added-entries-heading", [], null, null, null, View_AddAlarmAddedEntriesHeadingComponent_0, RenderType_AddAlarmAddedEntriesHeadingComponent)), i1.ɵdid(1, 114688, null, 0, i2.AddAlarmAddedEntriesHeadingComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AddAlarmAddedEntriesHeadingComponentNgFactory = i1.ɵccf("app-add-alarm-added-entries-heading", i2.AddAlarmAddedEntriesHeadingComponent, View_AddAlarmAddedEntriesHeadingComponent_Host_0, { headlineText: "headlineText" }, {}, []);
export { AddAlarmAddedEntriesHeadingComponentNgFactory as AddAlarmAddedEntriesHeadingComponentNgFactory };
