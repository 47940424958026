import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AddAlarmStepsBtnGroupComponent } from '@components-general/add-alarm-steps-btn-group/add-alarm-steps-btn-group.component';
import { AlarmsService } from 'services/alarms.service';
import { Alarm } from 'services/alarms.model';
import { RecipientsTableEntry } from '@components-general/recipients-table/recipients-table.component';
import { RecipientsService } from 'services/recipients.service';
import { SuccessUpsertRemoveComponent } from '@components-modal/success-upsert-remove/success-upsert-remove.component';
import { FailedUpsertRemoveComponent } from '@components-modal/failed-upsert-remove/failed-upsert-remove.component';
import { NoRecipientsAddedComponent } from '@components-modal/no-recipients-added/no-recipients-added.component';
// tslint:disable-next-line:max-line-length
import { UpsertAlarmRecipientFormContainerComponent } from '@components-general/upsert-alarm-recipient-form-container/upsert-alarm-recipient-form-container.component';

@Component({
  selector: 'app-add-alarm-step3',
  templateUrl: './add-alarm-step3.component.html',
  styleUrls: ['./add-alarm-step3.component.scss']
})
export class AddAlarmStep3Component implements OnInit {

  @ViewChild('upsertAlarmRecipientFormContainer') upsertAlarmRecipientFormContainerCmp: UpsertAlarmRecipientFormContainerComponent;

  Step = AddAlarmStepsBtnGroupComponent.Step;

  recipientsSubscription: Subscription;
  addedRecipients$ = new BehaviorSubject<RecipientsTableEntry[]>([]);
  recipient: Alarm.Recipient;
  saveInProgress = false;

  alarm: Alarm;

  constructor(private modalService: NgbModal, private router: Router,
    private alarms: AlarmsService, private recipients: RecipientsService) {}

  ngOnInit() {
    this.alarm = this.alarms.alarmToBeCreated;
    this.recipient = this.alarms.createPlainAlarmRecipient();
    const addedRecipients = RecipientsTableEntry.convertFromAlarmRecipients(this.alarm.recipients, this.alarm, this.recipients);
    this.addedRecipients$.next(addedRecipients);
  }

  onSubmit(form: NgForm) {
    if (form.invalid) {
      return;
    }

    const recipient = Alarm.Recipient.deepCopy(this.recipient);
    const newRecepients = this.alarms.upsertRecipient(recipient, this.alarm);
    const alarmRecipients = RecipientsTableEntry.convertFromAlarmRecipients(newRecepients, this.alarm, this.recipients);
    this.addedRecipients$.next(alarmRecipients);
    this.recipient = this.alarms.createPlainAlarmRecipient();
    form.reset();
  }

  onRemoveRecipient(recipient: RecipientsTableEntry) {
    const newRecipients = this.alarms.removeRecipientWithIdentifier(recipient.recipientId, this.alarm);
    const alarmRecipients = RecipientsTableEntry.convertFromAlarmRecipients(newRecipients, this.alarm, this.recipients);
    this.addedRecipients$.next(alarmRecipients);
    this.upsertAlarmRecipientFormContainerCmp.triggerUpdateAvailableRecipients();
  }

  onRecipientChanged(recipient: Alarm.Recipient) {
    this.recipient = recipient;
  }

  onSave() {
    if (this.alarm.recipients.length === 0) {
      const modalRef = NoRecipientsAddedComponent.openDefaultModal(this.modalService);
      modalRef.result.then(
        result => {
          this.save();
        },
        reason => { }
      );
    } else {
      this.save();
    }
  }

  private save() {
    this.saveInProgress = true;
    this.alarms.upsertAlarm(this.alarm).subscribe(
      resp => {
        this.router.navigate(['main', 'alarm', resp.identifier]);
        SuccessUpsertRemoveComponent.openDefaultModal(SuccessUpsertRemoveComponent.Type.AlarmCreated, this.modalService);
      },
      err => {
        console.error('save alarm failed', err);
        FailedUpsertRemoveComponent.openDefaultModal(err, this.modalService);
        this.saveInProgress = false;
      },
      () => {
        this.saveInProgress = false;
      }
    );
  }

}
