const baseUrl = 'https://api.tsd-alarmierung.sic-software.tk/v1';
const currentLocation = window.location;

export const environment = {
  production: false,
  showLogs: false,
  appVersion: '1.0.0',
  endpoints: {
    verifyLogin: `${baseUrl}/login/check`,
    ssoLogin: `${baseUrl}/login?redirect_url=${currentLocation.protocol}//${currentLocation.hostname}/sso_redirect`,
    user: `${baseUrl}/user`,
    logout: `${baseUrl}/logout`,
    alarms: `${baseUrl}/alarms`,
    metrics: `${baseUrl}/metrics`,
    metricsValues: `${baseUrl}/metrics/values`,
    recipients: `${baseUrl}/recipients`,
    subscriptions: `${baseUrl}/subscriptions`,
    alerts: `${baseUrl}/alerts?size=100&page=0`
  },
  externalLinks: {
    proficloud: 'https://www.proficloud-staging.net',
    tsdAnalytics: 'https://www.proficloud-staging.net/app/service/login/MzFlNWQ0NjYtNjI0ZS00NDBmLTkxZTUtYTI0NTM4NzY2NmEx'
  }
};
