import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AlarmsService } from 'services/alarms.service';
import { Alarm } from 'services/alarms.model';
import { of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AlarmRecipientResolve implements Resolve<Alarm.Recipient> {
    constructor(private alarms: AlarmsService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        console.log('AlarmRecipientResolve');
        const alarm = this.alarms.currentAlarm$.value;
        const recipient = Alarm.Recipient.recipientByIdentifier(route.params.id, alarm);
        return of(recipient);
    }
}
