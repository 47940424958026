<div class="content-main">
  <app-add-alarm-heading [mode]="HeadingMode.RecipientsAlreadyCreated" [lastPathName]="recipient.name">
  </app-add-alarm-heading>
  <div class="content-body">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12 body-head">
          <div class="float-md-left content-padding subtitle">
            <h5>{{ recipient.name }}</h5>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h3 class="content-title" i18n>Recipient</h3>

          <div class="content-padding">
            <form>
              <div class="form-row align-items-center">
                <label for="mailInput" class="col-md-2 col-form-label mb-2"><strong i18n>Mail
                    address</strong></label>
                <div class="col-md-4">
                  <input type="text" readonly class="form-control-plaintext mb-2" id="mailInput" name="mailInput"
                    [(ngModel)]="recipient.mail">
                </div>
                <div class="col-md-6">
                  <div class="form-check mb-2">
                    <input class="form-check-input" type="checkbox" id="confirmationMail" name="confirmationMail"
                      [(ngModel)]="recipient.mailConfirmed" (ngModelChange)="onConfirmationMail($event)"
                      [disabled]="isConfirmMailDisabled()">
                    <ng-container [ngSwitch]="recipient.mailConfirmed">
                      <label *ngSwitchCase="true" class="form-check-label" for="confirmationMail" i18n>
                        Confirmed
                      </label>
                      <label *ngSwitchCase="false" class="form-check-label" for="confirmationMail" i18n>
                        Confirmation pending
                      </label>
                    </ng-container>
                  </div>
                </div>
              </div>
              <div class="form-row align-items-center">
                <label for="phoneInput" class="col-2 col-form-label mb-2"><strong i18n>Phone number</strong></label>
                <div class="col-md-4">
                  <input type="text" readonly class="form-control-plaintext mb-2" id="phoneInput" name="phoneInput"
                    [(ngModel)]="recipient.mobile">
                </div>
                <div class="col-md-6">
                  <div class="form-check mb-2">
                    <input class="form-check-input" type="checkbox" id="confirmationSMS" name="confirmationSMS"
                      [(ngModel)]="recipient.smsConfirmed" (ngModelChange)="onConfirmationSMS($event)"
                      [disabled]="isConfirmSMSDisabled()">
                    <ng-container [ngSwitch]="recipient.smsConfirmed">
                      <label *ngSwitchCase="true" class="form-check-label" for="confirmationSMS" i18n>
                        Confirmed
                      </label>
                      <label *ngSwitchCase="false" class="form-check-label" for="confirmationSMS" i18n>
                        Confirmation pending
                      </label>
                    </ng-container>
                  </div>
                </div>
              </div>
              <button class="btn btn-primary btn-phoenix float-right" type="button"
                [disabled]="isSendTestAlarmDisabled()" (click)="onSendTestAlarm()" i18n>Send test alarm</button>
              <div class="clearfix"></div>
            </form>
          </div>
          <h3 class="content-title" i18n>Subscriptions</h3>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col" class="d-table-cell" i18n>Name alarm</th>
                  <th scope="col" class="d-table-cell" i18n>Name limit</th>
                  <th scope="col" class="d-none d-md-table-cell" i18n>Way to notice</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let sub of subscriptions">
                  <td class="d-table-cell align-middle">{{ sub.alarm }}</td>
                  <td class="d-table-cell align-middle">{{ sub.limit }}</td>
                  <td class="d-none d-md-table-cell align-middle">
                    <span *ngIf="sub.mail" i18n>email</span>
                    <span *ngIf="sub.mail && sub.sms">, </span>
                    <span *ngIf="sub.sms" i18n>sms</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<button class="btn btn-primary btn-phoenix float-right" (click)="onEditRecipient()" i18n>Edit recipient</button>