import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { LimitsTableEntry } from '@components-general/limits-table/limits-table.component';
import { AlarmBtnGroupComponent } from '@components-general/alarm-btn-group/alarm-btn-group.component';
import { AlarmsService } from 'services/alarms.service';
import { Alarm } from 'services/alarms.model';
import { MetricsService } from 'services/metrics.service';
import { SettingsService } from 'services/settings.service';
import { WarningRemoveComponent } from '@components-modal/warning-remove/warning-remove.component';
import { SuccessUpsertRemoveComponent } from '@components-modal/success-upsert-remove/success-upsert-remove.component';
import { FailedUpsertRemoveComponent } from '@components-modal/failed-upsert-remove/failed-upsert-remove.component';
import { UnableDeleteComponent } from '@components-modal/unable-delete/unable-delete.component';

@Component({
  selector: 'app-alarm-limits',
  templateUrl: './alarm-limits.component.html',
  styleUrls: ['./alarm-limits.component.scss']
})
export class AlarmLimitsComponent implements OnInit, OnDestroy {

  Step = AlarmBtnGroupComponent.Step;

  currentPage = 1;
  numberOfLimits = 0;
  alarm: Alarm;
  limits$ = new BehaviorSubject<LimitsTableEntry[]>([]);

  searchText: string;
  allAlarmLimits: Alarm.Limit[] = [];
  removeInProgress = false;
  private allLimitEntries: LimitsTableEntry[] = [];

  private alarmSubscription: Subscription;
  private settingsSubscription: Subscription;

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private alarms: AlarmsService,
    private metrics: MetricsService,
    private settings: SettingsService
  ) { }

  ngOnInit() {
    this.alarmSubscription = this.alarms.currentAlarm$.subscribe(alarm => {
      this.alarm = alarm;
      this.numberOfLimits = this.alarm.limits.length;
      this.allAlarmLimits = this.alarm.limits;
      this.allLimitEntries = this.allAlarmLimits.map(l => {
        return LimitsTableEntry.convertFromAlarmLimit(l, this.metrics);
      });
      this.allLimitEntries = this.allLimitEntries.sort((l1, l2) => {
        const nameE1 = l1.limitName.toLocaleLowerCase();
        const nameE2 = l2.limitName.toLocaleLowerCase();
        if (nameE1 > nameE2) {
          return 1;
        }
        if (nameE1 < nameE2) {
          return -1;
        }
        return 0;
      });
      this.updateLimits();
    });
    this.settingsSubscription = this.settings.showEntriesPerPage$.subscribe(entriesPerPage => {
      this.updateLimits(true);
    });
  }

  ngOnDestroy() {
    this.alarmSubscription.unsubscribe();
    this.settingsSubscription.unsubscribe();
  }

  onPageChanged(newPage: number) {
    this.currentPage = newPage;
    this.updateLimits();
  }

  onChangeSearchText(searchText: string) {
    this.searchText = searchText;
    this.updateLimits(true);
  }

  onEditLimit(limit: LimitsTableEntry) {
    this.router.navigate(['/main/alarm', this.alarm.identifier, 'edit_limit', limit.identifier]);
  }

  onRemoveLimit(limit: LimitsTableEntry) {
    const activeSubscribedRecipients = this.alarm.recipients.filter(r => {
      const subscribedLimit = r.subscribedToLimits.find(l => {
        return l.identifier === limit.identifier;
      });
      return subscribedLimit != null;
    });
    if (activeSubscribedRecipients.length > 0) {
      UnableDeleteComponent.openDefaultModal(UnableDeleteComponent.Type.LimitReasonRecpientsAddedd, this.modalService);
    } else {
      const modalRef = this.modalService
        .open(WarningRemoveComponent, { centered: true, size: 'lg', backdrop: 'static', keyboard: false });
      if (modalRef.componentInstance instanceof WarningRemoveComponent) {
        modalRef.componentInstance.name = limit.limitName;
        modalRef.componentInstance.type = WarningRemoveComponent.Type.LimitFromAlarm;
      }
      modalRef.result.then(
        result => {
          this.removeInProgress = true;
          this.alarms.removeLimitWithIdentifier(limit.identifier, this.alarm);
          this.alarms.upsertAlarm(this.alarm).subscribe(
            msg => {
              this.alarms.currentAlarm$.next(msg);
              SuccessUpsertRemoveComponent.openDefaultModal(SuccessUpsertRemoveComponent.Type.AlarmLimitRemoved, this.modalService);
            },
            err => {
              FailedUpsertRemoveComponent.openDefaultModal(err, this.modalService);
              console.error(err);
              this.removeInProgress = false;
            },
            () => {
              this.removeInProgress = false;
            }
          );
        },
        reason => { }
      );
    }
  }

  onDuplicatedAlarm(alarm: Alarm) {
    this.router.navigate(['/main/alarm', alarm.identifier, 'limits']);
  }

  onAddMoreLimits() {
    this.router.navigate(['/main/alarm', this.alarm.identifier, 'add_limit']);
  }

  private updateLimits(resetPage: boolean = false) {
    const totalEntries = this.allLimitEntries.filter(entry => {
      return this.filterByText(entry, this.searchText);
    });
    this.numberOfLimits = totalEntries.length;
    const entriesPerPage = this.settings.showEntriesPerPage$.value;
    let start: number;
    if (resetPage) {
      this.currentPage = 1;
    }
    start = this.currentPage * entriesPerPage - entriesPerPage;
    start = start < totalEntries.length ? start : 0;
    const end = start + entriesPerPage;
    const clippedEntries = totalEntries.slice(start, end);
    this.limits$.next(clippedEntries);
  }

  private filterByText(limit: LimitsTableEntry, text: String) {
    if (text == null || text.length === 0) {
      return true;
    }
    const t = text.toLocaleLowerCase();
    const name = limit.limitName != null ? limit.limitName.toLocaleLowerCase() : '';
    if (name.includes(t)) {
      return true;
    }
    const desc = limit.limitsDescription != null ? limit.limitsDescription.toLocaleLowerCase() : '';
    if (desc.includes(t)) {
      return true;
    }
    const metricName = limit.metricName != null ? limit.metricName.toLocaleLowerCase() : '';
    if (metricName.includes(t)) {
      return true;
    }
    const notificationText = limit.notificationText != null ? limit.notificationText.toLocaleLowerCase() : '';
    if (notificationText.includes(t)) {
      return true;
    }
    return false;
  }

}
