import { Router } from '@angular/router';
import { AlarmsService } from 'services/alarms.service';
import * as i0 from "@angular/core";
import * as i1 from "../../services/alarms.service";
import * as i2 from "@angular/router";
var ConfigureAlarmGuard = /** @class */ (function () {
    function ConfigureAlarmGuard(alarms, router) {
        this.alarms = alarms;
        this.router = router;
    }
    ConfigureAlarmGuard.prototype.canActivate = function (next, state) {
        console.log('ConfigureAlarmGuard');
        if (this.alarms.alarmToBeCreated == null) {
            this.router.navigate(['/main', 'add_alarm']);
            return false;
        }
        return true;
    };
    ConfigureAlarmGuard.ngInjectableDef = i0.defineInjectable({ factory: function ConfigureAlarmGuard_Factory() { return new ConfigureAlarmGuard(i0.inject(i1.AlarmsService), i0.inject(i2.Router)); }, token: ConfigureAlarmGuard, providedIn: "root" });
    return ConfigureAlarmGuard;
}());
export { ConfigureAlarmGuard };
