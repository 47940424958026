import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AlarmsService } from 'services/alarms.service';
import { Alarm } from 'services/alarms.model';
import { AddAlarmHeading } from '@components-general/add-alarm-heading/add-alarm-heading.component';
import { SuccessUpsertRemoveComponent } from '@components-modal/success-upsert-remove/success-upsert-remove.component';
import { FailedUpsertRemoveComponent } from '@components-modal/failed-upsert-remove/failed-upsert-remove.component';

@Component({
  selector: 'app-alarm-upsert-recipient',
  templateUrl: './alarm-upsert-recipient.component.html',
  styleUrls: ['./alarm-upsert-recipient.component.scss']
})
export class AlarmUpsertRecipientComponent implements OnInit, OnDestroy {

  @ViewChild('addRecipientText') addRecipientText: ElementRef;
  @ViewChild('editRecipientText') editRecipientText: ElementRef;

  alarm: Alarm;
  recipientIdentifier: string;
  recipient: Alarm.Recipient;
  inViewerMode: boolean;
  editRecipient: boolean;

  alarmHeadingMiddleEntries: AddAlarmHeading.MiddleEntry[];
  alarmHeadingLastPath: string;
  saveInProgress = false;

  private alarmSubscription: Subscription;
  private recipientSubscription: Subscription;

  constructor(private modalService: NgbModal, private router: Router, private route: ActivatedRoute, private alarms: AlarmsService) { }

  ngOnInit() {
    this.alarmSubscription = this.alarms.currentAlarm$.subscribe(alarm => {
      this.alarm = alarm;
      this.updateAlarmHeadingMiddleEntries();
    });
    this.recipientSubscription = this.route.data.subscribe(data => {
      this.recipient = data.recipient;
      this.editRecipient = this.recipient.subscribedToLimits.length > 0;
      this.alarmHeadingLastPath = this.editRecipient ?
            this.editRecipientText.nativeElement.innerHTML : this.addRecipientText.nativeElement.innerHTML;
      // this.inViewerMode = this.editRecipient ? true : false;
      this.inViewerMode = false;
    });
  }

  ngOnDestroy() {
    this.alarmSubscription.unsubscribe();
    this.recipientSubscription.unsubscribe();
  }

  onRecipientChanged(recipient: Alarm.Recipient) {
    this.recipient = recipient;
  }

  onCancel() {
    this.router.navigate(['/main/alarm', this.alarm.identifier, 'recipients']);
  }

  onEdit() {
    this.inViewerMode = false;
  }

  onSubmit(form: NgForm) {
    if (form.invalid) {
      return;
    }
    this.saveInProgress = true;
    const recipient = Alarm.Recipient.deepCopy(this.recipient);
    console.log('onSubmit - recipient', recipient);
    this.alarms.upsertRecipient(recipient, this.alarm);
    console.log('onSubmit - upsertRecipient', this.alarm);
    this.alarms.upsertAlarm(this.alarm).subscribe(
      resp => {
        this.alarms.currentAlarm$.next(resp);
        this.router.navigate(['/main/alarm', this.alarm.identifier, 'recipients']);
        // tslint:disable-next-line:max-line-length
        const type = this.editRecipient ? SuccessUpsertRemoveComponent.Type.AlarmRecipientUpdated : SuccessUpsertRemoveComponent.Type.AlarmRecipientCreated;
        SuccessUpsertRemoveComponent.openDefaultModal(type, this.modalService);
      },
      err => {
        console.error(err);
        FailedUpsertRemoveComponent.openDefaultModal(err, this.modalService);
        this.saveInProgress = false;
      },
      () => {
        this.saveInProgress = false;
      }
    );
  }

  private updateAlarmHeadingMiddleEntries() {
    const entry: AddAlarmHeading.MiddleEntry = {
      name: this.alarm.name,
      routerLinkValue: `/main/alarm/${this.alarm.identifier}/recipients`
    };
    this.alarmHeadingMiddleEntries = [entry];
  }

}
