import { Component, OnInit, OnChanges, Input, SimpleChanges, ViewChild } from '@angular/core';
import { ControlContainer, NgForm, NgModel } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Recipient } from '@services/recipients.model';
import { TermsAndConditionsComponent } from '@components-modal/terms-and-conditions/terms-and-conditions.component';

interface MobileInput {
  number: string;
  internationalNumber: string;
  nationalNumber: string;
}

@Component({
  selector: 'app-upsert-recipient-form-container',
  templateUrl: './upsert-recipient-form-container.component.html',
  styleUrls: ['./upsert-recipient-form-container.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer, useExisting: NgForm
    }
  ]
})
export class UpsertRecipientFormContainerComponent implements OnInit, OnChanges {

  @Input() recipient: Recipient;
  @Input() termsAndConditionsAccepted: boolean;

  @ViewChild('inputRecipientPhone') inputRecipientPhone: NgModel;

  mobileInput: MobileInput | string;

  mailConfirmed = false;
  mobileConfirmed = false;

  // phoneInputModelOptions = { updateOn: 'change' };
  phoneInputModelOptions = { updateOn: 'blur' };

  constructor(private modalService: NgbModal) { }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    const recipientChanged = changes['recipient'];
    if (recipientChanged != null) {
      this.inputRecipientPhone.valueAccessor.writeValue(this.recipient.mobile);
    }
  }

  mobileInputChanged(input: MobileInput) {
    if (input != null) {
      this.recipient.mobile = input.internationalNumber;
    } else {
      this.recipient.mobile = null;
    }
    this.recipient.smsConfirmed = false;
  }

  mailInputChanged(evt: any) {
    console.log('evt:', evt);
    this.recipient.mailConfirmed = false;
  }

  openTermsAndConditions() {
    TermsAndConditionsComponent.openDefaultModal(this.modalService);
  }

  isDisabledConfirmMail() {
    return this.recipient.mail == null || this.recipient.mail.length === 0 ? true : false;
  }

  isDisabledConfirmSMS() {
    return this.recipient.mobile == null || this.recipient.mobile.length === 0 ? true : false;
  }

}
