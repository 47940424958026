import { UserService } from 'services/user.service';
import * as i0 from "@angular/core";
import * as i1 from "../../services/user.service";
var UserResolve = /** @class */ (function () {
    function UserResolve(userService) {
        this.userService = userService;
    }
    UserResolve.prototype.resolve = function (route, state) {
        console.log('UserResolve');
        return this.userService.user();
    };
    UserResolve.ngInjectableDef = i0.defineInjectable({ factory: function UserResolve_Factory() { return new UserResolve(i0.inject(i1.UserService)); }, token: UserResolve, providedIn: "root" });
    return UserResolve;
}());
export { UserResolve };
