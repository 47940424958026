import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AlertsService } from 'services/alerts.service';
import { Alert } from 'services/alerts.model';

import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
  })
export class AlertsResolve implements Resolve<Alert[]> {
    constructor(private alerts: AlertsService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        console.log('AlertsResolve');
        return this.alerts.allAlerts().pipe(
            tap(msg => {
                // console.log('AlertsResolve msg', msg);
            })
        );
    }
}
