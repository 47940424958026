import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { RecipientsService } from 'services/recipients.service';
import { Recipient } from 'services/recipients.model';
import { of } from 'rxjs';

@Injectable({
    providedIn: 'root'
  })
export class RecipientsResolve implements Resolve<Recipient[]> {
    constructor(private recipients: RecipientsService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        console.log('RecipientsResolve');
        const currentRecipients = this.recipients.recipients$.value;
        if (currentRecipients == null || currentRecipients.length === 0) {
            return this.recipients.refreshRecipients();
        }
        return of(currentRecipients);
    }
}
