import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { MetricsService, Metric } from 'services/metrics.service';
import { of } from 'rxjs';

@Injectable({
    providedIn: 'root'
  })
export class MetricsResolve implements Resolve<Metric[]> {
    constructor(private metrics: MetricsService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        console.log('MetricsResolve');
        const currentMetrics = this.metrics.metrics$.value;
        if (currentMetrics == null || currentMetrics.length === 0) {
            return this.metrics.refreshMetrics();
        }
        return of(currentMetrics);
    }
}
