import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError, mergeMap } from 'rxjs/operators';
import { throwError, pipe, forkJoin } from 'rxjs';

import { environment } from 'environments/environment';
import { AlarmsService } from 'services/alarms.service';
import { MetricsService } from 'services/metrics.service';
import { RecipientsService } from 'services/recipients.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  isAuthenticated = false;

  constructor(private http: HttpClient,
    private alarms: AlarmsService,
    private metrics: MetricsService,
    private recipients: RecipientsService
    ) { }

  logout() {
    const url = environment.endpoints.logout;
    const obs = this.http.get(url, { withCredentials: true }).pipe(
      map(resp => {
        this.isAuthenticated = false;
        this.resetDataAfterLogout();
        return true;
      }),
      catchError(error => {
        console.error('logout failed', error);
        this.isAuthenticated = false;
        return throwError(error);
      })
    );
    return obs;
  }

  verifyLoginState() {
    this.resetDataAfterLogout();
    const url = environment.endpoints.verifyLogin;
    const obs = this.http.get(url, { withCredentials: true }).pipe(
      map((resp) => {
        this.isAuthenticated = true;
        return resp;
      }),
      catchError(error => {
        // do additional error handling if needed
        console.error('verifyLoginState error:', error);
        this.isAuthenticated = false;
        return throwError(error);
      })
    );
    return obs;
  }

  initiateSSO() {
    const url = environment.endpoints.ssoLogin;
    const obs = this.http.get(url, { withCredentials: true })
      .pipe(
        map((resp) => {
          return resp['redirect_to'];
        }),
        catchError(error => {
          console.error('initiateSSO failed', error);
          return throwError(error);
        })
      );
    return obs;
  }

  resetDataAfterLogout() {
    this.alarms.resetAlarms();
    this.metrics.resetMetrics();
    this.recipients.resetRecipients();
  }
}
