import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {

  constructor(public modal: NgbActiveModal) { }

  static openDefaultModal(modalService: NgbModal) {
    const modal = modalService.open(TermsAndConditionsComponent,
      { centered: true, size: 'lg', backdrop: 'static', keyboard: false});
    return modal;
  }

  ngOnInit() {
  }

}
