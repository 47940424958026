import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap, concatMap } from 'rxjs/operators';

import { Recipient } from 'services/recipients.model';
import { RecipientsMiddleware } from './recipients.middleware';

@Injectable({
  providedIn: 'root'
})
export class RecipientsService {

  recipients$ = new BehaviorSubject<Recipient[]>([]);
  recipientsUnconfirmed$ = new BehaviorSubject<Recipient[]>([]);
  countRecipientsUnconfirmed = 0;

  constructor(private http: HttpClient) {}

  recipientByIdentifier(identifier: string) {
    const allRecepients = this.recipients$.value;
    const recipient = allRecepients.find(r => {
      return r.identifier === identifier;
    });
    return recipient;
  }

  resetRecipients() {
    this.recipients$.next([]);
  }

  refreshRecipients() {
    return new RecipientsMiddleware(this.http).refreshRecipients().pipe(
      tap(resp => {
        this.recipients$.next(resp);
        this.updateUnconfirmedRecipients(resp);
      })
    );
  }

  getRecipientByIdentifier(identifier: string) {
    return new RecipientsMiddleware(this.http).getRecipient(identifier);
  }

  subscriptionsWithRecipientIdentifier(identifier: string) {
    return new RecipientsMiddleware(this.http).getRecipientSubscriptionsWithRecipientIdentifier(identifier);
  }

  upsertRecipient(recipient: Recipient) {
    const exists = this.recipients$.value.findIndex(r => {
      return r.identifier === recipient.identifier;
    });
    const mw = new RecipientsMiddleware(this.http);
    let recipientObs: Observable<Recipient>;
    if (exists > -1) {
      // update
      recipientObs = mw.updateRecipient(recipient);
    } else {
      // add
      recipientObs = mw.createNewRecipient(recipient);
    }
    let upsertedRecipient: Recipient;
    return recipientObs.pipe(
      concatMap(
        msg => {
          upsertedRecipient = msg;
          return this.refreshRecipients();
        }
      )
    ).pipe(
      map(
        msg => {
          return upsertedRecipient;
        }
      )
    );
  }

  deleteRecipientWithIdentifier(identifier: string) {
    return new RecipientsMiddleware(this.http).deleteRecipientWithIdentifier(identifier).pipe(
      concatMap(
        msg => {
          return this.refreshRecipients();
        }
      )
    );
  }

  confirmWayToNotifiyRecipientWithIdentifier(identifier: string, type: Recipient.Confirmation.Type.mail|Recipient.Confirmation.Type.sms) {
    return new RecipientsMiddleware(this.http).confirmRecipientWithIdentifier(identifier, type).pipe(
      tap(resp => {
        this.updateRecipientConfirmation(identifier, true, type);
        this.updateUnconfirmedRecipients(this.recipients$.value);
      })
    );
  }

  unconfirmWayToNotifiyRecipientWithIdentifier(identifier: string, type: Recipient.Confirmation.Type.mail|Recipient.Confirmation.Type.sms) {
    return new RecipientsMiddleware(this.http).unconfirmRecipientWithIdentifier(identifier, type).pipe(
      tap(resp => {
        this.updateRecipientConfirmation(identifier, false, type);
        this.updateUnconfirmedRecipients(this.recipients$.value);
      })
    );
  }

  sendTestAlarmToRecipientWithIdentifier(identifier: string) {
    return new RecipientsMiddleware(this.http).sendTestAlarmToRecipientWithIdentifier(identifier);
  }

  // tslint:disable-next-line:max-line-length
  private updateRecipientConfirmation(recipientIdentifier: string, confirmed: boolean, type: Recipient.Confirmation.Type.mail|Recipient.Confirmation.Type.sms) {
    const recipients = this.recipients$.value;
    const recipient = recipients.find(r => {
      return r.identifier === recipientIdentifier;
    });
    if (recipient != null) {
      switch (type) {
        case Recipient.Confirmation.Type.mail:
          recipient.mailConfirmed = confirmed;
          break;
        case Recipient.Confirmation.Type.sms:
          recipient.smsConfirmed = confirmed;
          break;
      }
    }
  }

  private updateUnconfirmedRecipients(recipients: Recipient[]) {
    const unconfirmedRecipients = recipients.filter(r => {
      const isConfirmed = Recipient.Confirmation.isFullConfirmedRecipient(r.mail, r.mailConfirmed, r.mobile, r.smsConfirmed);
      return isConfirmed ? false : true;
    });
    this.recipientsUnconfirmed$.next(unconfirmedRecipients);
    this.countRecipientsUnconfirmed = unconfirmedRecipients.length;
  }
}
