<div #dropdownOptionsList="ngbDropdown" ngbDropdown class="d-inline-block" autoClose="outside"
  (openChange)="onOpenChanged($event)" [ngClass]="widthCSS()" (click)="onClickDropdown($event)">
  <div class="input-group" id="dropdownSelectOptions" ngbDropdownToggle>
    <input type="text" class="form-control" placeholder="Select" aria-label="Select option"
      aria-describedby="button-addon2" #inputTextElem [(ngModel)]="inputText" (input)="onInputTextChanged()"
      [disabled]="isDisabled">
    <div class="input-group-append">
      <fa-icon class="input-group-text" [icon]="['fas', 'angle-down']" (click)="onClickAngleDown($event)"></fa-icon>
    </div>
  </div>
  <div ngbDropdownMenu aria-labelledby="dropdownBasic1" [ngClass]="widthCSS()">
    <div class="scrollable">
      <ng-container [ngSwitch]="contentMode">
        <div *ngSwitchCase="ContentMode.normal" class="list-group">
          <!-- Render all selected options -->
          <ng-container *ngFor="let option of selectedOptions; last as isLast">
            <ng-container *ngTemplateOutlet="normalButton; context: {$implicit: option, isSelected: true, isLast: isLast}"></ng-container>
          </ng-container>

          <!-- Render all selectable options -->
          <ng-container *ngFor="let option of filteredSelectableOptions">
            <ng-container *ngTemplateOutlet="normalButton; context: {$implicit: option}"></ng-container>
          </ng-container>
        </div>
        <div *ngSwitchCase="ContentMode.recipients" class="list-group">
          <!-- Render all selected options -->
          <ng-container *ngFor="let option of selectedOptions; last as isLast">
              <ng-container *ngTemplateOutlet="recipientButton; context: {$implicit: option, isSelected: true, isLast: isLast}"></ng-container>
            </ng-container>
          
          <!-- Render all selectable options -->
          <ng-container *ngFor="let option of filteredSelectableOptions">
            <ng-container *ngTemplateOutlet="recipientButton; context: {$implicit: option}"></ng-container>
          </ng-container>

          <!-- Render all unable to notify options -->
          <ng-container *ngFor="let option of filteredUnableToNotifyRecipientsOptions; first as isFirst">
              <ng-container *ngTemplateOutlet="recipientButton; context: {$implicit: option, disabled: true, isFirst: isFirst}"></ng-container>
            </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #normalButton let-option let-isLast="isLast" let-isSelected="isSelected">
  
  <button class="list-group-item list-group-item-action recipient"
    [ngClass]="{'active': isSelected, 'last': isLast}" (click)="onOptionClicked($event, option)" [disabled]="disabled">
    {{ option.name }}
  </button>
</ng-template>

<ng-template #recipientButton let-option let-disabled="disabled" let-isFirst="isFirst" let-isLast="isLast" let-isSelected="isSelected">
  
  <button class="list-group-item list-group-item-action recipient"
    [ngClass]="{'active': isSelected, 'first': isFirst, 'last': isLast}" (click)="onOptionClicked($event, option)" [disabled]="disabled">
    <fa-icon [icon]="['fas', 'sms']" [ngClass]="recipientMobileCSSClass(option)"></fa-icon>
    <fa-icon [icon]="['fas', 'envelope']" [ngClass]="recipientMailCSSClass(option)"></fa-icon>
    {{ option.name }}
  </button>
</ng-template>
