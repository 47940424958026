import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { v1 } from 'uuid';
import { map, tap, concatMap } from 'rxjs/operators';
import { AlarmsMiddleware } from 'services/alarms.middleware';
import { Alarm } from 'services/alarms.model';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var AlarmsService = /** @class */ (function () {
    function AlarmsService(http) {
        this.http = http;
        this.alarmMetaInfo$ = new BehaviorSubject([]);
        this.currentAlarm$ = new BehaviorSubject(undefined);
        this.alarmToBeCreated = undefined;
    }
    AlarmsService.prototype.refreshAlarms = function () {
        var _this = this;
        return new AlarmsMiddleware(this.http).allAlarms().pipe(tap(function (resp) {
            _this.alarmMetaInfo$.next(resp);
        }));
    };
    AlarmsService.prototype.resetAlarms = function () {
        this.alarmMetaInfo$.next([]);
    };
    AlarmsService.prototype.createPlainAlarm = function (name) {
        var alarm = {
            identifier: v1(),
            createdAt: new Date().toISOString(),
            name: name,
            limits: [],
            recipients: []
        };
        // const alarm = this.defaultAlarm();
        return alarm;
    };
    AlarmsService.prototype.createPlainLimit = function () {
        var limit = {
            identifier: v1(),
            rule: {}
        };
        return limit;
    };
    AlarmsService.prototype.createPlainAlarmRecipient = function () {
        var aRecipient = {
            identifier: v1(),
            subscribedToLimits: []
        };
        return aRecipient;
    };
    AlarmsService.prototype.upsertLimit = function (limit, toAlarm) {
        var indexLimit = Alarm.Limit.indexOfLimitForIdentifier(limit.identifier, toAlarm);
        if (indexLimit > -1) {
            toAlarm.limits[indexLimit] = limit;
        }
        else {
            toAlarm.limits.push(limit);
        }
        return toAlarm.limits;
    };
    AlarmsService.prototype.removeLimitWithIdentifier = function (limitId, fromAlarm) {
        var index = fromAlarm.limits.findIndex(function (l) {
            return l.identifier === limitId;
        });
        if (index > -1) {
            fromAlarm.limits.splice(index, 1);
            // remove limit from recipients, where the limit was added
            // fromAlarm.recipients.forEach(r => {
            //   const indexL = r.subscribedToLimits.findIndex(l => {
            //     return l.identifier === limitId;
            //   });
            //   if (indexL > -1) {
            //     r.subscribedToLimits.splice(indexL, 1);
            //   }
            // });
        }
        return fromAlarm.limits;
    };
    AlarmsService.prototype.upsertRecipient = function (recipient, toAlarm) {
        var indexRecipient = Alarm.Recipient.indexOfRecipientForIdentifier(recipient.identifier, toAlarm);
        if (indexRecipient > -1) {
            toAlarm.recipients[indexRecipient] = recipient;
        }
        else {
            toAlarm.recipients.push(recipient);
        }
        return toAlarm.recipients;
    };
    AlarmsService.prototype.removeRecipientWithIdentifier = function (recipientId, fromAlarm) {
        var index = fromAlarm.recipients.findIndex(function (r) {
            return r.identifier === recipientId;
        });
        if (index > -1) {
            fromAlarm.recipients.splice(index, 1);
        }
        return fromAlarm.recipients;
    };
    AlarmsService.prototype.upsertAlarm = function (alarm, duplicateName) {
        var _this = this;
        var exists = this.alarmMetaInfo$.value.findIndex(function (a) {
            return a.identifier === alarm.identifier;
        });
        var mw = new AlarmsMiddleware(this.http);
        var alarmObs;
        if (exists > -1 && duplicateName == null) {
            // update
            alarmObs = mw.updateAlarm(alarm);
        }
        else {
            // add
            alarmObs = mw.createNewAlarm(alarm, duplicateName);
        }
        var upsertedAlarm;
        return alarmObs.pipe(concatMap(function (msg) {
            upsertedAlarm = msg;
            return _this.refreshAlarms();
        })).pipe(map(function (msg) {
            return upsertedAlarm;
        }));
    };
    AlarmsService.prototype.deleteAlarmWithIdentifier = function (identifier) {
        var _this = this;
        return new AlarmsMiddleware(this.http).deleteAlarm(identifier).pipe(concatMap(function (msg) { return _this.refreshAlarms(); }));
    };
    AlarmsService.prototype.alarmWithIdentifier = function (identifier) {
        return new AlarmsMiddleware(this.http).alarm(identifier);
    };
    AlarmsService.ngInjectableDef = i0.defineInjectable({ factory: function AlarmsService_Factory() { return new AlarmsService(i0.inject(i1.HttpClient)); }, token: AlarmsService, providedIn: "root" });
    return AlarmsService;
}());
export { AlarmsService };
