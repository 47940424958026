import { AlarmsService } from 'services/alarms.service';
import { of } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../../services/alarms.service";
var CreateLimitResolve = /** @class */ (function () {
    function CreateLimitResolve(alarms) {
        this.alarms = alarms;
    }
    CreateLimitResolve.prototype.resolve = function (route, state) {
        var limit = this.alarms.createPlainLimit();
        return of(limit);
    };
    CreateLimitResolve.ngInjectableDef = i0.defineInjectable({ factory: function CreateLimitResolve_Factory() { return new CreateLimitResolve(i0.inject(i1.AlarmsService)); }, token: CreateLimitResolve, providedIn: "root" });
    return CreateLimitResolve;
}());
export { CreateLimitResolve };
