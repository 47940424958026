<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title" i18n>Success</h4>
</div>
<div class="modal-body">
  <p class="icon">
    <fa-icon [icon]="['fas', 'smile']" size="4x"></fa-icon>
  </p>
  <ng-container [ngSwitch]="type">
    <p *ngSwitchCase="Type.AlarmCreated" i18n>The alarm was created successfully.</p>
    <p *ngSwitchCase="Type.AlarmRemoved" i18n>The alarm was removed successfully.</p>
    <p *ngSwitchCase="Type.AlarmDuplicated" i18n>The alarm was duplicated successfully.</p>
    <p *ngSwitchCase="Type.AlarmLimitCreated" i18n>The limit was created successfully.</p>
    <p *ngSwitchCase="Type.AlarmLimitUpdated" i18n>The limit has been updated successfully.</p>
    <p *ngSwitchCase="Type.AlarmLimitRemoved" i18n>The limit was removed successfully.</p>
    <div *ngSwitchCase="Type.AlarmRecipientCreated">
      <p i18n>The entries you made, are now saved.</p>
      <p>
        <span i18n>You can now send test alarm from the profile page of the recipient. </span>
        <strong i18n>Only a recipient with confirmed mail and/or telephone number will receive alarms.</strong></p>
    </div>
    <div *ngSwitchCase="Type.AlarmRecipientUpdated">
      <p i18n>The entries you made, are now saved.</p>
      <p>
        <span i18n>You can now send test alarm from the profile page of the recipient. </span>
        <strong i18n>Only a recipient with confirmed mail and/or telephone number will receive alarms.</strong>
      </p>
    </div>
    <p *ngSwitchCase="Type.RecipientCreated" i18n>The recipient was created successfully.</p>
    <p *ngSwitchCase="Type.RecipientUpdated" i18n>The recipient has been updated successfully.</p>
    <p *ngSwitchCase="Type.RecipientRemoved" i18n>The recipient was removed successfully.</p>
  </ng-container>
</div>
<div class="modal-footer one">
  <button type="button" class="btn btn-primary btn-phoenix" (click)="modal.close('Ok click')">Okay.</button>
</div>