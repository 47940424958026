import { AlarmsService } from 'services/alarms.service';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../../services/alarms.service";
var AlarmResolve = /** @class */ (function () {
    function AlarmResolve(alarms) {
        this.alarms = alarms;
    }
    AlarmResolve.prototype.resolve = function (route, state) {
        var _this = this;
        console.log('AlarmResolve');
        var currentAlarm = this.alarms.currentAlarm$ != null ? this.alarms.currentAlarm$.value : undefined;
        if (currentAlarm == null || currentAlarm.identifier !== route.params.id) {
            return this.alarms.alarmWithIdentifier(route.params.id).pipe(map(function (a) {
                _this.alarms.currentAlarm$.next(a);
                return a;
            }));
        }
        return of(currentAlarm);
    };
    AlarmResolve.ngInjectableDef = i0.defineInjectable({ factory: function AlarmResolve_Factory() { return new AlarmResolve(i0.inject(i1.AlarmsService)); }, token: AlarmResolve, providedIn: "root" });
    return AlarmResolve;
}());
export { AlarmResolve };
