import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

import { AlarmsService } from 'services/alarms.service';
import { Alarm } from 'services/alarms.model';
import { AddAlarmStepsBtnGroupComponent } from '@components-general/add-alarm-steps-btn-group/add-alarm-steps-btn-group.component';
import { AlarmConfigurationVisitedStep, SettingsService } from '@services/settings.service';

@Component({
  selector: 'app-add-alarm-step1',
  templateUrl: './add-alarm-step1.component.html',
  styleUrls: ['./add-alarm-step1.component.scss']
})
export class AddAlarmStep1Component implements OnInit {

  @ViewChild('addAlarmForm') addAlarmForm: NgForm;

  Step = AddAlarmStepsBtnGroupComponent.Step;

  alarm: Alarm;

  constructor(private router: Router, private alarms: AlarmsService, private settings: SettingsService) { }

  ngOnInit() {
    this.alarm = this.alarms.alarmToBeCreated;
  }

  onSubmit(form: NgForm) {
    if (form.invalid) {
      return;
    }
    if (this.settings.helperAlarmConfigurationVisitedStep === AlarmConfigurationVisitedStep.Alarm) {
      this.settings.helperAlarmConfigurationVisitedStep = AlarmConfigurationVisitedStep.Limits;
    }
    this.router.navigate(['main', 'add_alarm', 'limits']);
  }

  addAlarmStepsBtnGroupLimitsDisable() {
    if (this.alarm.name == null || this.alarm.name.length <= 0) {
      return true;
    }
    const current = this.settings.helperAlarmConfigurationVisitedStep;
    return current === AlarmConfigurationVisitedStep.Alarm; // || this.addAlarmForm.invalid;
  }

  addAlarmStepsBtnGroupRecipientsDisable() {
    if (this.alarm.name == null || this.alarm.name.length <= 0 || this.alarm.limits == null || this.alarm.limits.length <= 0) {
      return true;
    }
    const current = this.settings.helperAlarmConfigurationVisitedStep;
    return current !== AlarmConfigurationVisitedStep.Recipients; // || this.addAlarmForm.invalid;
  }

}
