export namespace Alarm {
    export interface Limit {
        identifier: string;
        name?: string;
        metricIdentifier?: string;
        notificationText?: string;
        rule: Limit.Rule;
    }

    export interface Recipient {
        identifier: string;
        recipientName?: string;
        subscribedToLimits: Recipient.SubscribedLimit[]; // list of limit identifiers and names
        noticeByMail?: Recipient.Notice.byMail;
        noticeBySMS?: Recipient.Notice.bySMS;
    }
}

export interface Alarm {
    identifier: string;
    name: string;
    limits: Alarm.Limit[];
    recipients: Alarm.Recipient[];
    createdAt: string;
}

export namespace Alarm.Limit {
    export interface Rule {
        operator?: Rule.Operator;
        value?: number;
    }

    export function limitForIdentifier(identifier: string, alarm: Alarm) {
        const limit = alarm.limits.find(l => {
            return l.identifier === identifier;
        });
        return limit;
    }

    export function indexOfLimitForIdentifier(indentifier: string, alarm: Alarm) {
        const index = alarm.limits.findIndex(l => {
            return l.identifier === indentifier;
          });
          return index;
    }

    export function deepCopy(limit: Alarm.Limit) {
        const cLimit = Object.assign({}, limit);
        cLimit.rule = Alarm.Limit.Rule.deepCopy(limit.rule);
        return cLimit;
    }
}

export namespace Alarm.Limit.Rule {
    export enum Operator {
        greater = '>',
        lesser = '<',
        greaterThan = '>=',
        lessThan = '<=',
        equal = '=='
    }

    export function deepCopy(rule: Alarm.Limit.Rule) {
        const cRule = Object.assign({}, rule);
        return cRule;
    }

    export function operatorFromName(name: string) {
        switch (name) {
            case Limit.Rule.Operator.greater: return Limit.Rule.Operator.greater;
            case Limit.Rule.Operator.lesser: return Limit.Rule.Operator.lesser;
            case Limit.Rule.Operator.greaterThan: return Limit.Rule.Operator.greaterThan;
            case Limit.Rule.Operator.lessThan: return Limit.Rule.Operator.lessThan;
            case Limit.Rule.Operator.equal: return Limit.Rule.Operator.equal;
        }
    }

    export function descriptionRule(limit: Alarm.Limit) {
        if (limit.rule != null) {
            return `${limit.rule.operator} ${limit.rule.value}`;
        }
        return '---';
    }

    export const availableOperators = [Limit.Rule.Operator.greaterThan, Limit.Rule.Operator.greater,
    Limit.Rule.Operator.lessThan, Limit.Rule.Operator.lesser, Limit.Rule.Operator.equal];
}

export namespace Alarm.Recipient {

    export enum Notice {
        byMail = 'Mail', bySMS = 'SMS'
    }

    export interface SubscribedLimit {
        identifier: string;
    }

    export function deepCopy(recipient: Alarm.Recipient) {
        const cRecipient = Object.assign({}, recipient);
        const subscribedLimits = recipient.subscribedToLimits.map(l => {
            return Object.assign({}, l);
        });
        cRecipient.subscribedToLimits = subscribedLimits;
        cRecipient.noticeByMail = recipient.noticeByMail;
        cRecipient.noticeBySMS = recipient.noticeBySMS;
        return cRecipient;
    }

    export function recipientByIdentifier(identifier: string, alarm: Alarm) {
        return alarm.recipients.find(r => {
            return r.identifier === identifier;
        });
    }

    export function indexOfRecipientForIdentifier(indentifier: string, alarm: Alarm) {
        const index = alarm.recipients.findIndex(r => {
            return r.identifier === indentifier;
          });
          return index;
    }

    export function descriptionSubscribedToLimits(recipient: Alarm.Recipient, alarm: Alarm) {
        const desc = recipient.subscribedToLimits.map(l => {
            const limit = Alarm.Limit.limitForIdentifier(l.identifier, alarm);
            const name = limit != null && limit.name != null ? limit.name : limit.identifier;
            return name;
        }).join(', ');
        return desc;
    }

    export function descriptionWayToNotice(recipient: Alarm.Recipient) {
        const notices: string[] = [];
        if (recipient.noticeByMail != null) {
            notices.push(recipient.noticeByMail);
        }
        if (recipient.noticeBySMS != null) {
            notices.push(recipient.noticeBySMS);
        }
        const desc = notices.join(', ');
        return desc;
    }
}

export interface AlarmMetaInfo {
    identifier: string;
    name: string;
    createdAt: string;
    numberOfLimits: number;
}
