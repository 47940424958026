<div class="form-group row">
  <div class="col-sm-12">
    <small class="form-text text-muted" i18n>Please note that fields marked with a star (<span class="star">*</span>)
      are required fields.</small>
  </div>
</div>
<div class="form-group form-row align-items-center">
  <label for="inputRecipientName" class="col-sm-2 col-form-label" i18n>Name<span class="star">*</span></label>
  <div class="col-sm-10">
    <input type="text" class="form-control" id="inputRecipientName" name="inputRecipientName"
      #inputRecipientName="ngModel" [(ngModel)]="recipient.name" placeholder="Enter recipient name" required>
    <div
      *ngIf="(inputRecipientName.invalid && !inputRecipientName.pristine) && (inputRecipientName.dirty || inputRecipientName.touched)"
      class="alert alert-danger">
      <div *ngIf="inputRecipientName.errors.required" i18n>Recipient name is required.</div>
    </div>
  </div>
</div>
<ng-container ngModelGroup="waysToNotify" #waysToNotify="ngModelGroup" appRecipientWaysToNotifyValidator>
  <div class="form-group form-row">
    <label for="inputRecipientMail" class="col-sm-2 col-form-label" i18n>Mail address</label>
    <div class="col-sm-5">
      <input type="email" class="form-control" id="inputRecipientMail" name="inputRecipientMail"
        #inputRecipientMail="ngModel" [(ngModel)]="recipient.mail" [ngModelOptions]="{updateOn: 'blur'}"
        (ngModelChange)="mailInputChanged($event)" placeholder="Enter recipient mail address" email="true">
      <div
        *ngIf="(inputRecipientMail.invalid && !inputRecipientMail.pristine) && (inputRecipientMail.dirty || inputRecipientMail.touched)"
        class="alert alert-danger">
        <div *ngIf="inputRecipientMail.errors.email" i18n>Mail address is invalid. Please check again.</div>
      </div>
    </div>
    <div class="col-sm-5">
      <div class="form-check confirmation">
        <input class="form-check-input" type="checkbox" id="mailConfirmed" name="mailConfirmed"
          [(ngModel)]="recipient.mailConfirmed" [disabled]="isDisabledConfirmMail() || inputRecipientMail.invalid">
        <ng-container [ngSwitch]="recipient.mailConfirmed">
          <label *ngSwitchCase="true" class="form-check-label" for="mailConfirmed" i18n>
            Confirmed
          </label>
          <ng-container *ngSwitchCase="false">
            <label class="form-check-label" for="mailConfirmed" i18n>
              Confirmation pending
            </label>
            <sup>
              <fa-icon [icon]="['fas', 'question-circle']" class="fa-button" placement="top"
                [ngbTooltip]="confirmationTip">
              </fa-icon>
            </sup>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="form-group form-row">
    <label for="inputRecipientPhone" class="col-sm-2 col-form-label" i18n>Phone number</label>
    <div class="col-sm-5">
      <ngx-intl-tel-input id="inputRecipientPhone" name="inputRecipientPhone" [cssClass]="'form-control'"
        [preferredCountries]="['de', 'us']" [onlyCountries]="['de', 'us', 'gb']" [enablePlaceholder]="true"
        #inputRecipientPhone="ngModel" [(ngModel)]="mobileInput" [ngModelOptions]="phoneInputModelOptions"
        (ngModelChange)="mobileInputChanged($event)" [enableAutoCountrySelect]="true"></ngx-intl-tel-input>
      <div
        *ngIf="(inputRecipientPhone.invalid && !inputRecipientPhone.pristine) && (inputRecipientPhone.dirty || inputRecipientPhone.touched)"
        class="alert alert-danger">
        <div *ngIf="inputRecipientPhone.errors.validatePhoneNumber.valid === false" i18n>Phone number is invalid. Please
          check again.</div>
      </div>
      <div
        *ngIf="(waysToNotify.invalid && !waysToNotify.pristine) && (waysToNotify.dirty || waysToNotify.touched) && waysToNotify.errors != null"
        class="alert alert-danger">
        <div *ngIf="waysToNotify.errors.waysToNotifiy.valid === false" i18n>
          Either a mail address or a phone number must be given.
        </div>
      </div>
    </div>
    <div class="col-sm-5">
      <div class="form-check confirmation">
        <input class="form-check-input" type="checkbox" id="confirmationPhone" name="confirmationPhone"
          [(ngModel)]="recipient.smsConfirmed" [disabled]="isDisabledConfirmSMS() || inputRecipientPhone.invalid">
        <ng-container [ngSwitch]="recipient.smsConfirmed">
          <label *ngSwitchCase="true" class="form-check-label" for="confirmationPhone" i18n>
            Confirmed
          </label>
          <ng-container *ngSwitchCase="false">
            <label class="form-check-label" for="confirmationPhone" i18n>
              Confirmation pending
            </label>
            <sup>
              <fa-icon [icon]="['fas', 'question-circle']" class="fa-button" placement="top"
                [ngbTooltip]="confirmationTip">
              </fa-icon>
            </sup>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
<!-- <div class="form-group row">
  <div class="col-sm-12">
    <div class="form-check">
      <input type="checkbox" class="form-check-input" id="acceptTermsAndConditions" name="acceptTermsAndConditions"
        #acceptTermsAndConditions="ngModel" [(ngModel)]="termsAndConditionsAccepted" required>
      <label class="form-check-label" for="acceptTermsAndConditions">
        <span i18n>I have read the </span><button class="btn btn-link tac" (click)="openTermsAndConditions()"
          i18n>'Terms and conditions'</button><span> for creating a new user and I agree.</span><br>
        <span i18n>I understand that adding a new user is allowed only with his express permission.</span>
      </label>
    </div>
    <div
      *ngIf="(acceptTermsAndConditions.invalid && !acceptTermsAndConditions.pristine) && (acceptTermsAndConditions.dirty || acceptTermsAndConditions.touched)"
      class="alert alert-danger">
      <div *ngIf="acceptTermsAndConditions.errors.required" i18n>Terms and Conditions must be accepted.</div>
    </div>
  </div>
</div> -->

<ng-template #confirmationTip>
  <div>
    If you confirm the contact possiblity now, you must have previously ensured that the user has agreed to recive the
    messages. You can also confirm the contact option later.
    To do this, simply calll up the user under [Recipients] and edit him or her.
  </div>
</ng-template>