import { Component, OnInit, OnChanges, Input, SimpleChanges, ViewChild, ElementRef, forwardRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

// Custom form control
// see: https://blog.thoughtram.io/angular/2016/07/27/custom-form-controls-in-angular-2.html

@Component({
  selector: 'app-select-options',
  templateUrl: './select-options.component.html',
  styleUrls: ['./select-options.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectOptionsComponent),
      multi: true
    }
  ]
})
export class SelectOptionsComponent implements OnInit, OnChanges, ControlValueAccessor {

  @ViewChild('dropdownOptionsList') dropdownOptionsList: NgbDropdown;
  @ViewChild('filterInput') filterInput: ElementRef;

  @Input() options: SelectOptionsComponent.Option[] = [];
  @Input() allowMultiSelection = false;
  @Input() showFilter = true;
  @Input() widthClass: 'none'|'small-width'|'full-width' = 'none';

  private selectedOptions: SelectOptionsComponent.Option[] = [];

  filteredOptions$ = new BehaviorSubject<SelectOptionsComponent.Option[]>([]);

  filterText = '';
  selectedOptionsDescription = '';
  isDisabled = false;

  constructor() {}

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    const optionsChanged = changes['options'];
    if (optionsChanged != null) {
      this.performOptionsFilter();
    }
    const selectedOptions = changes['selectedOptions'];
    if (selectedOptions != null) {
      this.updateSelectedOptionsDescription();
    }
  }

  onOpenChanged(opened: boolean) {
    if (opened === true && this.showFilter) {
      setTimeout(() => {
        this.filterInput.nativeElement.focus();
      }, 0);
    }
  }

  performOptionsFilter() {
    const filteredOptions = this.options.filter(o => {
      return this.filter_option_by_text(o, this.filterText);
    });
    this.filteredOptions$.next(filteredOptions);
  }

  onOptionClicked(option: SelectOptionsComponent.Option) {

    if (this.allowMultiSelection === false) {
      this.selectedOptions = [option];
    } else {
      const index = this.selectedOptions.findIndex(o => {
        return o.identifier === option.identifier;
      });
      if (index > -1) {
        // option selected => remove
        this.selectedOptions.splice(index, 1);
      } else {
        // select option => add
        this.selectedOptions.push(option);
      }
    }
    this.propagateChange(this.selectedOptions);
    this.updateSelectedOptionsDescription();

    if (this.allowMultiSelection === false) {
      this.dropdownOptionsList.close();
    }
  }

  onToggleDropdown(event: Event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    return false;
  }

  onClickAngleDown(event: Event) {
    if (this.isDisabled) {
      event.stopImmediatePropagation();
    }
  }

  isOptionSelected(option: SelectOptionsComponent.Option) {
    const index = this.selectedOptions.findIndex(o => {
      return o.identifier === option.identifier;
    });
    return index > -1;
  }

  widthCSS() {
    switch (this.widthClass) {
      case 'none': return undefined;
      default: return this.widthClass;
    }
  }

  private filter_option_by_text(option: SelectOptionsComponent.Option, filterText: string) {
    if (filterText.length > 0) {
      const text = filterText.toLocaleLowerCase();
      const name = option.name.toLocaleLowerCase();
      return name.includes(text);
    }
    return true;
  }

  private updateSelectedOptionsDescription() {
    this.selectedOptionsDescription = this.selectedOptions.map(o => {
      return o.name;
    }).join(', ');
  }

  // ControlValueAccessor Implementation

  propagateChange = (_: any) => {};

  writeValue(obj: SelectOptionsComponent.Option[] | null) {
    this.selectedOptions = obj != null ? obj : [];
    this.updateSelectedOptionsDescription();
  }

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any) {}

  setDisabledState?(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

}

export namespace SelectOptionsComponent {
  export interface Option {
    identifier: string;
    name: string;
  }
}
