import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { of } from 'rxjs';

import { RecipientsService } from '@services/recipients.service';
import { Recipient } from '@services/recipients.model';


@Injectable({
    providedIn: 'root'
})
export class RecipientSubscriptionsResolve implements Resolve<Recipient.Subscription[]> {
    constructor(private recipients: RecipientsService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        console.log('RecipientSubscriptionsResolve');
        return this.recipients.subscriptionsWithRecipientIdentifier(route.params.id);
    }
}
