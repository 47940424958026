/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./not-confirmed-information-box.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "./not-confirmed-information-box.component";
import * as i5 from "../../../services/recipients.service";
var styles_NotConfirmedInformationBoxComponent = [i0.styles];
var RenderType_NotConfirmedInformationBoxComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotConfirmedInformationBoxComponent, data: {} });
export { RenderType_NotConfirmedInformationBoxComponent as RenderType_NotConfirmedInformationBoxComponent };
function View_NotConfirmedInformationBoxComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["A recipient does not have a confirmed telephone number and/or mail address."]))], null, null); }
function View_NotConfirmedInformationBoxComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, ["", " recipients have not confirmed telephone number and/or mail addresses."]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.recipients.countRecipientsUnconfirmed; _ck(_v, 0, 0, currVal_0); }); }
export function View_NotConfirmedInformationBoxComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "alert alert-danger"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "span", [], null, null, null, null, null)), i1.ɵdid(3, 16384, null, 0, i2.NgPlural, [i2.NgLocalization], { ngPlural: [0, "ngPlural"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotConfirmedInformationBoxComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgPluralCase, [[8, "=1"], i1.TemplateRef, i1.ViewContainerRef, i2.NgPlural], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotConfirmedInformationBoxComponent_2)), i1.ɵdid(7, 16384, null, 0, i2.NgPluralCase, [[8, "other"], i1.TemplateRef, i1.ViewContainerRef, i2.NgPlural], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["They will not receive alarms."])), (_l()(), i1.ɵeld(11, 0, null, null, 4, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(14, 1), (_l()(), i1.ɵted(-1, null, ["Click here to see the list."]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.recipients.countRecipientsUnconfirmed; _ck(_v, 3, 0, currVal_0); var currVal_3 = _ck(_v, 14, 0, "/main/recipients_created"); _ck(_v, 13, 0, currVal_3); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 13).target; var currVal_2 = i1.ɵnov(_v, 13).href; _ck(_v, 12, 0, currVal_1, currVal_2); }); }
export function View_NotConfirmedInformationBoxComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-not-confirmed-information-box", [], null, null, null, View_NotConfirmedInformationBoxComponent_0, RenderType_NotConfirmedInformationBoxComponent)), i1.ɵdid(1, 114688, null, 0, i4.NotConfirmedInformationBoxComponent, [i5.RecipientsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotConfirmedInformationBoxComponentNgFactory = i1.ɵccf("app-not-confirmed-information-box", i4.NotConfirmedInformationBoxComponent, View_NotConfirmedInformationBoxComponent_Host_0, {}, {}, []);
export { NotConfirmedInformationBoxComponentNgFactory as NotConfirmedInformationBoxComponentNgFactory };
