import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-failed-upsert-remove',
  templateUrl: './failed-upsert-remove.component.html',
  styleUrls: ['./failed-upsert-remove.component.scss']
})
export class FailedUpsertRemoveComponent implements OnInit {

  message: string;

  static openDefaultModal(err: any, modalService: NgbModal) {
    const modal = modalService.open(FailedUpsertRemoveComponent,
      { centered: true, size: 'sm', backdrop: 'static', keyboard: false});
    if (modal.componentInstance instanceof FailedUpsertRemoveComponent) {
      if (err.message != null) {
        modal.componentInstance.message = err.message;
      } else {
        modal.componentInstance.message = JSON.stringify(err);
      }
    }
    return modal;
  }

  constructor(public modal: NgbActiveModal) { }

  ngOnInit() {
  }

}
