<div class="content-heading">
  <nav aria-label="Alarm configuration label">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <ng-container [ngSwitch]="mode">
            <a *ngSwitchCase="Mode.Alarms" [routerLink]="['/']" i18n>Alarms</a>
            <a *ngSwitchCase="Mode.RecipientsAlreadyCreated" [routerLink]="['/main/recipients_created']" i18n>Recipients</a>
            <a *ngSwitchCase="Mode.AlarmsSent" [routerLink]="['/main/alarms_sent']" i18n>Alarms sent</a>
        </ng-container>
      </li>
      <li class="breadcrumb-item" *ngFor="let entry of middleEntries">
        <a [routerLink]="entry.routerLinkValue">{{entry.name}}</a>
      </li>
      <li class="breadcrumb-item" aria-current="page">
        {{lastPathName}}
      </li>
    </ol>
  </nav>
</div>