import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AlarmsService } from 'services/alarms.service';
import { Alarm } from 'services/alarms.model';
import { of } from 'rxjs';

@Injectable({
    providedIn: 'root'
  })
export class CreateRecipientResolve implements Resolve<Alarm.Recipient> {
    constructor(private alarms: AlarmsService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const limit = this.alarms.createPlainAlarmRecipient();
        return of(limit);
    }
}
