import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { environment } from 'environments/environment';
import { AlarmsService } from 'services/alarms.service';
import { MetricsService } from 'services/metrics.service';
import { RecipientsService } from 'services/recipients.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./alarms.service";
import * as i3 from "./metrics.service";
import * as i4 from "./recipients.service";
var AuthService = /** @class */ (function () {
    function AuthService(http, alarms, metrics, recipients) {
        this.http = http;
        this.alarms = alarms;
        this.metrics = metrics;
        this.recipients = recipients;
        this.isAuthenticated = false;
    }
    AuthService.prototype.logout = function () {
        var _this = this;
        var url = environment.endpoints.logout;
        var obs = this.http.get(url, { withCredentials: true }).pipe(map(function (resp) {
            _this.isAuthenticated = false;
            _this.resetDataAfterLogout();
            return true;
        }), catchError(function (error) {
            console.error('logout failed', error);
            _this.isAuthenticated = false;
            return throwError(error);
        }));
        return obs;
    };
    AuthService.prototype.verifyLoginState = function () {
        var _this = this;
        this.resetDataAfterLogout();
        var url = environment.endpoints.verifyLogin;
        var obs = this.http.get(url, { withCredentials: true }).pipe(map(function (resp) {
            _this.isAuthenticated = true;
            return resp;
        }), catchError(function (error) {
            // do additional error handling if needed
            console.error('verifyLoginState error:', error);
            _this.isAuthenticated = false;
            return throwError(error);
        }));
        return obs;
    };
    AuthService.prototype.initiateSSO = function () {
        var url = environment.endpoints.ssoLogin;
        var obs = this.http.get(url, { withCredentials: true })
            .pipe(map(function (resp) {
            return resp['redirect_to'];
        }), catchError(function (error) {
            console.error('initiateSSO failed', error);
            return throwError(error);
        }));
        return obs;
    };
    AuthService.prototype.resetDataAfterLogout = function () {
        this.alarms.resetAlarms();
        this.metrics.resetMetrics();
        this.recipients.resetRecipients();
    };
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.inject(i1.HttpClient), i0.inject(i2.AlarmsService), i0.inject(i3.MetricsService), i0.inject(i4.RecipientsService)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
