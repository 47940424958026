<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title" i18n>You did not create any recipients</h4>
  </div>
  <div class="modal-body">
    <p class="icon">
      <fa-icon [icon]="['fas', 'info-circle']" size="4x"></fa-icon>
    </p>
    <p i18n>
      The alarm you are creating does not have a target recipient.<br>
      You can create recipients and add them to this alarm.
    </p>
  </div>
  <div class="modal-footer one">
    <button type="button" class="btn btn-primary btn-phoenix" (click)="modal.close('Ok click')" i18n>Okay</button>
  </div>