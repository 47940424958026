/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./recipients-table.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i3 from "@fortawesome/angular-fontawesome";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@angular/common";
import * as i6 from "./recipients-table.component";
var styles_RecipientsTableComponent = [i0.styles];
var RenderType_RecipientsTableComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RecipientsTableComponent, data: {} });
export { RenderType_RecipientsTableComponent as RenderType_RecipientsTableComponent };
function View_RecipientsTableComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "btn btn-light btn-sm"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onEditRecipient(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "fa-icon", [["class", "ng-fa-icon"]], [[8, "innerHTML", 1]], null, null, i2.View_FaIconComponent_0, i2.RenderType_FaIconComponent)), i1.ɵdid(2, 573440, null, 0, i3.FaIconComponent, [i4.DomSanitizer, i3.FaIconService], { iconProp: [0, "iconProp"] }, null), i1.ɵpad(3, 2)], function (_ck, _v) { var currVal_1 = _ck(_v, 3, 0, "fas", "edit"); _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).renderedIconHTML; _ck(_v, 1, 0, currVal_0); }); }
function View_RecipientsTableComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [["class", "d-table-cell align-middle"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "td", [["class", "d-none d-md-table-cell align-middle"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "td", [["class", "d-table-cell align-middle"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 7, "td", [["class", "d-table-cell align-middle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 6, "div", [["class", "float-right"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RecipientsTableComponent_2)), i1.ɵdid(10, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 3, "button", [["class", "btn btn-light btn-sm"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onRemoveRecipient(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "fa-icon", [["class", "ng-fa-icon"]], [[8, "innerHTML", 1]], null, null, i2.View_FaIconComponent_0, i2.RenderType_FaIconComponent)), i1.ɵdid(13, 573440, null, 0, i3.FaIconComponent, [i4.DomSanitizer, i3.FaIconService], { iconProp: [0, "iconProp"] }, null), i1.ɵpad(14, 2)], function (_ck, _v) { var _co = _v.component; var currVal_3 = (_co.showEdit === true); _ck(_v, 10, 0, currVal_3); var currVal_6 = _ck(_v, 14, 0, "fas", "trash-alt"); _ck(_v, 13, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.recipientName; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.wayToNotice; _ck(_v, 4, 0, currVal_1); var currVal_2 = _v.context.$implicit.subscribedTo; _ck(_v, 6, 0, currVal_2); var currVal_4 = _co.removeRecipientsDisabled; _ck(_v, 11, 0, currVal_4); var currVal_5 = i1.ɵnov(_v, 13).renderedIconHTML; _ck(_v, 12, 0, currVal_5); }); }
export function View_RecipientsTableComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "table-responsive"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 13, "table", [["class", "table"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 8, "thead", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 7, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "th", [["class", "d-table-cell"], ["scope", "col"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Recipients name"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "th", [["class", "d-none d-md-table-cell"], ["scope", "col"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Way to notice"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "th", [["class", "d-table-cell"], ["scope", "col"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Subscriptions"])), (_l()(), i1.ɵeld(10, 0, null, null, 0, "th", [["class", "d-table-cell"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 3, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_RecipientsTableComponent_1)), i1.ɵdid(13, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform(_co.recipients$)); _ck(_v, 13, 0, currVal_0); }, null); }
export function View_RecipientsTableComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-recipients-table", [], null, null, null, View_RecipientsTableComponent_0, RenderType_RecipientsTableComponent)), i1.ɵdid(1, 114688, null, 0, i6.RecipientsTableComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RecipientsTableComponentNgFactory = i1.ɵccf("app-recipients-table", i6.RecipientsTableComponent, View_RecipientsTableComponent_Host_0, { alarm: "alarm", showEdit: "showEdit", recipients$: "recipients$", removeRecipientsDisabled: "removeRecipientsDisabled" }, { editRecipient: "editRecipient", removeRecipient: "removeRecipient" }, []);
export { RecipientsTableComponentNgFactory as RecipientsTableComponentNgFactory };
