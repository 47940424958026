import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { AlarmsService } from 'services/alarms.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigureAlarmGuard implements CanActivate {

  constructor(private alarms: AlarmsService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      console.log('ConfigureAlarmGuard');
      if (this.alarms.alarmToBeCreated == null) {
        this.router.navigate(['/main', 'add_alarm']);
        return false;
      }
    return true;
  }
}
