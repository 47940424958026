import { Component, OnInit } from '@angular/core';

import { NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-warning-remove',
  templateUrl: './warning-remove.component.html',
  styleUrls: ['./warning-remove.component.scss']
})
export class WarningRemoveComponent implements OnInit {

  Type = WarningRemoveComponent.Type;

  type: WarningRemoveComponent.Type;
  name: string;
  closeResult: string;

  constructor(public modal: NgbActiveModal) { }

  ngOnInit() {
  }

}

export namespace WarningRemoveComponent {
  export enum Type {
    Alarm, RecipientFromAlarm, LimitFromAlarm, Recipient
  }
}
