<div #dropdownOptionsList="ngbDropdown" ngbDropdown class="d-inline-block" autoClose="outside" (openChange)="onOpenChanged($event)" [ngClass]="widthCSS()">
  <div class="input-group" id="dropdownSelectOptions" ngbDropdownToggle>
    <input type="text" class="form-control" placeholder="Select" aria-label="Select option"
      aria-describedby="button-addon2" [(ngModel)]="selectedOptionsDescription" [disabled]="isDisabled" readonly>
    <div class="input-group-append">
      <fa-icon class="input-group-text" [icon]="['fas', 'angle-down']" (click)="onClickAngleDown($event)"></fa-icon>
    </div>
  </div>
  <div ngbDropdownMenu aria-labelledby="dropdownSelectOptions" [ngClass]="widthCSS()">
    <div *ngIf="showFilter">
      <div class="form-group">
        <input type="text" class="form-control" placeholder="Filter ..." [(ngModel)]="filterText" (input)="performOptionsFilter()"
          #filterInput>
      </div>
    </div>
    <div class="scrollable">
      <div class="list-group">
        <button type="button" *ngFor="let option of filteredOptions$ | async" class="list-group-item list-group-item-action"
          [ngClass]="{'active': isOptionSelected(option)}" (click)="onOptionClicked(option)">{{ option.name }}</button>
      </div>
    </div>
  </div>
</div>