import { Directive } from '@angular/core';
import { Validator, FormControl, ValidationErrors, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[appLimitNameValidator]',
  providers: [{provide: NG_VALIDATORS, useExisting: LimitNameValidatorDirective, multi: true}]
})
export class LimitNameValidatorDirective implements Validator {

  constructor() { }

  static validateLimitName(control: FormControl): ValidationErrors | null {
    if (control.value == null || control.value.length < 1) {
      return {
        'limitName': { value: control.value }
      };
    }
    return null;
  }

  validate(c: FormControl): ValidationErrors | null {
    return LimitNameValidatorDirective.validateLimitName(c);
  }

}
