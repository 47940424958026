import { AlertsService } from 'services/alerts.service';
import { tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../../services/alerts.service";
var AlertsResolve = /** @class */ (function () {
    function AlertsResolve(alerts) {
        this.alerts = alerts;
    }
    AlertsResolve.prototype.resolve = function (route, state) {
        console.log('AlertsResolve');
        return this.alerts.allAlerts().pipe(tap(function (msg) {
            // console.log('AlertsResolve msg', msg);
        }));
    };
    AlertsResolve.ngInjectableDef = i0.defineInjectable({ factory: function AlertsResolve_Factory() { return new AlertsResolve(i0.inject(i1.AlertsService)); }, token: AlertsResolve, providedIn: "root" });
    return AlertsResolve;
}());
export { AlertsResolve };
