import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { Metric } from 'services/metrics.service';

namespace MetricsMiddleware {
    export interface MetricsResponse {
        metrics: string[];
    }
}

export class MetricsMiddleware {
    constructor(private http: HttpClient) {}

    refreshMetrics() {
        return this.http.get<MetricsMiddleware.MetricsResponse>(environment.endpoints.metrics, {withCredentials: true}).pipe(
            map(resp => this.convertMetricsResponse(resp))
        );
    }

    metricsValue(metricNames: string[]) {
        const body = {
            metrics: metricNames
        };
        const url = environment.endpoints.metricsValues;
        return this.http.post<{[metricName: string]: number|null}>(url, body, { withCredentials: true});
    }

    private convertMetricsResponse(response: MetricsMiddleware.MetricsResponse) {
        const metrics = response.metrics.map(m => {
            const metric: Metric = {
                identifier: m,
                name: m
            };
            return metric;
        });
        return metrics;
    }
}
