import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { Alarm, AlarmMetaInfo } from 'services/alarms.model';

namespace AlarmsMiddleware.Request {
    export interface Recipient {
        recipient_id: number;
        mail: boolean;
        sms: boolean;
    }

    export interface Limit {
        name: string;
        metric: string;
        comparator: string;
        value: number;
        notification_text: string;
        limit_subscriptions_attributes: AlarmsMiddleware.Request.Recipient[];
    }

    export interface UpsertAlarm {
        alarm: {
            name: string,
            limits_attributes: AlarmsMiddleware.Request.Limit[]
        };
    }
}

namespace AlarmsMiddleware.Response {
    export interface Recipient {
        id: number;
        name: string;
        mail: boolean;
        sms: boolean;
    }

    export interface Limit {
        id: number;
        name: string;
        metric: string;
        comparator: string;
        value: number;
        notification_text: string;
        recipients: AlarmsMiddleware.Response.Recipient[];
    }

    export interface BaseAlarm {
        id: number;
        name: string;
        created_at: string;
        limits: Limit[];
    }

    export interface GetAlarm {
        id: number;
        name: string;
        created_at: string;
        number_of_limits: number;
    }

    export interface GetAlarms {
        alarms: GetAlarm[];
    }
}

export class AlarmsMiddleware {

    constructor(private http: HttpClient) { }

    allAlarms() {
        return this.http.get<AlarmsMiddleware.Response.GetAlarms>(environment.endpoints.alarms, { withCredentials: true }).pipe(
            map(resp => {
                const alarms = resp.alarms;
                if (alarms != null) {
                    const metaInfos = alarms.map(info => {
                        const metaInfo: AlarmMetaInfo = {
                            identifier: `${info.id}`,
                            name: info.name,
                            createdAt: info.created_at,
                            numberOfLimits: info.number_of_limits
                        };
                        return metaInfo;
                    });
                    return metaInfos;
                }
            })
        );
    }

    alarm(identifier: string) {
        const url = `${environment.endpoints.alarms}/${identifier}`;
        return this.http.get<AlarmsMiddleware.Response.BaseAlarm>(url, { withCredentials: true }).pipe(
            map(resp => {
                return this.baseAlarmResponseToAlarm(resp);
            })
        );
    }

    deleteAlarm(identifier: string) {
        const url = `${environment.endpoints.alarms}/${identifier}`;
        return this.http.delete(url, { withCredentials: true });
    }

    createNewAlarm(alarm: Alarm, duplicateName?: string) {
        const body = this.alarmRequestFromAlarm(alarm, duplicateName);
        return this.http.post<AlarmsMiddleware.Response.BaseAlarm>(environment.endpoints.alarms, body, { withCredentials: true }).pipe(
            map(resp => {
                const createdAlarm = this.baseAlarmResponseToAlarm(resp);
                return createdAlarm;
            })
        );
    }

    updateAlarm(alarm: Alarm) {
        console.log('updateAlarm-alarm', alarm);
        const body = this.alarmRequestFromAlarm(alarm);
        console.log('updateAlarm-body', body);
        const url = `${environment.endpoints.alarms}/${alarm.identifier}`;
        return this.http.put<AlarmsMiddleware.Response.BaseAlarm>(url, body, { withCredentials: true }).pipe(
            map(resp => {
                const createdAlarm = this.baseAlarmResponseToAlarm(resp);
                return createdAlarm;
            })
        );
    }

    private alarmRequestFromAlarm(alarm: Alarm, duplicateName?: string) {
        const alarmRequest: AlarmsMiddleware.Request.UpsertAlarm = {
            alarm: {
                name: duplicateName != null ? duplicateName : alarm.name,
                limits_attributes: null
            }
        };
        alarmRequest.alarm.limits_attributes = alarm.limits.map(l => {
            const subscribedRecipients = alarm.recipients.filter(r => {
                return r.subscribedToLimits.find(sL => {
                    return sL.identifier === l.identifier;
                });
            }).map(r => {
                return {
                    recipient_id: +r.identifier,
                    mail: r.noticeByMail != null,
                    sms: r.noticeBySMS != null
                };
            });

            return {
                name: l.name,
                identifier: l.identifier,
                metric: l.metricIdentifier,
                comparator: l.rule.operator,
                value: +l.rule.value,
                notification_text: l.notificationText,
                limit_subscriptions_attributes: subscribedRecipients
            };
        });
        return alarmRequest;
    }

    private baseAlarmResponseToAlarm(response: AlarmsMiddleware.Response.BaseAlarm) {

        const limits: Alarm.Limit[] = [];
        const recipients: Alarm.Recipient[] = [];
        response.limits.forEach(l => {
            const rule: Alarm.Limit.Rule = {
                operator: Alarm.Limit.Rule.operatorFromName(l.comparator),
                value: l.value
            };
            const limit: Alarm.Limit = {
                identifier: `${l.id}`,
                name: l.name,
                rule: rule,
                metricIdentifier: l.metric,
                notificationText: l.notification_text
            };
            limits.push(limit);

            l.recipients.forEach(r => {
                const existingRecipient = recipients.find(rec => {
                    return rec.identifier === `${r.id}`;
                });
                if (existingRecipient != null) {
                    // update subscribed limits
                    existingRecipient.subscribedToLimits.push({ identifier: limit.identifier });
                } else {
                    // add new recipient
                    const recipient: Alarm.Recipient = {
                        identifier: `${r.id}`,
                        subscribedToLimits: [ { identifier: limit.identifier }],
                        recipientName: r.name,
                        noticeByMail: r.mail ? Alarm.Recipient.Notice.byMail : undefined,
                        noticeBySMS: r.sms ? Alarm.Recipient.Notice.bySMS : undefined
                    };
                    recipients.push(recipient);
                }
            });
        });

        const alarm: Alarm = {
            identifier: `${response.id}`,
            name: response.name,
            limits: limits,
            recipients: recipients,
            createdAt: response.created_at
        };
        return alarm;
    }
}
