<form #newRecipientsForm="ngForm" (ngSubmit)="onSubmit(newRecipientsForm)" class="clearfix">
  <div class="content-main">
    <app-add-alarm-heading [lastPathName]="alarmHeadingLastPath" [middleEntries]="alarmHeadingMiddleEntries"></app-add-alarm-heading>
    <div class="content-body">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 body-head">
            <div class="float-md-left content-padding subtitle">
              <h5>{{ alarm?.name }}</h5>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <h3 class="content-title" i18n>Recipients</h3>
            <div class="content-padding" *ngIf="alarm">
              <app-upsert-alarm-recipient-form-container (recipientChanged)="onRecipientChanged($event)"
                [alarm]="alarm" [recipient]="recipient" [inViewerMode]="inViewerMode" [selectRecipientDisabled]="editRecipient">
              </app-upsert-alarm-recipient-form-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="btn-toolbar float-right buttons-outside-content" role="toolbar" aria-label="Toolbar with button groups">
    <!-- <div class="btn-group mr-2" role="group" aria-label="First group">
      <button class="btn btn-primary btn-phoenix float-right" type="button" (click)="onCancel()" i18n>Cancel</button>
    </div> -->

    <div class="btn-group" role="group" aria-label="Second group" *ngIf="inViewerMode; else editMode">
      <button class="btn btn-primary btn-phoenix float-right" type="button" (click)="onEdit()" i18n>Edit</button>
    </div>
    <ng-template #editMode>
      <div class="btn-group" role="group" aria-label="Second group">
        <button class="btn btn-primary btn-phoenix float-right" type="submit" [disabled]="!newRecipientsForm.form.valid || saveInProgress"
          i18n>Save</button>
      </div>
    </ng-template>
  </div>
</form>
<span [hidden]="true" #addRecipientText i18n>Add recipient</span>
<span [hidden]="true" #editRecipientText i18n>Edit recipient</span>