import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Subscription } from 'rxjs';

import { AddAlarmHeading } from '@components-general/add-alarm-heading/add-alarm-heading.component';
import { Recipient } from '@services/recipients.model';
import { RecipientsService } from '@services/recipients.service';
import { SuccessUpsertRemoveComponent } from '@components-modal/success-upsert-remove/success-upsert-remove.component';
import { FailedUpsertRemoveComponent } from '@components-modal/failed-upsert-remove/failed-upsert-remove.component';
import { SettingsService, AlarmingMenuEntry } from '@services/settings.service';

@Component({
  selector: 'app-recipient-upsert',
  templateUrl: './recipient-upsert.component.html',
  styleUrls: ['./recipient-upsert.component.scss']
})
export class RecipientUpsertComponent implements OnInit, OnDestroy {

  HeadingMode = AddAlarmHeading.Mode;

  inAddRecipientMode = false;

  Mode = RecipientUpsertComponent.Mode;
  mode = RecipientUpsertComponent.Mode.add;

  termsAndConditionsAccepted = true;

  recipient: Recipient;
  saveInProgress = false;

  private recipientSubscription: Subscription;

  constructor(
    private modalService: NgbModal, private router: Router,
    private route: ActivatedRoute, private recipients: RecipientsService,
    private settings: SettingsService) {
      this.settings.currentAlarmEntry$.next(AlarmingMenuEntry.RecipientsCreated);
    }

  ngOnInit() {
    this.recipientSubscription = this.route.data.subscribe(data => {
      if (data.recipient != null) {
        this.recipient = data.recipient;
        this.mode = RecipientUpsertComponent.Mode.edit;
        // this.termsAndConditionsAccepted = true;
      } else {
        this.recipient = { identifier: undefined, name: '', mail: '', mobile: '', smsConfirmed: false, mailConfirmed: false};
        this.mode = RecipientUpsertComponent.Mode.add;
      }
    });
  }

  ngOnDestroy() {
    if (this.recipientSubscription != null) {
      this.recipientSubscription.unsubscribe();
    }
  }

  onSubmit(form: NgForm) {
    if (form.invalid) {
      return;
    }

    this.saveInProgress = true;
    const recipientToSave = Recipient.deepCopy(this.recipient);
    this.recipients.upsertRecipient(recipientToSave).subscribe(
      resp => {
        switch (this.mode) {
          case RecipientUpsertComponent.Mode.add:
            this.router.navigate(['main', 'recipients_created', resp.identifier]);
            break;
          case RecipientUpsertComponent.Mode.edit:
            this.router.navigate(['main', 'recipients_created', recipientToSave.identifier]);
            break;
        }
        // tslint:disable-next-line:max-line-length
        const type = this.mode === RecipientUpsertComponent.Mode.add ? SuccessUpsertRemoveComponent.Type.RecipientCreated : SuccessUpsertRemoveComponent.Type.RecipientUpdated;
        SuccessUpsertRemoveComponent.openDefaultModal(type, this.modalService);
      },
      err => {
        console.error('save recipient failed', err);
        FailedUpsertRemoveComponent.openDefaultModal(err, this.modalService);
        this.saveInProgress = false;
      },
      () => {
        this.saveInProgress = false;
      }
    );
  }
}


export namespace RecipientUpsertComponent {
  export enum Mode {
    add, edit
  }
}
