import { map } from 'rxjs/operators';
import { AlertsService } from 'services/alerts.service';
import * as i0 from "@angular/core";
import * as i1 from "../../services/alerts.service";
var AlertResolve = /** @class */ (function () {
    function AlertResolve(alerts) {
        this.alerts = alerts;
    }
    AlertResolve.prototype.resolve = function (route, state) {
        console.log('AlertResolve');
        var alert = this.alerts.alert(route.params.id);
        if (alert == null) {
            var alertObs = this.alerts.allAlerts().pipe(map(function (alerts) { return alerts.find(function (a) { return a.identifier === route.params.id; }); }));
            return alertObs;
        }
        return this.alerts.alert(route.params.id);
    };
    AlertResolve.ngInjectableDef = i0.defineInjectable({ factory: function AlertResolve_Factory() { return new AlertResolve(i0.inject(i1.AlertsService)); }, token: AlertResolve, providedIn: "root" });
    return AlertResolve;
}());
export { AlertResolve };
