/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./terms-and-conditions.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./terms-and-conditions.component";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
var styles_TermsAndConditionsComponent = [i0.styles];
var RenderType_TermsAndConditionsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TermsAndConditionsComponent, data: {} });
export { RenderType_TermsAndConditionsComponent as RenderType_TermsAndConditionsComponent };
export function View_TermsAndConditionsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title"], ["id", "modal-basic-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Terms and conditions"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. "])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "modal-footer one"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "button", [["class", "btn btn-primary btn-phoenix"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.modal.close("Ok click") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Okay"]))], null, null); }
export function View_TermsAndConditionsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-terms-and-conditions", [], null, null, null, View_TermsAndConditionsComponent_0, RenderType_TermsAndConditionsComponent)), i1.ɵdid(1, 114688, null, 0, i2.TermsAndConditionsComponent, [i3.NgbActiveModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TermsAndConditionsComponentNgFactory = i1.ɵccf("app-terms-and-conditions", i2.TermsAndConditionsComponent, View_TermsAndConditionsComponent_Host_0, {}, {}, []);
export { TermsAndConditionsComponentNgFactory as TermsAndConditionsComponentNgFactory };
