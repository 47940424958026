<router-outlet></router-outlet>
<div class="container">
    <div class="row">
        <footer class="footer">
            <div class="col-md-12">
                <hr>
                <ul>
                    <li><span>TSD Alarming</span><span>{{ appVersion }}.{{ revision }}</span></li>
                    <!-- <li><a href="https://api.tsd-alarmierung.sic-software.tk/v1/login?redirect_url={{ssoRedirectUrl}}">Call
                            login</a></li>
                    <li><a href="https://api.tsd-alarmierung.sic-software.tk/v1/test">Call
                        login</a></li> -->
                </ul>
            </div>
        </footer>
    </div>
</div>