import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { SettingsService, AlarmingMenuEntry } from 'services/settings.service';
import { Alert } from 'services/alerts.model';

export interface AlarmsAlreadySentTableEntry {
  identifier: string;
  occuredDate: Date;
  alarmName: string;
  limitName: string;
  recipientName: string;
  notifiedBy: string;
}

@Component({
  selector: 'app-alarms-already-sent',
  templateUrl: './alarms-already-sent.component.html',
  styleUrls: ['./alarms-already-sent.component.scss']
})
export class AlarmsAlreadySentComponent implements OnInit, OnDestroy {

  currentPage = 0;
  numberOfAlarms = 0;

  searchText: string;

  shownEntries: AlarmsAlreadySentTableEntry[] = [];
  private allEntries: AlarmsAlreadySentTableEntry[] = [];

  private dataSubscription: Subscription;
  private settingsSubscription: Subscription;

  constructor(private route: ActivatedRoute, private router: Router, private settings: SettingsService) {
    this.settings.currentAlarmEntry$.next(AlarmingMenuEntry.AlarmsSent);
  }

  ngOnInit() {

    this.settingsSubscription = this.settings.showEntriesPerPage$.subscribe(entriesPerPage => {
      this.updateShownEntries(true);
    });

    this.dataSubscription = this.route.data.subscribe(data => {
      this.allEntries = data.alerts.map((alert: Alert) => {
        const entry: AlarmsAlreadySentTableEntry = {
          identifier: alert.identifier,
          occuredDate: alert.occurredAt,
          alarmName: alert.alarmName,
          limitName: alert.limitName,
          recipientName: alert.recipientName,
          notifiedBy: alert.recipientNotifiedBy
        };
        return entry;
      }).sort((e1: AlarmsAlreadySentTableEntry, e2: AlarmsAlreadySentTableEntry) => {
        const date1 = e1.occuredDate.getTime();
        const date2 = e2.occuredDate.getTime();
        if (date1 > date2) {
          return -1;
        }
        if (date1 < date2) {
          return 1;
        }
        return 0;
      });
    });

    this.updateShownEntries();
  }

  ngOnDestroy() {
    if (this.dataSubscription != null) {
      this.dataSubscription.unsubscribe();
      this.dataSubscription = null;
    }
    if (this.settingsSubscription != null) {
      this.settingsSubscription.unsubscribe();
      this.settingsSubscription = null;
    }
  }

  onChangeSearchText(searchText: string) {
    this.searchText = searchText;
    this.updateShownEntries();
  }

  onPageChanged(newPage: number) {
    this.currentPage = newPage;
    this.updateShownEntries();
  }

  onShowAlertDetails(alert: AlarmsAlreadySentTableEntry) {
    this.router.navigate(['main', 'alarms_sent', alert.identifier]);
  }

  private updateShownEntries(resetPage: boolean = false) {
    const totalEntries = this.allEntries.filter(entry => {
      return this.filterByText(entry, this.searchText);
    });
    this.numberOfAlarms = totalEntries.length;
    const entriesPerPage = this.settings.showEntriesPerPage$.value;
    let start: number;
    if (resetPage) {
      this.currentPage = 1;
    }
    start = this.currentPage * entriesPerPage - entriesPerPage;
    start = start < totalEntries.length ? start : 0;
    const end = start + entriesPerPage;
    const clippedEntries = totalEntries.slice(start, end);
    this.shownEntries = clippedEntries;
  }

  private filterByText(alarm: AlarmsAlreadySentTableEntry, text: String) {
    if (text == null || text.length === 0) {
      return true;
    }
    const t = text.toLocaleLowerCase();
    const alarmName = alarm.alarmName.toLocaleLowerCase();
    if (alarmName.includes(t)) {
      return true;
    }
    const limitName = alarm.limitName.toLocaleLowerCase();
    if (limitName.includes(t)) {
      return true;
    }
    if (alarm.recipientName != null) {
      const recipientName = alarm.recipientName.toLocaleLowerCase();
      if (recipientName.includes(t)) {
        return true;
      }
    }
    if (alarm.notifiedBy != null) {
      const notifiedBy = alarm.notifiedBy.toLocaleLowerCase();
      if (notifiedBy.includes(t)) {
        return true;
      }
    }
    return false;
  }

}
