<div class="form-group row">
  <div class="col-sm-12">
    <small class="form-text text-muted" i18n>Please note that fields marked with a star (<span class="star">*</span>)
      are required fields.</small>
  </div>
</div>
<div class="form-group row">
  <label for="inputRecipient" class="col-sm-2 col-form-label" i18n>Name recipient<span class="star">*</span></label>
  <div class="col-sm-10">
    <app-dropdown-select-options id="inputRecipient" name="inputRecipient" #inputRecipient="ngModel"
      [(ngModel)]="selectedRecipientOptions" [options]="availableRecipientOptions" [disabled]="selectRecipientDisabled"
      (ngModelChange)="onSelectedRecipientsModelChanged($event)" [contentMode]="recipientsContentMode" required>
    </app-dropdown-select-options>
    <!-- <button class="btn btn-link" (click)="onReloadMetrics()">
      <fa-icon [icon]="['fas', 'sync']" [spin]="recipientsReloading"></fa-icon>
    </button>
    <small id="inputSelectRecipientHelp" class="text-muted" i18n>
      {{availableRecipientOptions.length || 0}} recipients loaded.
    </small> -->
  </div>
</div>
<fieldset>
  <div class="form-group row">
    <label for="inputSendPer" class="col-sm-2 col-form-label" i18n>Send per<span class="star">*</span></label>
    <div class="col-sm-10">
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" id="inlineCheckbox1" name="inlineCheckbox1"
          [(ngModel)]="smsSelected" [disabled]="smsDisabled()" (ngModelChange)="onSelectedSMSModelChanged($event)">
        <label class="form-check-label" for="inlineCheckbox1" i18n>Sms</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" id="inlineCheckbox2" name="inlineCheckbox2"
          [(ngModel)]="mailSelected" [disabled]="mailDisabled()" (ngModelChange)="onSelectedMailModelChanged($event)">
        <label class="form-check-label" for="inlineCheckbox2" i18n>Email</label>
      </div>
    </div>
  </div>
</fieldset>
<fieldset>
  <div class="form-group row" ngModelGroup="limitSelection" #limitSelection="ngModelGroup"
    appAlarmRecipientLimitsValidator>
    <label for="inputSubscribedTo" class="col-sm-2 col-form-label" i18n>Subscriptions<span class="star">*</span></label>
    <div class="col-sm-10 limit-checkboxes">
      <div *ngFor="let limit of availableLimits" class="form-check">
        <input class="form-check-input" type="checkbox" id="{{limit.identifier}}" name="limit-{{limit.identifier}}"
          [(ngModel)]="limit.selected" (ngModelChange)="onSelectLimitChanged(limit)">
        <label class="form-check-label" for="{{limit.identifier}}">
          {{ limit.name }}
        </label>
      </div>
      <div
        *ngIf="(limitSelection.invalid && !limitSelection.pristine) && (limitSelection.dirty || limitSelection.touched)"
        class="alert alert-danger">
        <div *ngIf="limitSelection.errors?.limitSelected.valid === false" i18n>At least one limit must be subscribed.
        </div>
      </div>
    </div>
  </div>
</fieldset>