import { RecipientsService } from 'services/recipients.service';
import { of } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../../services/recipients.service";
var RecipientsResolve = /** @class */ (function () {
    function RecipientsResolve(recipients) {
        this.recipients = recipients;
    }
    RecipientsResolve.prototype.resolve = function (route, state) {
        console.log('RecipientsResolve');
        var currentRecipients = this.recipients.recipients$.value;
        if (currentRecipients == null || currentRecipients.length === 0) {
            return this.recipients.refreshRecipients();
        }
        return of(currentRecipients);
    };
    RecipientsResolve.ngInjectableDef = i0.defineInjectable({ factory: function RecipientsResolve_Factory() { return new RecipientsResolve(i0.inject(i1.RecipientsService)); }, token: RecipientsResolve, providedIn: "root" });
    return RecipientsResolve;
}());
export { RecipientsResolve };
