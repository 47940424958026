<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="search-container float-right">
        <input type="text" class="form-control search-bar" [(ngModel)]="searchText" (input)="onChangeSearchText()" placeholder="Search">
        <fa-icon [icon]="['fas', 'search']" class="search-icon"></fa-icon>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="left-side">
        <span i18n>Show</span>
        <app-select-options [showFilter]="false" [(ngModel)]="selectedEntriesPerPageOption" [widthClass]="'small-width'"
          [options]="availableEntriesPerPageOptions" (ngModelChange)="onEntriesPerPageModelChanged($event)">
        </app-select-options>
        <span>Entries</span>
      </div>
      <ngb-pagination #pagination [collectionSize]="collectionSize" [(page)]="page" [boundaryLinks]="true" [pageSize]="entriesPerPage" [maxSize]="maxSize"
        [ellipses]="false" class="float-right phoenix-pagination" (pageChange)="onPageChanged($event)"></ngb-pagination>
    </div>
  </div>
</div>