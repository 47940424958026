import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export var EntriesPerPage;
(function (EntriesPerPage) {
    function entryFromText(text) {
        switch (text) {
            case "" + EntriesPerPage.five: return EntriesPerPage.five;
            case "" + EntriesPerPage.ten: return EntriesPerPage.ten;
            case "" + EntriesPerPage.twentyfive: return EntriesPerPage.twentyfive;
            case "" + EntriesPerPage.fifty: return EntriesPerPage.fifty;
        }
    }
    EntriesPerPage.entryFromText = entryFromText;
})(EntriesPerPage || (EntriesPerPage = {}));
(function (EntriesPerPage) {
    EntriesPerPage[EntriesPerPage["five"] = 5] = "five";
    EntriesPerPage[EntriesPerPage["ten"] = 10] = "ten";
    EntriesPerPage[EntriesPerPage["twentyfive"] = 25] = "twentyfive";
    EntriesPerPage[EntriesPerPage["fifty"] = 50] = "fifty";
})(EntriesPerPage || (EntriesPerPage = {}));
export var AlarmingMenuEntry;
(function (AlarmingMenuEntry) {
    AlarmingMenuEntry["Alarms"] = "alarms";
    AlarmingMenuEntry["AlarmsSent"] = "alarms_sent";
    AlarmingMenuEntry["RecipientsCreated"] = "recipients_created";
})(AlarmingMenuEntry || (AlarmingMenuEntry = {}));
export var AlarmConfigurationVisitedStep;
(function (AlarmConfigurationVisitedStep) {
    AlarmConfigurationVisitedStep[AlarmConfigurationVisitedStep["Alarm"] = 0] = "Alarm";
    AlarmConfigurationVisitedStep[AlarmConfigurationVisitedStep["Limits"] = 1] = "Limits";
    AlarmConfigurationVisitedStep[AlarmConfigurationVisitedStep["Recipients"] = 2] = "Recipients";
})(AlarmConfigurationVisitedStep || (AlarmConfigurationVisitedStep = {}));
var SettingsService = /** @class */ (function () {
    function SettingsService() {
        this.helperAlarmConfigurationVisitedStep = AlarmConfigurationVisitedStep.Alarm;
        this.showEntriesPerPage$ = new BehaviorSubject(EntriesPerPage.ten);
        this.currentAlarmEntry$ = new BehaviorSubject(AlarmingMenuEntry.Alarms);
        this.availableEntriesPerPage = [
            EntriesPerPage.five, EntriesPerPage.ten, EntriesPerPage.twentyfive, EntriesPerPage.fifty
        ];
    }
    SettingsService.ngInjectableDef = i0.defineInjectable({ factory: function SettingsService_Factory() { return new SettingsService(); }, token: SettingsService, providedIn: "root" });
    return SettingsService;
}());
export { SettingsService };
