import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { UserMiddleware } from './user.middleware';

export namespace UserService {
  export interface User {
    name: string;
  }
}

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  user() {
    return new UserMiddleware(this.http).user();
  }
}
