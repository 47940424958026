import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { MetricsMiddleware } from 'services/metrics.middleware';

import { tap } from 'rxjs/operators';

export interface Metric {
  identifier: string;
  name: string;
  limit?: number; // current value from proficloud
}

export namespace Metric {
  export function currentValueForMetric(identifier: string, metricsService: MetricsService) {
    const metric = metricsService.metricForIdentifier(identifier);
    if (metric != null) {
      return metric.limit;
    }
    return null;
  }

  export function nameForMetric(identifier: string, metricsService: MetricsService) {
    const metric = metricsService.metricForIdentifier(identifier);
    if (metric != null) {
      return metric.name;
    }
    return null;
  }
}

@Injectable({
  providedIn: 'root'
})
export class MetricsService {

  private static POLLING_INTERVAL = 3000;

  metrics$ = new BehaviorSubject<Metric[]>([]);
  metricsValues$ = new BehaviorSubject<{ [metricName: string]: number | null }>({});

  private metricNamesToPollForValue: string[] = [];

  constructor(private http: HttpClient) {
    this.pollForMetricsValues();
  }

  refreshMetrics() {
    return new MetricsMiddleware(this.http).refreshMetrics().pipe(
      tap(resp => {
        this.metrics$.next(resp);
      })
    );
  }

  resetMetrics() {
    this.metrics$.next([]);
  }

  metricForIdentifier(identifier: string) {
    return this.metrics$.value.find(m => {
      return m.identifier === identifier;
    });
  }

  // poll for metrics valus
  addMetricNameToPollForValue(metricName: string) {
    const alreadyAdded = this.metricNamesToPollForValue.find(n => n === metricName);
    if (alreadyAdded == null && metricName != null) {
      this.metricNamesToPollForValue.push(metricName);
    }
  }

  removeMetricNameToPollForValue(metricName: string) {
    const index = this.metricNamesToPollForValue.findIndex(n => n === metricName);
    if (index > -1) {
      this.metricNamesToPollForValue.splice(index, 1);
    }
  }

  private pollForMetricsValues() {

    const nextPolling = () => {
      setTimeout(() => {
        this.pollForMetricsValues();
      }, MetricsService.POLLING_INTERVAL);
    };

    if (this.metricNamesToPollForValue.length > 0) {
      new MetricsMiddleware(this.http).metricsValue(this.metricNamesToPollForValue).subscribe(res => {
        console.log('pollForMetricsValues values', res);
        this.metricsValues$.next(res);
      }, err => {
        console.error('pollForMetricsValues failed', err);
        nextPolling();
      }, () => {
        nextPolling();
      });
    } else {
      nextPolling();
    }
  }
}
