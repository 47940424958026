/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./unable-delete.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i3 from "@fortawesome/angular-fontawesome";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@angular/common";
import * as i6 from "./unable-delete.component";
import * as i7 from "@ng-bootstrap/ng-bootstrap";
var styles_UnableDeleteComponent = [i0.styles];
var RenderType_UnableDeleteComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UnableDeleteComponent, data: {} });
export { RenderType_UnableDeleteComponent as RenderType_UnableDeleteComponent };
function View_UnableDeleteComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h4", [["class", "modal-title"], ["id", "modal-basic-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Unable to delete limit."])), (_l()(), i1.ɵeld(4, 0, null, null, 9, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "p", [["class", "icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "fa-icon", [["class", "ng-fa-icon"], ["size", "4x"]], [[8, "innerHTML", 1]], null, null, i2.View_FaIconComponent_0, i2.RenderType_FaIconComponent)), i1.ɵdid(7, 573440, null, 0, i3.FaIconComponent, [i4.DomSanitizer, i3.FaIconService], { iconProp: [0, "iconProp"], size: [1, "size"] }, null), i1.ɵpad(8, 2), (_l()(), i1.ɵeld(9, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Recipents are already subscribed to limit. "])), (_l()(), i1.ɵeld(11, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["First, you have to unsubscribe all recipients from that limit."])), (_l()(), i1.ɵeld(14, 0, null, null, 2, "div", [["class", "modal-footer one"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "button", [["class", "btn btn-primary btn-phoenix"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.modal.close("Ok click") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Okay"]))], function (_ck, _v) { var currVal_1 = _ck(_v, 8, 0, "fas", "exclamation-triangle"); var currVal_2 = "4x"; _ck(_v, 7, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 7).renderedIconHTML; _ck(_v, 6, 0, currVal_0); }); }
export function View_UnableDeleteComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i5.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UnableDeleteComponent_1)), i1.ɵdid(3, 278528, null, 0, i5.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i5.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.type; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.Type.LimitReasonRecpientsAddedd; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_UnableDeleteComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-unable-delete", [], null, null, null, View_UnableDeleteComponent_0, RenderType_UnableDeleteComponent)), i1.ɵdid(1, 114688, null, 0, i6.UnableDeleteComponent, [i7.NgbActiveModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UnableDeleteComponentNgFactory = i1.ɵccf("app-unable-delete", i6.UnableDeleteComponent, View_UnableDeleteComponent_Host_0, {}, {}, []);
export { UnableDeleteComponentNgFactory as UnableDeleteComponentNgFactory };
