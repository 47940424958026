import { Component, OnInit, OnChanges, OnDestroy, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';

import { SettingsService, EntriesPerPage } from 'services/settings.service';

@Component({
  selector: 'app-table-interactive-bottom',
  templateUrl: './table-interactive-bottom.component.html',
  styleUrls: ['./table-interactive-bottom.component.scss']
})
export class TableInteractiveBottomComponent implements OnInit, OnChanges, OnDestroy {

  // pagination
  @Input() page = 1;
  @Input() collectionSize = 10;
  @Input() entriesPerPage = EntriesPerPage.five;

  @Output() pageChanged = new EventEmitter<number>();

  maxSize = 3;

  showing1 = 1;
  showing2 = 6;
  showing3 = 10;

  private showEntriesPerPageSubscription: Subscription;

  constructor(private settings: SettingsService) { }

  ngOnInit() {
    this.showEntriesPerPageSubscription = this.settings.showEntriesPerPage$.subscribe(count => {
      this.entriesPerPage = count;
      this.updateShowings();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.updateShowings();
  }

  ngOnDestroy() {
    this.showEntriesPerPageSubscription.unsubscribe();
  }

  onPageChanged(newPage: number) {
    this.pageChanged.emit(newPage);
  }

  private updateShowings() {
    if (this.collectionSize === 0) {
      this.showing1 = this.showing2 = this.showing3 = 0;
    } else {
      this.showing1 = this.page * this.entriesPerPage - this.entriesPerPage + 1;
      this.showing2 = this.showing1 + this.entriesPerPage - 1;
      this.showing2 = this.showing2 < this.collectionSize ? this.showing2 : this.collectionSize;
      this.showing3 = this.collectionSize;
    }
  }

}
