import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AlarmsService } from 'services/alarms.service';
import { Alarm } from 'services/alarms.model';
import { SuccessUpsertRemoveComponent } from '@components-modal/success-upsert-remove/success-upsert-remove.component';
import { FailedUpsertRemoveComponent } from '@components-modal/failed-upsert-remove/failed-upsert-remove.component';

@Component({
  selector: 'app-alarm-bottom-btn-group',
  templateUrl: './alarm-bottom-btn-group.component.html',
  styleUrls: ['./alarm-bottom-btn-group.component.scss']
})
export class AlarmBottomBtnGroupComponent implements OnInit {

  @ViewChild('duplicateText') duplicateText: ElementRef;

  @Input() alarm: Alarm;
  @Input() secondBtnTitle: string;
  @Output() addMore = new EventEmitter<void>();
  @Output() duplicatedAlarm = new EventEmitter<Alarm>();

  duplicateAlarmInProgress = false;

  constructor(private modalService: NgbModal, private alarms: AlarmsService) { }

  ngOnInit() {
  }

  onDuplicateAlarm() {
    const name = `${this.alarm.name} ${this.duplicateText.nativeElement.innerHTML}`;
    this.duplicateAlarmInProgress = true;
    this.alarms.upsertAlarm(this.alarm, name).subscribe(
      msg => {
        this.duplicatedAlarm.emit(msg);
        SuccessUpsertRemoveComponent.openDefaultModal(SuccessUpsertRemoveComponent.Type.AlarmDuplicated, this.modalService);
      },
      err => {
        FailedUpsertRemoveComponent.openDefaultModal(err, this.modalService);
        console.log(err);
        this.duplicateAlarmInProgress = false;
      },
      () => {
        this.duplicateAlarmInProgress = false;
      }
    );
  }

  onAddMore() {
    this.addMore.emit();
  }

}
