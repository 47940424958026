import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { AlertsMiddleware } from './alerts.middleware';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var AlertsService = /** @class */ (function () {
    function AlertsService(http) {
        this.http = http;
        this.alerts = [];
    }
    AlertsService.prototype.allAlerts = function () {
        var _this = this;
        return new AlertsMiddleware(this.http).allAlerts().pipe(tap(function (alerts) {
            _this.alerts = alerts;
        }));
    };
    AlertsService.prototype.alert = function (identifier) {
        var alert = this.alerts.find(function (a) { return a.identifier === identifier; });
        return alert;
    };
    AlertsService.ngInjectableDef = i0.defineInjectable({ factory: function AlertsService_Factory() { return new AlertsService(i0.inject(i1.HttpClient)); }, token: AlertsService, providedIn: "root" });
    return AlertsService;
}());
export { AlertsService };
