import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { SubscriptionMetaInfo } from 'services/subscriptions.model';

namespace SubscriptionsMiddleware.Response {
    export interface Subscription {
        recipient_id: number;
        name: string;
        email: string;
        mobile: string;
        mail_confirmed: boolean;
        sms_confirmed: boolean;
        alarms: number;
        limits: number;
    }
}

export class SubscriptionsMiddleware {
    constructor(private http: HttpClient) {}

    allSubscriptions() {
        const url = environment.endpoints.subscriptions;
        return this.http.get<SubscriptionsMiddleware.Response.Subscription[]>(url, { withCredentials: true}).pipe(
            map(
                resp => {
                    return this.subscriptionsResponseToSubscriptionMetaInfos(resp);
                }
            )
        );
    }

    private subscriptionsResponseToSubscriptionMetaInfos(response: SubscriptionsMiddleware.Response.Subscription[]) {
        const subscriptions: SubscriptionMetaInfo[] = response.map((s: SubscriptionsMiddleware.Response.Subscription) => {
            return {
                recipientIdentifier: `${s.recipient_id}`,
                recipientName: s.name,
                mail: s.email,
                mobile: s.mobile,
                mailConfirmed: s.mail_confirmed,
                smsConfirmed: s.sms_confirmed,
                numberAlarms: s.alarms,
                numberLimits: s.limits
            };
        });
        return subscriptions;
    }
}
