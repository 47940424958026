import { SubscriptionsService } from 'services/subscriptions.service';
import { tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../../services/subscriptions.service";
var SubscriptionsResolve = /** @class */ (function () {
    function SubscriptionsResolve(subscriptions) {
        this.subscriptions = subscriptions;
    }
    SubscriptionsResolve.prototype.resolve = function (route, state) {
        console.log('SubscriptionsResolve');
        return this.subscriptions.allSubscriptions().pipe(tap(function (msg) {
            // console.log('SubscriptionsResolve msg', msg);
        }));
    };
    SubscriptionsResolve.ngInjectableDef = i0.defineInjectable({ factory: function SubscriptionsResolve_Factory() { return new SubscriptionsResolve(i0.inject(i1.SubscriptionsService)); }, token: SubscriptionsResolve, providedIn: "root" });
    return SubscriptionsResolve;
}());
export { SubscriptionsResolve };
