<form #newLimitsForm="ngForm" (ngSubmit)="onSubmit(newLimitsForm)" class="clearfix">
  <div class="content-main">
    <app-add-alarm-heading [lastPathName]="'New alarm'"></app-add-alarm-heading>
    <div class="content-body">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="float-md-left content-padding subtitle">
              <h5>{{ alarm.name }}</h5>
            </div>
            <div class="float-md-right content-padding">
              <app-add-alarm-steps-btn-group [activeStep]="Step.Limits" [recipientsDisabled]="addAlarmStepsBtnGroupRecipientsDisabled()"></app-add-alarm-steps-btn-group>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <h3 class="content-title" i18n>New limit</h3>
            <div class="content-padding">
              <app-upsert-alarm-limit-form-container [alarm]="alarm" [limit]="limit" (limitChanged)="onLimitChanged($event)"></app-upsert-alarm-limit-form-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="btn-toolbar float-right buttons-outside-content" role="toolbar" aria-label="Toolbar with button groups">
    <div class="btn-group mr-2" role="group" aria-label="First group">
      <button class="btn btn-primary btn-phoenix float-right" type="submit" [disabled]="!newLimitsForm.form.valid" i18n>Add limit</button>
    </div>
    <div class="btn-group" role="group" aria-label="Second group">
      <button class="btn btn-primary btn-phoenix float-right" type="button" (click)="onContinueToRecipientsClicked()" [disabled]="continueDisabled()" i18n>Continue to recipients</button>
    </div>
  </div>
</form>

<div class="content-main" *ngIf="addedLimits$.value.length > 0">
  <app-add-alarm-added-entries-heading [headlineText]="'Limits already created'"></app-add-alarm-added-entries-heading>
  <div class="content-body">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <app-limits-table [limits$]="addedLimits$" (removeLimit)="onRemoveLimit($event)" [showEdit]="false"></app-limits-table>
        </div>
      </div>
    </div>
  </div>
</div>