import { Component, OnInit } from '@angular/core';

import { RecipientsService } from '@services/recipients.service';

@Component({
  selector: 'app-not-confirmed-information-box',
  templateUrl: './not-confirmed-information-box.component.html',
  styleUrls: ['./not-confirmed-information-box.component.scss']
})
export class NotConfirmedInformationBoxComponent implements OnInit {

  constructor(public recipients: RecipientsService) { }

  ngOnInit() {}

}
