import { Directive } from '@angular/core';
import { Validator, FormGroup, ValidationErrors, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[appRecipientWaysToNotifyValidator]',
  providers: [{provide: NG_VALIDATORS, useExisting: RecipientWaysToNotifyValidatorDirective, multi: true}]
})
export class RecipientWaysToNotifyValidatorDirective implements Validator {

  constructor() { }

  static validateWaysToNotify(group: FormGroup): ValidationErrors | null {
    const recipientMail = group.controls['inputRecipientMail'];
    const recipientPhone = group.controls['inputRecipientPhone'];

    const mailMissing = recipientMail == null || recipientMail.value == null || recipientMail.value.length === 0;
    const phoneMissing = recipientPhone == null || recipientPhone.value == null;

    // pendingPhoneMissing is an hack
    // bug see #34284 (SIC! intern) Recipient-Bearbeiten
    const pendingPhoneMissing = recipientPhone != null && recipientPhone['_pendingValue'] != null ? false : true;
    if (mailMissing && phoneMissing && pendingPhoneMissing) {
      return {
        'waysToNotifiy' : { valid: false }
      };
    }
    return null;
  }

  validate(g: FormGroup): ValidationErrors | null {
    return RecipientWaysToNotifyValidatorDirective.validateWaysToNotify(g);
  }

}
