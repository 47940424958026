import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-alarm-btn-group',
  templateUrl: './alarm-btn-group.component.html',
  styleUrls: ['./alarm-btn-group.component.scss']
})
export class AlarmBtnGroupComponent implements OnInit {

  @Input() activeStep: AlarmBtnGroupComponent.Step;
  @Input() alarmId: string;

  Step = AlarmBtnGroupComponent.Step;

  constructor() { }

  ngOnInit() {
  }

  isStepActive(step: AlarmBtnGroupComponent.Step) {
    const isActive = step === this.activeStep;
    return isActive;
  }

}

export namespace AlarmBtnGroupComponent {
  export enum Step {
    Limits, Recipients
  }
}
