import { MetricsService } from 'services/metrics.service';
import { of } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../../services/metrics.service";
var MetricsResolve = /** @class */ (function () {
    function MetricsResolve(metrics) {
        this.metrics = metrics;
    }
    MetricsResolve.prototype.resolve = function (route, state) {
        console.log('MetricsResolve');
        var currentMetrics = this.metrics.metrics$.value;
        if (currentMetrics == null || currentMetrics.length === 0) {
            return this.metrics.refreshMetrics();
        }
        return of(currentMetrics);
    };
    MetricsResolve.ngInjectableDef = i0.defineInjectable({ factory: function MetricsResolve_Factory() { return new MetricsResolve(i0.inject(i1.MetricsService)); }, token: MetricsResolve, providedIn: "root" });
    return MetricsResolve;
}());
export { MetricsResolve };
