import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AlarmBtnGroupComponent } from '@components-general/alarm-btn-group/alarm-btn-group.component';
import { AlarmsService } from 'services/alarms.service';
import { Alarm } from 'services/alarms.model';
import { RecipientsService } from 'services/recipients.service';
import { RecipientsTableEntry } from '@components-general/recipients-table/recipients-table.component';
import { SettingsService } from 'services/settings.service';
import { WarningRemoveComponent } from '@components-modal/warning-remove/warning-remove.component';
import { SuccessUpsertRemoveComponent } from '@components-modal/success-upsert-remove/success-upsert-remove.component';
import { FailedUpsertRemoveComponent } from '@components-modal/failed-upsert-remove/failed-upsert-remove.component';

@Component({
  selector: 'app-alarm-recipients',
  templateUrl: './alarm-recipients.component.html',
  styleUrls: ['./alarm-recipients.component.scss']
})
export class AlarmRecipientsComponent implements OnInit, OnDestroy {

  Step = AlarmBtnGroupComponent.Step;

  currentPage = 1;
  numberOfRecipients = 0;
  alarm: Alarm;
  recipients$ = new BehaviorSubject<RecipientsTableEntry[]>([]);

  searchText: string;

  removeRecipientInProgress = false;
  allAlarmRecipients: Alarm.Recipient[] = [];
  private allRecipientEntries: RecipientsTableEntry[] = [];

  private alarmSubscription: Subscription;
  private settingsSubscription: Subscription;

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private alarms: AlarmsService,
    private recipients: RecipientsService,
    private settings: SettingsService
  ) { }

  ngOnInit() {
    this.alarmSubscription = this.alarms.currentAlarm$.subscribe(alarm => {
      this.alarm = alarm;
      this.numberOfRecipients = this.alarm.recipients.length;
      this.allAlarmRecipients = this.alarm.recipients;
      this.allRecipientEntries = this.allAlarmRecipients.map(r => {
        return RecipientsTableEntry.convertFromAlarmRecipient(r, this.alarm, this.recipients);
      });
      this.allRecipientEntries = this.allRecipientEntries.sort((e1, e2) => {
        const nameE1 = e1.recipientName.toLocaleLowerCase();
        const nameE2 = e2.recipientName.toLocaleLowerCase();
        if (nameE1 > nameE2) {
          return 1;
        }
        if (nameE1 < nameE2) {
          return -1;
        }
        return 0;
      });
      this.updateRecipients();
    });
    this.settingsSubscription = this.settings.showEntriesPerPage$.subscribe(entriesPerPage => {
      this.updateRecipients(true);
    });
  }

  ngOnDestroy() {
    this.alarmSubscription.unsubscribe();
    this.settingsSubscription.unsubscribe();
  }

  onPageChanged(newPage: number) {
    this.currentPage = newPage;
    this.updateRecipients();
  }

  onChangeSearchText(searchText: string) {
    this.searchText = searchText;
    this.updateRecipients(true);
  }

  onRemoveRecipient(recipient: RecipientsTableEntry) {
    const modalRef = this.modalService
      .open(WarningRemoveComponent, { centered: true, size: 'lg', backdrop: 'static', keyboard: false });
    if (modalRef.componentInstance instanceof WarningRemoveComponent) {
      modalRef.componentInstance.name = recipient.recipientName;
      modalRef.componentInstance.type = WarningRemoveComponent.Type.RecipientFromAlarm;
    }
    modalRef.result.then(
      result => {
        this.removeRecipientInProgress = true;
        this.alarms.removeRecipientWithIdentifier(recipient.recipientId, this.alarm);
        this.alarms.upsertAlarm(this.alarm).subscribe(
          msg => {
            this.alarms.currentAlarm$.next(msg);
            SuccessUpsertRemoveComponent.openDefaultModal(SuccessUpsertRemoveComponent.Type.AlarmRecipientRemoved, this.modalService);
          },
          err => {
            FailedUpsertRemoveComponent.openDefaultModal(err, this.modalService);
            console.error(err);
            this.removeRecipientInProgress = false;
          },
          () => {
            this.removeRecipientInProgress = false;
          }
        );
      },
      reason => { }
    );
  }

  onEditRecipient(recipient: RecipientsTableEntry) {
    this.router.navigate(['/main/alarm', this.alarm.identifier, 'edit_recipient', recipient.recipientId]);
  }

  onAddMoreRecipients() {
    this.router.navigate(['/main/alarm', this.alarm.identifier, 'add_recipient']);
  }

  onDuplicatedAlarm(alarm: Alarm) {
    this.router.navigate(['/main/alarm', alarm.identifier, 'recipients']);
  }

  private updateRecipients(resetPage: boolean = false) {
    const totalEntries = this.allRecipientEntries.filter(entry => {
      return this.filterByText(entry, this.searchText);
    });
    this.numberOfRecipients = totalEntries.length;
    const entriesPerPage = this.settings.showEntriesPerPage$.value;
    let start: number;
    if (resetPage) {
      this.currentPage = 1;
    }
    start = this.currentPage * entriesPerPage - entriesPerPage;
    start = start < totalEntries.length ? start : 0;
    const end = start + entriesPerPage;
    const clippedEntries = totalEntries.slice(start, end);
    this.recipients$.next(clippedEntries);
  }

  private filterByText(recipient: RecipientsTableEntry, text: String) {
    if (text == null || text.length === 0) {
      return true;
    }
    const t = text.toLocaleLowerCase();
    const name = recipient.recipientName != null ? recipient.recipientName.toLocaleLowerCase() : '';
    if (name.includes(t)) {
      return true;
    }
    const limits = recipient.subscribedTo != null ? recipient.subscribedTo.toLocaleLowerCase() : '';
    if (limits.includes(t)) {
      return true;
    }
    const notice = recipient.wayToNotice != null ? recipient.wayToNotice.toLocaleLowerCase() : '';
    if (notice.includes(t)) {
      return true;
    }
    return false;
  }

}
