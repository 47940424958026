import { AlarmsService } from 'services/alarms.service';
import { Alarm } from 'services/alarms.model';
import { of } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../../services/alarms.service";
var LimitResolve = /** @class */ (function () {
    function LimitResolve(alarms) {
        this.alarms = alarms;
    }
    LimitResolve.prototype.resolve = function (route, state) {
        console.log('LimitResolve');
        var alarm = this.alarms.currentAlarm$.value;
        var limit = Alarm.Limit.limitForIdentifier(route.params.id, alarm);
        return of(limit);
    };
    LimitResolve.ngInjectableDef = i0.defineInjectable({ factory: function LimitResolve_Factory() { return new LimitResolve(i0.inject(i1.AlarmsService)); }, token: LimitResolve, providedIn: "root" });
    return LimitResolve;
}());
export { LimitResolve };
