import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { MD5 } from 'object-hash';

import { environment } from 'environments/environment';
import { Alert } from 'services/alerts.model';

namespace AlarmsMiddleware {
    export interface AlarmsResponse {
        alerts: {
            occurred_at: string,
            ongoing: boolean,
            value: number,
            alarm_name: string,
            limit: {
                metric: string,
                comparator: string,
                value: number,
                name: string
            },
            recipients: {
                name: string,
                mail: boolean,
                sms: boolean
            }[]
        }[];
    }
}

export class AlertsMiddleware {
    constructor(private http: HttpClient) {}

    allAlerts() {
        const url = environment.endpoints.alerts;
        return this.http.get<AlarmsMiddleware.AlarmsResponse>(url, { withCredentials: true}).pipe(
            map(
                resp => this.convertAlertsRespone(resp)
            )
        );
    }

    private convertAlertsRespone(response: AlarmsMiddleware.AlarmsResponse) {
        const alerts: Alert[] = [];
        response.alerts.forEach(resp => {
            const recipients: {
                recipientName: string,
                recipientNotifiedBy: 'mail' | 'sms'
            }[] = [];
            resp.recipients.forEach(recipent => {
                if (recipent.mail) {
                    recipients.push({
                        recipientName: recipent.name,
                        recipientNotifiedBy: 'mail'
                    });
                }
                if (recipent.sms) {
                    recipients.push({
                        recipientName: recipent.name,
                        recipientNotifiedBy: 'sms'
                    });
                }
            });

            if (recipients.length > 0) {
                recipients.forEach(recipient => {
                    const alert: Alert = {
                        identifier: '',
                        occurredAt: new Date(resp.occurred_at),
                        value: `${resp.value}`,
                        alarmName: resp.alarm_name,
                        limitName: resp.limit.name,
                        limitValue: `${resp.limit.value}`,
                        limitValueViolation: `${resp.value}`,
                        comparator: resp.limit.comparator,
                        metricName: resp.limit.metric,
                        recipientName: recipient.recipientName,
                        recipientNotifiedBy: recipient.recipientNotifiedBy
                    };
                    const idHash = MD5(alert);
                    alert.identifier = idHash;
                    alerts.push(alert);
                });
            } else {
                const alert: Alert = {
                    identifier: '',
                    occurredAt: new Date(resp.occurred_at),
                    value: `${resp.value}`,
                    alarmName: resp.alarm_name,
                    limitName: resp.limit.name,
                    limitValue: `${resp.limit.value}`,
                    limitValueViolation: `${resp.value}`,
                    comparator: resp.limit.comparator,
                    metricName: resp.limit.metric
                };
                const idHash = MD5(alert);
                alert.identifier = idHash;
                alerts.push(alert);
            }
        });
        return alerts;
    }
}
