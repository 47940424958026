<div class="form-group row">
  <div class="col-sm-12">
    <small class="form-text text-muted" i18n>Please note that fields marked with a star (<span class="star">*</span>)
      are required fields.</small>
  </div>
</div>
<div class="form-group row">
  <label for="inputNameLimit" class="col-sm-2 col-form-label" i18n>Name limit<span class="star">*</span></label>
  <div class="col-sm-10">
    <input type="text" class="form-control" id="inputNameLimit" name="inputNameLimit" #inputNameLimit="ngModel"
      [(ngModel)]="limit.name" placeholder="Enter limit name" appLimitNameValidator>
    <div
      *ngIf="(inputNameLimit.invalid && !inputNameLimit.pristine) && (inputNameLimit.dirty || inputNameLimit.touched)"
      class="alert alert-danger">
      <div *ngIf="inputNameLimit.errors.limitName" i18n>Limit name is required.</div>
    </div>
  </div>
</div>
<div class="form-group row">
  <label for="inputMetric" class="col-sm-2 col-form-label" i18n>Metric<span class="star">*</span></label>
  <div class="col-sm-10">
    <app-dropdown-select-options id="inputMetric" name="inputMetric" #inputMetric="ngModel"
      [options]="availableMetricOptions" [(ngModel)]="selectedMetricOptions"
      (ngModelChange)="onSelectedMetricsModelChanged($event)" required>
    </app-dropdown-select-options>
    <!-- <app-select-options id="inputMetric" name="inputMetric" #inputMetric="ngModel" [(ngModel)]="selectedMetricOptions"
      [options]="availableMetricOptions" (ngModelChange)="onSelectedMetricsModelChanged($event)" required></app-select-options> -->
    <!-- <button class="btn btn-link" (click)="onReloadMetrics()">
      <fa-icon [icon]="['fas', 'sync']" [spin]="metricsReloading"></fa-icon>
    </button>
    <small id="inputSelectMetricHelp" class="text-muted" i18n>
      {{availableMetricOptions.length || 0}} metrics loaded.
    </small> -->
  </div>
</div>
<div class="form-group row">
  <label for="staticCurrentValue" class="col-sm-2 col-form-label" i18n>Current value</label>
  <div class="col-sm-10">
    <input type="text" readonly class="form-control-plaintext" id="staticCurrentValue"
      [value]="currentValueForMetric() || '---'">
  </div>
</div>
<div class="form-group row">
  <label for="inputLimitRule" class="col-sm-2 col-form-label" i18n>Limit rule<span class="star">*</span></label>
  <div class="col-sm-2">
    <app-select-options id="inputLimitRuleOption" name="inputLimitRuleOption" #inputLimitRuleOption="ngModel"
      [(ngModel)]="selectedLimitRuleOperatorOptions" [options]="availableLimitRuleOperatorOptions"
      (ngModelChange)="onSelectedLimitRuleOperationChanged($event)" [showFilter]="false"></app-select-options>
  </div>
  <div class="col-sm-8">
    <input type="text" class="form-control" id="inputLimitRule" name="inputLimitRule" #inputLimitRule="ngModel"
      [(ngModel)]="limit.rule.value" placeholder="Enter limit" required appOnlyAllowNumber allowSign="true">
    <small class="form-text text-muted" i18n>Your limit must contain numbers or floating point numbers.</small>
    <div
      *ngIf="(inputLimitRule.invalid && !inputLimitRule.pristine) && (inputLimitRule.dirty || inputLimitRule.touched)"
      class="alert alert-danger">
      <div *ngIf="inputLimitRule.errors.required" i18n>Limit is required.</div>
    </div>
  </div>
</div>
<div class="form-group row">
  <label for="inputNotificationText" class="col-sm-2 col-form-label" i18n>Notification text<span class="star">*</span></label>
  <div class="col-sm-10">
    <textarea class="form-control" id="inputNotificationText" name="inputNotificationText"
      #inputNotificationText="ngModel" [(ngModel)]="limit.notificationText" rows="3"
      placeholder="Enter notification text" required></textarea>
    <div
      *ngIf="(inputNotificationText.invalid && !inputNotificationText.pristine) && (inputNotificationText.dirty || inputNotificationText.touched)"
      class="alert alert-danger">
      <div *ngIf="inputNotificationText.errors.required" i18n>Notification text is required.</div>
    </div>
  </div>
</div>