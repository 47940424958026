import { Directive } from '@angular/core';
import { Validator, FormGroup, ValidationErrors, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[appAlarmRecipientLimitsValidator]',
  providers: [{provide: NG_VALIDATORS, useExisting: AlarmRecipientLimitsValidatorDirective, multi: true}]
})
export class AlarmRecipientLimitsValidatorDirective implements Validator {

  constructor() { }

  static validateAlarmRecipientLimits(group: FormGroup): ValidationErrors | null {
    const allLimitControls = Object.keys(group.controls).filter(key => {
      return key.startsWith('limit-');
    }).map(key => {
      return group.controls[key];
    });

    const minimumOfOneLimitSelected = allLimitControls.find(c => c.value) != null ? true : false;
    if (minimumOfOneLimitSelected === false) {
      return {
        'limitSelected' : { valid: minimumOfOneLimitSelected }
      };
    }
    return null;
  }

  validate(g: FormGroup): ValidationErrors | null {
    return AlarmRecipientLimitsValidatorDirective.validateAlarmRecipientLimits(g);
  }
}
