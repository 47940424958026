import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AlarmsService } from 'services/alarms.service';
import { Alarm } from 'services/alarms.model';
import { of } from 'rxjs';
import { RecipientsService } from '@services/recipients.service';
import { Recipient } from '@services/recipients.model';

@Injectable({
    providedIn: 'root'
})
export class RecipientResolve implements Resolve<Recipient> {
    constructor(private recipients: RecipientsService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        console.log('RecipientResolve');
        return this.recipients.getRecipientByIdentifier(route.params.id);
    }
}
