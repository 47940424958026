<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="left-side">
        <span i18n>Showing {{showing1}} to {{showing2}} from {{showing3}}.</span>
      </div>
      <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [boundaryLinks]="true" [pageSize]="entriesPerPage"
        [maxSize]="maxSize" [ellipses]="false" class="float-right phoenix-pagination" (pageChange)="onPageChanged($event)"></ngb-pagination>
    </div>
  </div>
</div>