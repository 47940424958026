import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { map, find } from 'rxjs/operators';

import { AlertsService } from 'services/alerts.service';
import { Alert } from 'services/alerts.model';

@Injectable({
    providedIn: 'root'
  })
export class AlertResolve implements Resolve<Alert> {
    constructor(private alerts: AlertsService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        console.log('AlertResolve');
        const alert = this.alerts.alert(route.params.id);
        if (alert == null) {
            const alertObs = this.alerts.allAlerts().pipe(
                map(alerts => alerts.find(a => a.identifier === route.params.id))
            );
            return alertObs;
        }
        return this.alerts.alert(route.params.id);
    }
}
