<nav class="navbar navbar-expand-md navbar-light top" [ngClass]="{'shadow':(!userMenuCollapsed || !navbarCollapsed)}">
  <div class="container">
    <div class="wrapper">
      <div class="header">
        <a class="navbar-brand" [routerLink]="['/']" (click)="userMenuCollapsed = true">
          <img src="assets/Phoenix_Contact_Logo/Phoenix_Contact_Logo_b.jpg" class="logo" alt="">
        </a>

        <div class="d-md-none flex-row-reverse">
          <button class="btn btn-light" [ngClass]="{'active': !userMenuCollapsed}" type="button" (click)="toggleCollapsedMenu(MainComponentMenu.User)"
            [attr.aria-expanded]="!userMenuCollapsed" aria-controls="userMenuContent" aria-expanded="false" aria-label="Toggle user menu">
            <fa-icon [icon]="['fas', 'user']"></fa-icon>
          </button>
          <button class="btn btn-light" [ngClass]="{'active': !navbarCollapsed}" type="button" (click)="toggleCollapsedMenu(MainComponentMenu.Application)"
            [attr.aria-expanded]="!navbarCollapsed" aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation">
            <fa-icon [icon]="['fas', 'bars']"></fa-icon>
          </button>
        </div>
        <div class="d-none d-md-block">
          <div ngbDropdown placement="bottom-right">
            <button class="btn btn-light" type="button" id="dropdownBasic1" ngbDropdownToggle>
              <fa-icon icon="user"></fa-icon>
              <span *ngIf="user && user.name; else noUsername">{{ user.name }}</span>
              <ng-template #noUsername> ---</ng-template>
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <button class="dropdown-item" (click)="onLogout()">
                <fa-icon icon="sign-out-alt"></fa-icon>
                <span i18n="Name of the logout button.">Logout</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="nav-menu d-md-none" [ngbCollapse]="userMenuCollapsed" id="userMenuContent">
        <ul>
          <li><button type="button" class="btn btn-link" i18n="Name of the logout button." (click)="onLogout()">Logout</button></li>
        </ul>
      </div>
      <div class="nav-menu d-md-none" [ngbCollapse]="navbarCollapsed" id="navbarContent">
        <ul>
          <li><a [routerLink]="['/']" [ngClass]="{'active': activeAlarmingMenuEntry === AlarmingMenuEntry.Alarms}" i18n="Left menu entry 'alarms'">Alarms</a></li>
          <li><a [routerLink]="['/main/alarms_sent']" [ngClass]="{'active': activeAlarmingMenuEntry === AlarmingMenuEntry.AlarmsSent}" i18n="Left menu entry 'Alarms history'">Alarms history</a></li>
          <li><a [routerLink]="['/main/recipients_created']" [ngClass]="{'active': activeAlarmingMenuEntry === AlarmingMenuEntry.RecipientsCreated}" i18n="Left menu entry 'recipients'">Recipients</a></li>
        </ul>
        <ul>
          <li><a i18n="Open external link 'TSD Analytics'" (click)="openExternalLink(ExternalLink.tsdAnalytics)">Goto Analytics</a></li>
          <li><a i18n="Open external link 'Proficloud'" (click)="openExternalLink(ExternalLink.proficloud)">Goto Proficloud</a></li>
        </ul>
      </div>
    </div>
  </div>
</nav>

<div class="container main">
  <div class="row">
    <div class="col-md-3 d-none d-md-block">
      <div>
        <div class="list-group navbar-collapse">
          <div class="list-group-item box-border menu-heading" i18n="Left menu title">TSD Alarming</div>
          <a class="list-group-item box-border" [ngClass]="{'active-item': activeAlarmingMenuEntry === AlarmingMenuEntry.Alarms}" [routerLink]="['/']" i18n="Left menu entry 'alarms'">Alarms</a>
          <a class="list-group-item box-border" [ngClass]="{'active-item': activeAlarmingMenuEntry === AlarmingMenuEntry.AlarmsSent}" [routerLink]="['/main/alarms_sent']" i18n="Left menu entry 'Alarms history'">Alarms history</a>
          <a class="list-group-item box-border" [ngClass]="{'active-item': activeAlarmingMenuEntry === AlarmingMenuEntry.RecipientsCreated}" [routerLink]="['/main/recipients_created']" i18n="Left menu entry 'recipients'">Recipients</a>
        </div>
        <div *ngIf="recipients.countRecipientsUnconfirmed > 0" class="not-confirmed-box">
          <app-not-confirmed-information-box></app-not-confirmed-information-box>
        </div>
        <div class="list-group navbar-collapse" class="links-menu">
          <a [href]="externalTSDAnalyticsUrl" target="_blank" class="list-group-item box-border" i18n="Open external link 'TSD Analytics'">Goto Analytics</a>
          <a [href]="externalProficloudUrl" target="_blank" class="list-group-item box-border" i18n="Open external link 'Proficloud'">Goto Proficloud</a>
        </div>
      </div>
    </div>
    <div class="col-md-9">
      <div *ngIf="recipients.countRecipientsUnconfirmed > 0" class="d-md-none">
        <app-not-confirmed-information-box></app-not-confirmed-information-box>
      </div>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>