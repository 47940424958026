import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AlarmsService } from 'services/alarms.service';
import { Alarm } from 'services/alarms.model';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class LimitResolve implements Resolve<Alarm.Limit> {
    constructor(private alarms: AlarmsService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        console.log('LimitResolve');
        const alarm = this.alarms.currentAlarm$.value;
        const limit = Alarm.Limit.limitForIdentifier(route.params.id, alarm);
        return of(limit);
    }
}
